import * as React from 'react';
import {
  PropsWithChildren, SetStateAction, useEffect, useState,
} from 'react';
import {
  Button,
  Confirm,
  Datagrid,
  Form,
  Identifier,
  RaRecord,
  required,
  TextInput,
  useCreate,
  useExpanded,
  useGetIdentity,
  useNotify,
  useRecordContext,
  useRedirect,
  useRefresh,
  useSaveContext,
  useTranslate,
  useUnselectAll,
} from 'react-admin';
import {
  Box,
  Card,
  CardContent,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  TableCell,
  TableHead,
  TableRow,
  TextField as MuiTextField,
  Tooltip,
  useTheme,
  Alert,
  AlertTitle,
} from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import { Edit } from '@react-admin/ra-rbac';
import { MapContainer, ScaleControl } from 'react-leaflet';
import L, { LatLngBoundsExpression, LatLngExpression, Map } from 'leaflet';
import EditIcon from '@mui/icons-material/Edit';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import BatteryAlertIcon from '@mui/icons-material/BatteryAlert';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import TuneIcon from '@mui/icons-material/Tune';
import {
  AnyGeometry, Asset, Device, DeviceType, PointGeometry,
} from '@x-guard/xgac-types/xgac';
import PersonIcon from '@mui/icons-material/Person';
import CircularProgress from '@mui/material/CircularProgress';
import { useUpdate } from 'ra-core';
import { useFormContext } from 'react-hook-form';
import _ from 'lodash';
import queryString from 'query-string';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import RoomIcon from '@mui/icons-material/Room';
import IosShareIcon from '@mui/icons-material/IosShare';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import DeleteIcon from '@mui/icons-material/Delete';
import CancelIcon from '@mui/icons-material/Cancel';
import WifiTetheringErrorIcon from '@mui/icons-material/WifiTetheringError';
import SignalCellularConnectedNoInternet1BarIcon from '@mui/icons-material/SignalCellularConnectedNoInternet1Bar';
import { useGetListLive } from '@react-admin/ra-realtime';
import { StyledButton } from '../buttons/StyledButton';
import { ButtonMapMarker } from '../map/ButtonMapMarker';
import authProvider from '../../../utils/authProvider';
import { atsDataProvider } from '../../../dataProviders/atsDataProvider';
import { httpClient } from '../../../utils/httpClient';
import { getCurrentCustomer } from '../../../lib/currentCustomer';
import { XGAC_MAIN_API_URL } from '../../../config';
import { wait } from '../../../lib/websockets/webSocketHelpers';
import { GoogleRaCoordinatesInput } from '../components/inputs/GoogleRaCoordinatesInput';
import { GoogleCoordinatesInput } from '../components/inputs/GoogleCoordinatesInput';
import { xgacDataProvider } from '../../../dataProviders/xgacDataProvider';
import { TestButtonButton } from '../components/TestButtonButton';
import { AddBuildingDialog } from '../components/AddBuildingDialog';
import bhvIcon from '../svg/bhv-button.svg';
import gatewayIcon from '../svg/gateway_icon.svg';
import buildingIcon from '../svg/building.svg';
import arrowDownIcon from '../svg/arrow_down.svg';

import { CenterControl, CustomLayerControl, FloorFilter } from '../components/LeafletControls';
import { buttonAssetExporter } from '../../../utils/customExporter';
import { AddButtonOrGatewayDialog } from '../components/AddButtonOrGatewayDialog';
import { DeviceConnectionStatus, DeviceFilter, DeviceStatus } from '../../../lib/constants/customTypes';
import { DeviceFilters } from '../components/deviceFilters';
import { ButtonWithIconAndLabel, ButtonPageButtons } from '../buttons/ButtonPageButtons';
import { RaIntegerInput } from '../../../components/inputs/raIntegerInput';
import { useOverlay } from '../../../utils/useOverlay';

const apiUrl = XGAC_MAIN_API_URL;
const Grid = Grid2;

export const EmptyHeader = () => {

  return (
    <TableHead></TableHead>
  );

};

const updateMonitoredZones = () => {

  const currentCustomer = getCurrentCustomer()?.value;
  if (currentCustomer) {

    httpClient(
      `${apiUrl}/specials/bhv-knop/update-monitored-zones/`,
      { method: 'POST' },
      { customer: { _id: currentCustomer, _ref: 'Customer' } },
    ).catch((e) => {

      console.log('error', e);

    });

  }

};

const getDeviceStatus = (record: Asset & { device: Device }, gatewayStatus: any): DeviceConnectionStatus => {

  const isTrial = authProvider.getRoles().includes('bhvk_trial');
  const currentTimestamp = new Date().getTime();
  const lastSeenTimestamp = new Date(record.lastObservationAt || 0).getTime();

  if (isTrial === true) {

    return 'connected';

  }

  if (record.device.type === 'loraGateway') {

    if (gatewayStatus === null || !gatewayStatus.result) {

      return 'disconnected';

    }
    const foundGatewayStatus = gatewayStatus.result.find((indexGatewayStatus: any) => indexGatewayStatus._id === record.device._id);

    if (!foundGatewayStatus) {

      return 'disconnected';

    }
    return foundGatewayStatus.gatewayStatus.online ? 'connected' : 'disconnected';

  }

  if (record.device.battery && record.device.battery <= 20) {

    return 'empty';

  }

  // if (record.device.rssi && record.device.rssi <= -110) {
  //
  //   return 'lowRSSI';
  //
  // }
  //
  // if (record.device.snr && record.device.snr <= -5) {
  //
  //   return 'highNoise';
  //
  // }

  if ((currentTimestamp - lastSeenTimestamp) < 1000 * 60 * 60 * 20) {

    return 'connected';

  }

  return 'disconnected';

};
const DeviceEditFields = (props: { setConfirmDialogOpen: SetStateAction<any> }) => {

  const record = useRecordContext();
  const { getValues } = useFormContext();
  let data = getValues() as Asset & { device: Device };
  const { save } = useSaveContext();

  const handleSave = (newData: any) => {

    data = { ...data, ...newData };

    if (_.isEqual(record.position, data.position)) {

      if (save) {

        save(data);
        return;

      }

    }
    props.setConfirmDialogOpen(true);

  };

  return (
    <>
      <TextInput source="name" size="small" fullWidth helperText={false} color="info" onBlur={(e) => {

        if (e.target.value && e.target.value.length > 0) {

          handleSave({ name: e.target.value });

        }

      }} validate={required()} />
      <GoogleRaCoordinatesInput source="position" sx={{ marginTop: '1em' }} handleSave={handleSave} country='nl'/>
      <RaIntegerInput source="position.properties.address.floor" label="general.text.map_floors" needsSaving={true} />
    </>
  );

};

const DeviceStatusWarning = (props: { active: DeviceConnectionStatus }) => {

  const { active } = props;
  const translate = useTranslate();
  const redirect = useRedirect();

  if (active === 'connected') {

    return null;

  }

  if (active === 'disconnected' || active === 'empty') {

    return <Box>
      <StyledButton
        style={{ width: '100%', marginBottom: '15px' }}
        label="general.text.solve_issues"
        onClick={() => {

          redirect('/help/hardware/er-is-een-knop-offline-wat-moet-ik-doen');

        }}
      />
    </Box>;

  }

  if (active === 'highNoise' || active === 'lowRSSI') {

    const urlMap = {
      highNoise: '/help/hardware/het-radiosignaal-bevat-ruis/',
      lowRSSI: '/help/hardware/de-knop-staat-te-ver-weg-van-het-basisstation',
    };

    return <Alert
      style={{ marginTop: '1em', marginBottom: '1.5em' }}
      severity="warning"
      action={
        <StyledButton
          style={{ width: '100%', marginBottom: '15px' }}
          label="general.text.solve_issues"
          onClick={() => {

            redirect(urlMap[active]);

          }}
        />
      }>
      <AlertTitle>{translate(`resources.buttons.text.status.${active}`)}</AlertTitle>
    </Alert>;

  }

  return null;

};

const DeviceEditForm = (props: { gatewayStatus: any }) => {

  const record: Asset & { device: Device } = useRecordContext();
  const translate = useTranslate();
  const notify = useNotify();
  const roles = authProvider.getRoles();
  const refresh = useRefresh();
  const [active, setActive] = useState<DeviceConnectionStatus>('unknown');
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const isTrial = roles.includes('bhvk_trial');
  useEffect(() => {

    if (record.device.deviceId?.substring(0, 6) === 'Unpaid') {

      setActive('connected');
      return;

    }

    setActive(getDeviceStatus(record, props.gatewayStatus));

  }, [isTrial, props.gatewayStatus, record]);
  const onDeviceSuccess = () => {

    notify('ra.notification.updated', { messageArgs: { smart_count: 1 }, type: 'info' });
    updateMonitoredZones();

    refresh();

  };

  const transform = (data: any) => {

    delete data.device;
    delete data.state;
    return {
      ...data,
      position: {
        ...data.position,
        coordinates: [data.position.coordinates[0], data.position.coordinates[1]],
        properties: {
          ...data.position.properties,
          address: {
            ...data.position.properties.address,
            formattedAddress: data.position.properties.address.formattedAddress,
          },
        },
      },
    };

  };

  const ExternalIdField = () => {

    const externalId = record.externalId?.split('-')?.slice(-1)[0];
    const type = record.device?.type;

    return (
      <h4 style={{ marginBottom: '10px', marginTop: '0' }}>
        {translate(type === 'lora' ? 'general.text.button' : 'general.text.gateway')}{externalId ? ` ${externalId}` : translate('general.text.number_unknown') }
        {externalId
            && (<Tooltip
              title={translate(type === 'lora' ? 'resources.buttons.text.button_number_tooltip' : 'resources.buttons.text.gateway_number_tooltip')}
              arrow
            >
              <HelpOutlineIcon
                style={{
                  verticalAlign: 'middle',
                  position: 'relative',
                  top: '-1px',
                  marginLeft: '5px',
                }} />
            </Tooltip>)}
      </h4>
    );

  };

  const TestButtonOrOrderExpl = () => {

    if (record.device.deviceId?.substring(0, 6) === 'Unpaid') {

      return (
        <Chip variant="outlined" label={translate('resources.buttons.text.not_ordered')}/>
      );

    }
    return <TestButtonButton button={record} />;

  };

  const ConfirmDialog = () => {

    const { getValues } = useFormContext();
    const [update] = useUpdate();

    const handleSave = (moveButtons: boolean) => {

      const { id, ...data } = getValues();

      const copiedData = { ...data } as Asset & { device: Device };

      if (copiedData.insideZones) {

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        delete copiedData.insideZones;

      }

      if (!moveButtons && copiedData.position) {

        copiedData.position.coordinates = record.position?.coordinates || copiedData.position.coordinates;

      }

      const onSuccess = () => {

        refresh();
        setConfirmDialogOpen(false);
        updateMonitoredZones();

      };

      const updateData = { name: copiedData.name, position: copiedData.position };

      update(
        'assets',
        { id, data: updateData, previousData: record },
        { onSuccess },
      );

    };

    return (
      <Dialog open={confirmDialogOpen} onClose={() => handleSave(false)}>
        <DialogTitle>{translate('resources.buttons.automove_title')}</DialogTitle>
        <DialogContent>
          <p>{translate('resource.buttons.automove_subtitle')}</p>
        </DialogContent>
        <DialogActions>
          <StyledButton
            label="resource.buttons.automove_no"
            onClick={() => {

              handleSave(false);

            }}
          />
          <StyledButton
            label="resource.buttons.automove_yes"
            onClick={() => {

              handleSave(true);

            }}
          />
        </DialogActions>
      </Dialog>
    );

  };

  return (
    <>
      <Card sx={{ boxShadow: 1 }}>
        <CardContent sx={{ p: '5px' }}>
          <Edit
            id={record._id}
            resource="assets"
            actions={false}
            mutationMode="pessimistic"
            mutationOptions={{ onSuccess: onDeviceSuccess }}
            redirect={false}
            transform={transform}>
            <Form>
              <ConfirmDialog/>
              <div style={{ padding: '5px' }}>
                <div className="flex-in-between">
                  <ExternalIdField/>
                  <TestButtonOrOrderExpl />
                </div>
                <DeviceStatusWarning active={active} />
                <DeviceEditFields setConfirmDialogOpen={setConfirmDialogOpen}/>
              </div>
            </Form>
          </Edit>
        </CardContent>
      </Card>
    </>
  );

};

const DivWithId = ({ children, className }: PropsWithChildren & { className: string }) => {

  const record = useRecordContext();
  return (
    <div className={className} id={`device-${record.id}`}>{children}</div>
  );

};

const DeviceStatusIcon = (props: { createAlarmMode?: boolean; deviceStatus: Record<string, DeviceConnectionStatus> }) => {

  const record: Asset & { device: Device } = useRecordContext();
  const theme = useTheme();
  const translate = useTranslate();

  let active = props.deviceStatus?.[record._id] ? props.deviceStatus[record._id] : 'unknown';

  if (record.device.deviceId?.substring(0, 4) === 'Demo') {

    active = 'connected';

  }
  if (record.device.deviceId?.substring(0, 6) === 'Unpaid') {

    return (
      <ShoppingCartIcon sx={{
        marginLeft: '-2px !important',
        marginRight: '-3px !important',
      }}/>
    );

  }

  if (active === 'unknown') {

    return (
      <Tooltip title={translate('resources.buttons.text.status.pending')} placement="top" arrow>
        <CircularProgress size={15} sx={{
          width: '15px',
          verticalAlign: 'middle',
          marginRight: '0',
        }}/>
      </Tooltip>
    );

  }

  if (active === 'connected') {

    return (
      <Tooltip title={translate('resources.buttons.text.status.online')} placement="top" arrow>
        <CheckCircleIcon
          style={{
            width: '15px',
            verticalAlign: 'middle',
            position: 'relative',
            top: '-1px',
            marginRight: '0',
            color: theme.palette.primary.main,
          }} />
      </Tooltip>
    );

  }

  if (active === 'disconnected') {

    return (
      <Tooltip title={translate('resources.buttons.text.status.offline')} placement="top" arrow>
        <NotInterestedIcon
          style={{
            width: '15px',
            verticalAlign: 'middle',
            position: 'relative',
            top: '-1px',
            marginRight: '0',
            color: theme.palette.secondary.main,
          }} />
      </Tooltip>
    );

  }

  if (active === 'empty') {

    return (
      <Tooltip title={translate('resources.buttons.text.status.empty')} placement="top" arrow>
        <BatteryAlertIcon
          style={{
            width: '17px',
            verticalAlign: 'middle',
            position: 'relative',
            top: '-1px',
            marginRight: '5px',
            color: theme.palette.secondary.main,
          }} />
      </Tooltip>
    );

  }

  if (active === 'highNoise') {

    return (
      <Tooltip title={translate('resources.buttons.text.status.high_noise')} placement="top" arrow>
        <WifiTetheringErrorIcon
          style={{
            width: '17px',
            verticalAlign: 'middle',
            position: 'relative',
            top: '-1px',
            color: theme.palette.secondary.main,
            marginLeft: '-1px',
            marginRight: '-1px',
          }} />
      </Tooltip>
    );

  }

  if (active === 'lowRSSI') {

    return (
      <Tooltip title={translate('resources.buttons.text.status.low_rssi')} placement="top" arrow>
        <SignalCellularConnectedNoInternet1BarIcon
          style={{
            width: '17px',
            verticalAlign: 'middle',
            position: 'relative',
            top: '-1px',
            color: theme.palette.secondary.main,
            marginLeft: '-1px',
            marginRight: '-1px',
          }} />
      </Tooltip>
    );

  }

  return null;

};

const DeviceListName = () => {

  const record: Asset & { device: Device } = useRecordContext();
  return (
    <span style={{ fontWeight: 'bold', marginLeft: '15px' }} >{record.name}</span>
  );

};

const MassMoveList = (props: { selectedIds: Identifier[]; setDialogOpen: any; setZoomed: any }) => {

  const notify = useNotify();
  const refresh = useRefresh();
  const translate = useTranslate();
  const [massPosition, setMassPosition] = useState<PointGeometry | null>(null);
  const [hasError, setHasError] = useState(false);

  const MoveMultipleButton = () => {

    const { selectedIds } = props;
    const handleMoveMultiple = () => {

      if (!massPosition) {

        notify('resources.notifications.move_multiple_no_location', { type: 'error' });
        return;

      }
      xgacDataProvider.massMoveDevices(selectedIds, massPosition).then(() => {

        notify('resources.notifications.moved_multiple', { type: 'success' });
        refresh();
        props.setDialogOpen(false);
        updateMonitoredZones();
        wait(500).then(() => {

          props.setZoomed(false);

        });

      }).catch(() => {

        notify('resources.notifications.moved_multiple_error', { type: 'error' });
        props.setDialogOpen(false);

      });
      props.setDialogOpen(false);

    };
    return (
      <Button label="resources.buttons.text.move_confirm_button" onClick={handleMoveMultiple} disabled={massPosition === null || selectedIds?.length === 0} />
    );

  };

  return (
    <>
      <span>{translate('resources.buttons.text.mass_move_explanation')}</span>
      <GoogleCoordinatesInput
        value={massPosition}
        setValue={setMassPosition}
        country="nl"
        label={translate('resources.buttons.text.building_dialog.0.input_label')}
        setHasError={setHasError}
      />
      {hasError && (
        <Alert severity='warning' sx={{
          marginTop: '10px',
        }}>{translate('resources.buttons.text.invalid_address')}</Alert>
      )}
      <DialogActions>
        <Button onClick={() => props.setDialogOpen(false)} autoFocus>
          <>{translate('ra.action.cancel')}</>
        </Button>
        <MoveMultipleButton/>
      </DialogActions>
    </>
  );

};

export const ButtonList = (props?: { createAlarmMode?: boolean }) => {

  const { createAlarmMode } = props || { createAlarmMode: false };

  const notify = useNotify();
  const currentCustomer = getCurrentCustomer()?.value;
  const [create, { error }] = useCreate();
  const translate = useTranslate();
  const [expandedId, setExpandedId] = useState<Identifier>('');
  const [, toggleExpanded] = useExpanded('all-assets', expandedId, true);
  const [map, setMap] = useState<Map | null>(null);
  const [zoomed, setZoomed] = useState(false);
  const [mapTileLayer, setMapTileLayer] = useState<string>(localStorage.getItem('mapTileLayer') || 'Default');
  const [createAlarmOpen, setCreateAlarmOpen] = useState<null | RaRecord>(null);
  const [simulateResponse, setSimulateResponse] = useState<null | JSX.Element | 'nobody' >(null);
  const isTrial = authProvider.getRoles().includes('bhvk_trial');
  const redirect = useRedirect();
  const [printDialogOpen, setPrintDialogOpen] = useState(false);
  const [openStep, setOpenStep] = useState<null | number>(null);
  const [typeToAdd, setTypeToAdd] = useState<null | 'gateway' | 'button'>(null);
  const [upperSearchTerm, setUpperSearchTerm] = useState('');
  const [customerLinks, setCustomerLinks] = useState<any[]>([]);
  const [possibleFloors, setPossibleFloors] = useState<number[]>([]);
  const [selectedFilters, setSelectedFilters] = useState<DeviceFilter>({
    floors: [],
    deviceType: [],
    activeStatus: [],
  });
  const [selectedBuildings, setSelectedBuildings] = useState<AnyGeometry[] | null>(null);
  const [cancelLastClicked, setCancelLastClicked] = useState<Date | null>(null);
  const [gatewayStatus, setGatewayStatus] = useState<any>(null);
  const [lastSavedCustomer, setLastSavedCustomer] = useState<string | null>(null);
  const [deviceStatus, setDeviceStatus] = useState<Record<string, DeviceConnectionStatus>>({});
  const [filterOpen, setFilterOpen] = useState(false);

  const identiy = useGetIdentity();

  const overlays = useOverlay(map, selectedFilters.floors);

  const setChosenFloors = (floors: number[]) => {

    setSelectedFilters({ ...selectedFilters, floors });

  };

  useEffect(() => {

    toggleExpanded();

  }, [expandedId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {

    if (localStorage.getItem('openBuildingDialog')) {

      setOpenStep(0);
      localStorage.removeItem('openBuildingDialog');

    }

    return () => {

      sessionStorage.removeItem('button_selectedIds');

    };

  }, []);

  useEffect(() => {

    if (currentCustomer && currentCustomer !== lastSavedCustomer) {

      setLastSavedCustomer(currentCustomer);
      sessionStorage.removeItem('button_selectedIds');

    }

  }, [currentCustomer, lastSavedCustomer, identiy]);

  useEffect(() => {

    const getGatewayStatus = async () => {

      const gatewayRequest = await httpClient(
        `${apiUrl}/specials/bhv-knop/gateways?${queryString.stringify({ 'customer._id': lastSavedCustomer })}`,
      );

      setGatewayStatus(gatewayRequest.json);

    };
    getGatewayStatus();

  }, [lastSavedCustomer]);

  const listResult = useGetListLive('specials/bhv-knop/device-list', {
    pagination: { page: 1, perPage: 1000 },
    sort: { field: 'name', order: 'ASC' },
    filter: {
      $search: upperSearchTerm !== '' ? upperSearchTerm : undefined,
      ...(createAlarmMode ? { type: '!loraGateway' } : {}),
    },
  });
  const { total, isLoading } = listResult;
  const data = listResult.data?.filter((item: Asset & { device: Device }) => {

    let canPass = true;
    if (selectedFilters.floors.length > 0) {

      if (!selectedFilters.floors.includes(item.position?.properties?.address?.floor || 0)) {

        canPass = false;

      }

    }
    if (canPass && selectedFilters.deviceType.length > 0) {

      if (!selectedFilters.deviceType.includes(item.device.type)) {

        canPass = false;

      }

    }
    if (canPass && selectedFilters.activeStatus.length > 0) {

      let passesActive = false;
      if (selectedFilters.activeStatus.includes(DeviceStatus.Demo)) {

        if (item.device.deviceId.substring(0, 4) === 'Demo') {

          passesActive = true;

        }

      }
      if (!passesActive && selectedFilters.activeStatus.includes(DeviceStatus.Unpaid)) {

        if (item.device.deviceId.substring(0, 6) === 'Unpaid') {

          passesActive = true;

        }

      }
      if (!passesActive && selectedFilters.activeStatus.includes(DeviceStatus.Active)) {

        if (item.device.deviceId.substring(0, 6) !== 'Unpaid' && item.device.deviceId.substring(0, 4) !== 'Demo') {

          passesActive = true;

        }

      }
      if (!passesActive) {

        canPass = false;

      }

    }
    return canPass;

  }) as (Asset & { device: Device })[];

  useEffect(() => {

    if (!lastSavedCustomer) {

      return;

    }
    const getProcessTemplates = async () => {

      const customerLinkRequest = await atsDataProvider.getList('customer-links', {
        pagination: { page: 1, perPage: 100 },
        sort: { field: 'createdAt', order: 'DESC' },
        filter: {},
      });

      if (!customerLinkRequest.data) {

        setCustomerLinks([]);

      }
      setCustomerLinks(customerLinkRequest.data);

    };

    getProcessTemplates();

  }, [lastSavedCustomer]);

  useEffect(() => {

    if (createAlarmMode && createAlarmOpen !== null) {

      const currentRecord = data.find((item) => item._id === createAlarmOpen._id);
      if (!currentRecord) {

        return;

      }
      const mappedAssetGroups = currentRecord.assetGroups.map((item) => item._id);
      const filteredCustomerLinks = customerLinks.filter((item: any) => {

        return _.intersection(item.xgacGroups, [...mappedAssetGroups, -2]).length > 0
          && _.intersection(item.notXgacGroups, mappedAssetGroups).length === 0
          && item.alarmLevels.red === true;

      }).sort((a: any, b: any) => {

        const aXgacGroups = a.xgacGroups.filter((item: any) => item !== -2);
        const bXgacGroups = b.xgacGroups.filter((item: any) => item !== -2);
        if (aXgacGroups.length === bXgacGroups.length) {

          return 0;

        }
        if (aXgacGroups.length > bXgacGroups.length) {

          return -1;

        }
        return 1;

      });
      const customerLink = filteredCustomerLinks[0];
      const simulationId = customerLink?.processTemplate?.id || '334df770-17c7-4dbb-8f6b-eebb5f75e270';

      setSimulateResponse(null);
      atsDataProvider.simulate(simulationId, createAlarmOpen.id.toString()).then((response: any) => {

        if (!response.json || Object.keys(response.json).length === 0) {

          setSimulateResponse('nobody');
          return;

        }
        let totalResponders = 0;
        for (const key of Object.keys(response.json)) {

          totalResponders += response.json[key].filter((item: any) => item.name || item.alarmCenter).length;

        }
        if (totalResponders === 0) {

          setSimulateResponse('nobody');
          return;

        }
        setSimulateResponse(<>
          {Object.keys(response.json).map((key, index) => {

            const hasResults = response.json[Object.keys(response.json)[index - 1]]?.length > 0;
            const hasPersons = response.json[key].some((item: any) => item.name);
            const taskContactedFilter = customerLink?.processTemplate?.scheduleTasks[index]?.metaScript?.meta?.xgacFilter?.result?.contacted
              || customerLink?.processTemplate?.scheduleTasks[index]?.metaScript?.meta?.xgacFilter?.request?.contacted;
            const hasContactedFilter = taskContactedFilter === '!=true';
            const delay = customerLink?.processTemplate?.scheduleTasks[index]?.delay || 0;
            const isAlarmCenterStep = response.json[key].some((item: any) => item.alarmCenter);

            if (!isAlarmCenterStep && !hasPersons) {

              return null;

            }

            return (
              <>
                <div className={'sf-arrow'}>
                  {hasResults === false
                    ? <img src={arrowDownIcon} alt="arrow down"/>
                    : <div>
                      <img src={arrowDownIcon} alt="arrow down"/>
                      {delay / 1000} seconden
                    </div>
                  }
                </div>
                <div className={`sf-step ${hasPersons ? 'sf-has-persons' : 'sf-no-persons'}`}>
                  {hasPersons === false
                    ? <h6>{customerLink.processTemplate.scheduleTasks[index]?.taskTemplate?.templateName || `fase ${index + 1}`}</h6>
                    : <>
                      <h5>BHV-oproep</h5>
                      <ul>
                        {hasContactedFilter && index !== 0
                          ? (<>
                            <li>
                              <PersonIcon style={{
                                verticalAlign: 'middle', position: 'relative', top: '-2px', marginRight: '1px',
                              }}/>
                              {translate('resources.buttons.text.create_alarm_uncontacted', { smart_count: response.json[key].length })}
                            </li>
                          </>
                          ) : (
                            <>
                              {response.json[key].map((item: any) => (
                                <>
                                  <li>
                                    <PersonIcon style={{ verticalAlign: 'middle', position: 'relative', top: '-2px' }}/> {item.name}
                                  </li>
                                </>
                              ))}
                            </>
                          )}
                      </ul>
                    </>
                  }
                </div>
              </>
            );

          })}
        </>);

      });

    }

  }, [createAlarmMode, createAlarmOpen, customerLinks]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {

    if (data && data.length > 0 && map && !zoomed) {

      const allCoordinates = data.filter((item) => {

        return !!item.position?.coordinates;

      }).map((item: any) => [item.position.coordinates[1], item.position.coordinates[0]]);
      map.fitBounds(allCoordinates as LatLngBoundsExpression);
      setZoomed(true);

    }

  }, [data, map, zoomed]);

  useEffect(() => {

    if (!listResult.data || !gatewayStatus) return;

    const newStatus: Record<string, DeviceConnectionStatus> = {};
    listResult.data.forEach((item: Asset & { device: Device }) => {

      newStatus[item._id] = getDeviceStatus(item, gatewayStatus);

    });

    setDeviceStatus(newStatus);

  }, [listResult.data, gatewayStatus]);

  useEffect(() => {

    if (data && data[0]?.customer?._id !== currentCustomer && zoomed) {

      setZoomed(false);

    }

  }, [currentCustomer, data, zoomed]);

  useEffect(() => {

    if (!listResult.data) return;
    const floors = listResult.data.map((item: Asset & { device: Device }) => {

      return item.position?.properties?.address?.floor || 0;

    });
    const combinedFloors = [...floors, ...overlays.map((item) => item.level || 0)];
    const uniqueFloors = _.uniq(combinedFloors).sort((a, b) => a - b);

    if (!_.isEqual(uniqueFloors, possibleFloors)) {

      setPossibleFloors(uniqueFloors);
      setChosenFloors([]);

    }

  }, [listResult.data, overlays]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {

    if (selectedBuildings) {

      map?.fitBounds(L.geoJSON(selectedBuildings).getBounds(), { maxZoom: 19 });
      setSelectedBuildings(null);

    }

  }, [selectedBuildings]); // eslint-disable-line react-hooks/exhaustive-deps

  const toggleItemExpansion = async (id: Identifier) => {

    if (data) {

      const currentItem = data?.find((item: any) => item._id === id);
      if (currentItem && currentItem.position?.coordinates) {

        const coordinates = [currentItem.position.coordinates[1], currentItem.position.coordinates[0]];
        if (coordinates && !map?.getBounds().contains(coordinates as LatLngExpression)) {

          map?.flyTo(coordinates as LatLngExpression);

        }

      }

    }

    if (expandedId === id) {

      setExpandedId('');
      return false;

    }
    setExpandedId(id);
    await wait(1);
    document.getElementById(`device-${id}`)?.scrollIntoView({ block: 'start' });
    return false;

  };

  const ExternalIdField = (innerProps: { externalId?: string }) => {

    if (!innerProps.externalId) return (<></>);
    return (
      <div className="button-sidebar-grid-external_id">
        <span>{innerProps.externalId}</span>
      </div>
    );

  };

  const ButtonSideBar = () => {

    const unselectAll = useUnselectAll('specials/bhv-knop/device-list');
    const [searchTerm, setSearchTerm] = useState(upperSearchTerm);
    const [mouseHoverId, setMouseHoverId] = useState<Identifier | null>(null);
    const [selectedIds, setSelectedIds] = useState<Identifier[]>(
      sessionStorage.getItem('button_selectedIds') ? JSON.parse(sessionStorage.getItem('button_selectedIds') as string) : [],
    );
    const [massMoveOpen, setMassMoveOpen] = useState(false);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const refresh = useRefresh();

    useEffect(() => {

      sessionStorage.setItem('button_selectedIds', JSON.stringify(selectedIds));

    }, [selectedIds]);

    useEffect(() => {

      if (mouseHoverId) {

        const type = data?.find((item) => item._id === mouseHoverId)?.device.type;

        if (type === 'loraGateway') {

          document.getElementById(`marker-${mouseHoverId}`)?.classList.add('leaflet-gateway-hover');

        } else {

          document.getElementById(`marker-${mouseHoverId}`)?.classList.add('leaflet-marker-hover');

        }

      }
      return () => {

        const type = data?.find((item) => item._id === mouseHoverId)?.device.type;
        if (type === 'loraGateway') {

          document.getElementById(`marker-${mouseHoverId}`)?.classList.remove('leaflet-gateway-hover');

        } else {

          document.getElementById(`marker-${mouseHoverId}`)?.classList.remove('leaflet-marker-hover');

        }

      };

    }, [mouseHoverId]);

    useEffect(() => {

      const debouncedSearch = setTimeout(() => {

        setUpperSearchTerm(searchTerm);

      }, 500);
      return () => {

        clearTimeout(debouncedSearch);

      };

    }, [searchTerm]);

    useEffect(() => {

      unselectAll();

    }, [unselectAll]);

    const resetSearch = () => {

      setSearchTerm('');
      setUpperSearchTerm('');
      setZoomed(false);

    };

    const printAllLabels = async () => {

      const regex = /^[a-z0-9]+$/i;
      if (!data) return;
      const formattedData = data.filter((item: Asset & { device: Device }) => {

        return regex.test(item.device.deviceId);

      }).map((item: Asset & { device: Device }) => {

        return {
          devEui: item.device.deviceId,
          sequenceNumber: item.externalId?.split('-')?.slice(-1)[0] || ' ',
        };

      });

      await httpClient('https://xgac-dymo-printer.ngrok.dev/print', { method: 'POST' }, formattedData).catch((printError) => {

        console.error('Error printing labels:', printError);
        notify('Error occurred while printing labels');

      });

    };

    const handleGoToOrder = () => {

      const conceptButtons: Identifier[] = [];
      const conceptGateways: Identifier[] = [];
      data?.filter((item: Asset & { device: Device }) => {

        const hasSubstring = item.device.deviceId?.substring(0, 6) === 'Unpaid';
        if (selectedIds.length > 0) {

          return selectedIds.includes(item._id) && hasSubstring;

        }
        return hasSubstring;

      }).forEach((item: Asset & { device: Device }) => {

        if (item.device.type === DeviceType.Lora) {

          conceptButtons.push(item._id);

        } else if (item.device.type === DeviceType.LoraGateway) {

          conceptGateways.push(item._id);

        }

      });
      if (conceptButtons.length > 0 || conceptGateways.length > 0) {

        sessionStorage.setItem('conceptDevices', JSON.stringify({ conceptButtons, conceptGateways }));
        redirect('/bestellen');

      } else {

        notify('resources.buttons.text.no_concept_devices');

      }

    };

    const handleDeleteDevices = async () => {

      const assetsToDelete = listResult.data?.filter((item: Asset & { device: Device }) => {

        const hasSubstring = item.device.deviceId?.substring(0, 6) === 'Unpaid' || item.device.deviceId?.substring(0, 4) === 'Demo';
        if (selectedIds.length > 0) {

          return selectedIds.includes(item._id) && hasSubstring;

        }
        return hasSubstring;

      }).map((item: Asset & { device: Device }) => {

        return {
          _id: item._id,
          _ref: 'Asset',
        };

      });
      if (assetsToDelete?.map((item) => item._id).includes(expandedId.toString())) {

        setExpandedId('');

      }

      if (assetsToDelete) {

        try {

          const deleteDevicesResponse = await httpClient(
            `${apiUrl}/specials/bhv-knop/remove-unpaid-devices`,
            { method: 'POST' },
            {
              assets: assetsToDelete,
            },
          );
          if (deleteDevicesResponse) {

            notify('resources.buttons.text.deleted_unpaid_devices', { type: 'success' });
            refresh();

          }
          sessionStorage.setItem('button_selectedIds', JSON.stringify([]));
          setSelectedIds([]);

        } catch (e) {

          notify('resources.buttons.text.deleted_unpaid_devices_error', { type: 'error' });

        }

      }

    };

    const ButtonDataGridListItem = () => {

      const record = useRecordContext();
      if (!record) return <></>;
      const id = record._id;
      return (
        <div
          onMouseEnter={() => setMouseHoverId(id)}
          onMouseLeave={() => setMouseHoverId(null)}
          style={{ cursor: 'pointer' }}
        >
          <DivWithId className={selectedIds.includes(id) ? 'button-sidebar-grid-row selected-row' : 'button-sidebar-grid-row'} >
            {(mouseHoverId !== id && selectedIds.length === 0) ? (
              <>
                <div className="button-sidebar-grid-name">
                  <DeviceStatusIcon deviceStatus={deviceStatus} createAlarmMode={createAlarmMode}/>
                  <DeviceListName/>
                </div>
                {createAlarmMode
                  ? <StyledButton label="menu.items.create_alarm" onClick={resetSearch}/>
                  : <ExternalIdField externalId={record.externalId?.split('-')?.slice(-1)[0]} />}
              </>
            ) : (
              <>
                <div className="button-sidebar-grid-name">
                  <Checkbox
                    checked={selectedIds.includes(id)}
                    size="small"
                    className="button-sidebar-checkbox"
                    onClick={(e) => {

                      e.stopPropagation();
                      if (selectedIds.includes(id)) {

                        setSelectedIds(selectedIds.filter((selectedId) => selectedId !== id));

                      } else {

                        setSelectedIds([...selectedIds, id]);

                      }

                    }}/>
                  <DeviceListName/>
                </div>
                {/* eslint-disable-next-line no-nested-ternary */}
                {createAlarmMode
                  ? <StyledButton label="menu.items.create_alarm" onClick={resetSearch}/>
                  : mouseHoverId === id ? <IconButton size="small" sx={{ padding: 0, margin: 0 }}>
                    <EditIcon spacing={0} sx={{ padding: 0, margin: 0, fontSize: '20px' }}/>
                  </IconButton> : <ExternalIdField externalId={record.externalId?.split('-')?.slice(-1)[0]} />
                }
              </>
            )}
          </DivWithId>
        </div>
      );

    };

    const UnpaidBanner = () => {

      const hasUnpaid = data?.some((item: Asset & { device: Device }) => {

        return item.device.deviceId?.substring(0, 6) === 'Unpaid';

      });
      if (!hasUnpaid) return <></>;
      return <Grid className="button-sidebar-unpaid-banner" container>
        <Grid xs={8}>
          <div>
            <h4>{translate('resources.buttons.text.unpaid_devices')}</h4>
            <span onClick={() => {

              const newSelectedIds = listResult.data?.filter((item: Asset & { device: Device }) => {

                if (selectedIds.includes(item._id)) {

                  return item.device.deviceId?.substring(0, 6) !== 'Unpaid';

                }
                return false;

              }).map((item: Asset & { device: Device }) => item._id) || [];
              setSelectedIds(newSelectedIds);
              setDeleteDialogOpen(true);

            }
            }>{translate('resources.buttons.text.delete_unpaid')}</span>
          </div>
        </Grid>
        <Grid xs={4} style={{
          display: 'flex',
          justifyContent: 'end',
          alignItems: 'flex-end',
        }}>
          <button onClick={handleGoToOrder}>
            <ShoppingCartIcon/>
            <span>{translate('general.text.order_now')}</span>
          </button>
        </Grid>
      </Grid>;

    };

    return (
      <>
        <div className="button-sidebar-div">
          <Dialog open={massMoveOpen} onClose={() => setMassMoveOpen(false)} sx={{ zIndex: '7' }} maxWidth="md" fullWidth>
            <DialogTitle sx={{ paddingBottom: 0 }}><span>{translate('resources.buttons.text.mass_move_title')}</span></DialogTitle>
            <DialogContent>
              <MassMoveList
                selectedIds={selectedIds}
                setDialogOpen={setMassMoveOpen}
                setZoomed={setZoomed}
              />

            </DialogContent>
          </Dialog>
          {!createAlarmMode ? (
            <div className="button-sidebar-add" style={
              selectedIds.length === 0 ? {} : { paddingBottom: '30px' }
            }>
              <h4>
                {translate(selectedIds.length === 0 ? 'ra.action.create' : 'resources.buttons.text.actions_with_selected')}
                <div>
                  {authProvider.isAdmin() && (
                    <><StyledButton
                      label="resources.buttons.text.print_all_labels"
                      style={{ marginTop: '1em', marginRight: '10px' }}
                      color="primary"
                      onClick={() => setPrintDialogOpen(true)}
                    />
                    <Confirm
                      isOpen={printDialogOpen}
                      title="resources.buttons.text.print_all_labels"
                      content="resources.buttons.text.print_all_labels_confirm"
                      onConfirm={() => {

                        setPrintDialogOpen(false);
                        notify('Alle labels worden geprint');
                        printAllLabels();

                      }}
                      onClose={() => setPrintDialogOpen(false)}
                    />
                    </>
                  )}
                </div>
              </h4>

              <Grid container spacing={1}>
                {selectedIds.length === 0 ? (
                  <>
                    {typeToAdd === null ? (
                      <>
                        <Grid xs={4}>
                          <ButtonWithIconAndLabel
                            icon={buildingIcon}
                            label={translate('resources.buttons.text.building_dialog.building')}
                            onClick={() => setOpenStep(0)}
                          />
                        </Grid>
                        <Grid xs={4}>
                          <ButtonWithIconAndLabel
                            icon={bhvIcon}
                            label={translate('general.text.emergency_button')}
                            onClick={() => {

                              setTypeToAdd('button');

                            }}
                          />
                        </Grid>
                        <Grid xs={4}>
                          <ButtonWithIconAndLabel
                            icon={gatewayIcon}
                            label={translate('general.text.gateway')}
                            onClick={() => {

                              setTypeToAdd('gateway');

                            }}
                          />
                        </Grid>
                      </>
                    ) : (
                      <>
                        <Grid xs={12}>
                          <ButtonPageButtons icon={<CancelIcon/>} label="resources.buttons.text.stop_placing" onClick={() => {

                            setCancelLastClicked(new Date());
                            setTypeToAdd(null);

                          }}
                          />
                        </Grid>
                      </>
                    )}
                    <Grid xs={8}>
                      <MuiTextField
                        label={searchTerm.length > 0 ? '' : translate('resources.buttons.text.search')}
                        variant="outlined"
                        color="info"
                        fullWidth
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        size="small"
                        InputLabelProps={{
                          shrink: false,
                          sx: {
                            marginTop: '2px',
                            marginLeft: '30px',
                            fontSize: '12px',
                          },
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position={'start'}>
                              <SearchIcon/>
                            </InputAdornment>
                          ),
                          endAdornment: (
                            searchTerm.length > 0 && (
                              <InputAdornment position={'end'}>
                                <IconButton onClick={resetSearch} size="small">
                                  <CloseIcon fontSize="small"/>
                                </IconButton>
                              </InputAdornment>
                            )
                          ),
                          sx: {
                            height: '35px',
                            borderRadius: '3px',
                            borderColor: '#9AB4A3',
                            fontSize: '12px',
                            '& .MuiSvgIcon-root': {
                              color: 'black',
                            },
                          },
                        }}
                        inputProps={{
                          style: {
                            height: '40px',
                          },
                        }}
                      />
                    </Grid>
                    <Grid xs={4}>
                      <button
                        className={(selectedFilters.floors.length > 0 || selectedFilters.activeStatus.length > 0 || selectedFilters.deviceType.length > 0)
                          ? 'filter-device-button filter-device-button-active'
                          : 'filter-device-button'
                        }
                        onClick={() => {

                          setFilterOpen(!filterOpen);

                        }}
                      >
                        <TuneIcon/>
                        <span>
                          {translate((selectedFilters.floors.length > 0 || selectedFilters.activeStatus.length > 0 || selectedFilters.deviceType.length > 0)
                            ? 'general.text.filtered'
                            : 'general.text.filter')}
                        </span>
                      </button>
                    </Grid>
                  </>
                ) : (
                  <>
                    <Grid xs={3}>
                      <ButtonPageButtons icon={<RoomIcon/>} label="resources.buttons.text.mass_move" onClick={() => setMassMoveOpen(true)}/>
                    </Grid>
                    <Grid xs={3}>
                      <ButtonPageButtons icon={<ShoppingCartIcon/>} label="general.text.order" onClick={handleGoToOrder}/>
                    </Grid>
                    <Grid xs={3}>
                      <ButtonPageButtons icon={<IosShareIcon sx={{ transform: 'rotate(90deg)' }}/>} label="ra.action.export" onClick={() => {

                        if (!data) return;
                        const selectedAssets = data?.filter(
                          (item: Asset & { device: Device }) => selectedIds.includes(item._id),
                        ) as Array<Asset & { device: Device }> || [];
                        buttonAssetExporter(selectedAssets);

                      }}/>
                    </Grid>
                    <Grid xs={3}>
                      <ButtonPageButtons icon={<DeleteIcon/>} label="ra.action.delete" onClick={() => setDeleteDialogOpen(true)} disabled={

                        !data?.some((item: Asset & { device: Device }) => {

                          return selectedIds.includes(item._id)
                            && (item.device.deviceId?.substring(0, 6) === 'Unpaid' || item.device.deviceId?.substring(0, 4) === 'Demo');

                        })

                      }/>
                    </Grid>
                  </>
                )}
              </Grid>
              <DeviceFilters
                filters={selectedFilters}
                onChange={setSelectedFilters}
                possibleFloors={possibleFloors}
                open={filterOpen}
                setOpen={setFilterOpen}
              />
            </div>

          ) : (
            <div className="button-sidebar-header">
              <h4>{translate(createAlarmMode ? 'resources.alarms.text.create_an_alarm' : 'resources.buttons.text.manage_buttons')}</h4>
              <StyledButton label="resources.buttons.text.show_all" style={{ marginTop: '1em', marginRight: '10px' }} onClick={resetSearch}/>
              <StyledButton
                label={isTrial ? 'general.text.order' : 'general.text.order_extra'}
                onClick={() => redirect('/bestellen')}
                style={{ marginTop: '1em', marginRight: '10px' }}
                color="primary"
              />
            </div>
          )}
          {selectedIds.length > 0 && (
            <div className="button-sidebar-select-all">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Checkbox
                      checked={selectedIds.length === data?.length}
                      indeterminate={selectedIds.length > 0 && selectedIds.length < (data?.length || 0)}
                      className="button-sidebar-checkbox"
                      onClick={() => {

                        if (selectedIds.length === data?.length) {

                          setSelectedIds([]);

                        } else {

                          setSelectedIds(data?.map((item: any) => item._id) || []);

                        }

                      }}/>
                  </TableCell>
                  <TableCell>
                    <span>{translate(selectedIds.length === data?.length ? 'general.text.deselect_all' : 'general.text.select_all')}</span>
                  </TableCell>
                </TableRow>
              </TableHead>
            </div>
          )}
          <div className="button-sidebar-grid">
            <Datagrid
              resource="all-assets"
              data={data}
              isLoading={isLoading}
              total={total}
              expandSingle={true}
              rowStyle={(record: any, index: number) => {

                if (selectedIds.includes(record._id)) {

                  return {
                    backgroundColor: '#9FD2B6',
                  };

                }
                return {
                  backgroundColor: index % 2 === 0 ? '#F0F9F5' : '#E2F1EA',
                };

              }}
              sx={{
                '& .RaDatagrid-expandedPanel': {
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  backgroundColor: (data || []).indexOf((data || []).find((item: any) => item._id === expandedId)) % 2 === 0 ? '#F0F9F5' : '#E2F1EA',
                },
              }}
              bulkActionButtons={false}
              expand={createAlarmMode ? undefined : <DeviceEditForm gatewayStatus={gatewayStatus}/>}
              header={ <EmptyHeader/>}
              rowClick={createAlarmMode ? (id, resource, record) => {

                setCreateAlarmOpen(record);
                return false;

              } : 'toggleSelection'}
              onToggleItem={(id) => toggleItemExpansion(id)}
            >
              <ButtonDataGridListItem/>
            </Datagrid>
          </div>
          <UnpaidBanner/>
        </div>
        <Confirm
          content="resources.buttons.text.delete_add_again"
          isOpen={deleteDialogOpen}
          onClose={() => setDeleteDialogOpen(false)}
          onConfirm={handleDeleteDevices}
          title="resources.buttons.text.delete_are_you_sure"
        />
      </>
    );

  };

  const createAlarm = async () => {

    const creationData = {
      name: 'BHV-Oproep',
      type: 'assistance',
      severity: 'orange',
      asset: {
        _id: createAlarmOpen?._id,
        _ref: 'Asset',
      },
      position: createAlarmOpen?.position || null,
      procedure: 'BHV-knop demo alarm, geen actie nodig',
    };

    await create('alarms', {
      data: creationData,
    });

    if (!error) {

      notify('BHV-oproep aangemaakt, de BHV\'ers worden gealarmeerd.');
      redirect('/alarms?page=1');

    }

  };

  return <>
    <Confirm
      isOpen={createAlarmOpen !== null}
      title={createAlarmOpen?.name || ''}
      content={<div>
          Weet u zeker dat u een alarm wil maken voor deze knop?
        <hr />
        <strong>Huidige adres: </strong>
        {createAlarmOpen?.position?.properties?.address?.formattedAddress}
        <hr/>
        <div className={'simulationFlow'}>
          <div className="sf-alarm">{translate('general.text.alarm')}</div>
          {simulateResponse ? <>
            {simulateResponse !== 'nobody'
              ? <>
                {simulateResponse}
              </>
              : <div className="sf-step" style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}>
                <Button
                  onClick={() => redirect('/help/dashboard/waarom-wordt-er-niemand-opgeroepen')}
                  size="small"
                  style={{ textTransform: 'none' }}
                  variant={'outlined'}
                  color={'primary'}
                  label={translate('resources.buttons.text.status.nobody_why')}
                />
              </div>
            }
          </>
            : (<div style={{ display: 'flex', justifyContent: 'center' }}>
              <CircularProgress/>
            </div>)
          }
        </div>
      </div>}
      onConfirm={() => {

        createAlarm();
        setCreateAlarmOpen(null);

      }}
      onClose={() => setCreateAlarmOpen(null) }
    />
    <Grid container sx={{ height: '100%' }}>
      <Grid md={9} xs={9}>
        <MapContainer
          ref={(ref: any) => setMap(ref)}
          center={[52.1009166, 5.6462914]}
          zoom={17}
          id="button-map-container"
          minZoom={4}
          zoomControl={false}
          style={{ height: '100%' }}
        >
          <div>
            <div className="leaflet-control-container-custom">
              <CustomLayerControl
                map={map}
                toggleVisible={true}
                attribution={translate('resources.buttons.text.gateway_map_disclaimer')}
                setUpperTileLayer={setMapTileLayer}
              />
              <CenterControl onClick={() => {

                setZoomed(false);

              }}/>
            </div>
            <FloorFilter floors={possibleFloors} selectedFloors={selectedFilters.floors} onChange={setChosenFloors} />
          </div>
          <ScaleControl position="bottomright" imperial={false}/>
          {data && data.map((item: Asset & { device: Device }) => {

            if (item.position && item.position.coordinates && item.position.coordinates.length > 0) {

              return (<ButtonMapMarker
                createAlarmMode={createAlarmMode}
                setCreateAlarmOpen={setCreateAlarmOpen}
                key={item._id}
                item={item}
                expandedId={expandedId}
                setExpandedId={setExpandedId}
                toggleItemExpansion={toggleItemExpansion}
                mapTileLayer={mapTileLayer}
              />);

            }
            return null;

          })}
        </MapContainer>
      </Grid>
      <Grid md={3} xs={3}>
        <ButtonSideBar />
      </Grid>
    </Grid>
    <AddBuildingDialog
      openStep={openStep}
      setOpenStep={setOpenStep}
      setChosenFloors={setChosenFloors}
      setChosenBuilding={setSelectedBuildings}
      data={data}
    />
    <AddButtonOrGatewayDialog
      setAddType={setTypeToAdd}
      setChosenFloors={setChosenFloors}
      addType={typeToAdd}
      map={map}
      cancelLastClicked={cancelLastClicked}
      setSelectedBuilding={setSelectedBuildings}
      chosenFloors={selectedFilters.floors}
    />
  </>;

};
