import {
  Button, Labeled, Loading, useGetRecordId, useNotify, useTranslate,
} from 'react-admin';
import React, { useEffect, useState } from 'react';
import {
  Dialog, DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Select as MuiSelect,
  TextField as MuiTextField,
} from '@mui/material';
import { reportDataProvider } from '../../dataProviders/reportDataProvider';
import { timeGranularityType } from '../../lib/constants/selectChoices';

export const DownloadAssetHistoryButton = () => {

  const [dialogOpen, setDialogOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const notify = useNotify();
  const translate = useTranslate();
  const currentAssetId = useGetRecordId();
  const [timeFrame, setTimeFrame] = useState<{ type: string; value: number }>(
    { type: 'week', value: 1 },
  );
  const [timeFrameType, setTimeFrameType] = useState<string>('week');
  const [timeFrameValue, setTimeFrameValue] = useState<number>(1);

  useEffect(() => {

    const localLastTimeFrame = localStorage.getItem('assetHistoryTimeFrame');
    if (localLastTimeFrame) {

      setTimeFrame(JSON.parse(localLastTimeFrame));
      setTimeFrameType(JSON.parse(localLastTimeFrame).type);
      setTimeFrameValue(JSON.parse(localLastTimeFrame).value);

    }

  }, []);

  const getHistoryFromDataProvider = async () => {

    setTimeFrame({ type: timeFrameType, value: timeFrameValue });
    localStorage.setItem('assetHistoryTimeFrame', JSON.stringify({ type: timeFrameType, value: timeFrameValue }));
    setIsLoading(true);
    let reportResult;
    try {

      reportResult = await reportDataProvider.getAssetHistory(currentAssetId.toString(), {
        type: timeFrameType,
        value: timeFrameValue,
      });

    } catch (e) {

      notify('resources.unhealthyBeacons.text.noAttachments', { type: 'warning' });
      setIsLoading(false);
      setDialogOpen(false);
      return;

    }

    if (!reportResult.emailAttachments || reportResult.emailAttachments?.length === 0) {

      setIsLoading(false);
      setDialogOpen(false);
      notify('resources.unhealthyBeacons.text.noAttachments', { type: 'warning' });
      return;

    }

    await reportDataProvider.getAttachmentForReport(reportResult.emailAttachments, document);
    setIsLoading(false);
    setDialogOpen(false);

  };

  return (
    <>
      <Button label="resources.assets.text.download_history_button" onClick={() => setDialogOpen(true)} />
      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)} fullWidth={true} maxWidth="md">
        <DialogTitle>
          {translate('resources.assets.text.download_history_button')}
        </DialogTitle>
        {isLoading
          ? (<Loading loadingSecondary=""/>)
          : (<>
            <DialogContent>
              <Labeled label="resources.assets.download_history_for_last">
                <>
                  <div>
                    <MuiTextField defaultValue={timeFrame.value} onChange={(e) => {

                      setTimeFrameValue(parseInt(e.target.value, 10));

                    }} type="number"
                    label={translate('resources.report-triggers.fields.time_granularity.value')}
                    value={timeFrameValue}
                    size="small"
                    />
                    <MuiSelect defaultValue={timeFrame.type}
                      size="small"
                      onChange={(e) => {

                        setTimeFrameType(e.target.value);

                      }}
                      label={translate('resources.report-triggers.fields.time_granularity.type')}
                      value={timeFrameType}
                      variant="filled"
                      sx={{ marginTop: '8px' }}

                    >
                      {timeGranularityType.map((choice) => {

                        return (<MenuItem value={choice.id}>{translate(choice.name)}</MenuItem>);

                      })}
                    </MuiSelect>
                  </div>
                </>
              </Labeled>
            </DialogContent>
            <DialogActions>
              <Button label="resources.assets.text.download_history_button" onClick={() => getHistoryFromDataProvider()} />
              <Button label="ra.action.cancel" onClick={() => setDialogOpen(false)} />
            </DialogActions>
          </>
          )}
      </Dialog>
    </>);

};
