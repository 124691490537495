import { ReactNode } from 'react';
import {
  Button,
  Dialog, DialogActions, DialogTitle, IconButton,
} from '@mui/material';
import { useTranslate } from 'ra-core';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

export const StepDialogCard = (props: {
  open: boolean;
  title: string;
  content: ReactNode;
  onClose: () => void;
  nextLabel?: string;
  onNext?: () => void;
  hintText?: string;
  nextDisabled?: boolean;
  onBack?: () => void;
}) => {

  const translate = useTranslate();
  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      fullWidth
      maxWidth="md"
      transitionDuration={0}
      sx={{ zIndex: 7 }}
      PaperProps={{
        sx: {
          borderRadius: '0',
        },

      }}
    >
      <DialogTitle className="flex-in-between">
        <div style={{
          display: 'flex',
          alignItems: 'center',
        }}>
          {props.onBack
          && <IconButton onClick={props.onBack} sx={{ marginLeft: '-10px', marginRight: '10px' }}>
            <ArrowBackIcon />
          </IconButton>
          }
          <span>{translate(props.title)}</span>
        </div>
        <IconButton onClick={props.onClose}>
          <CloseIcon fontSize="large" />
        </IconButton>
      </DialogTitle>
      {props.content}
      {(props.onNext || props.hintText) && (
        <DialogActions className="building-add-actions">
          <div>
            {props.hintText && (
              props.hintText
            )}
          </div>
          {props.onNext && (
            <Button
              onClick={props.onNext}
              color="primary"
              variant={'contained'}
              size="small"
              disabled={props.nextDisabled}
            >
              {translate(props.nextLabel || 'ra.navigation.next')}
            </Button>
          )}
        </DialogActions>
      )}
    </Dialog>
  );

};
