import {
  TextField, useRecordContext, useTranslate, ReferenceArrayField, ReferenceArrayFieldProps,
} from 'react-admin';
import { get } from 'lodash';

const ReferenceArrayFieldNullable = (props: ReferenceArrayFieldProps) => {

  const translate = useTranslate();
  const record = useRecordContext();
  const value = get(record, props.source || '');

  if (!props.source || !value || value.length === 0) return <TextField source="value" record={{ value: translate('general.text.none') }} className="gray"/>;
  return <ReferenceArrayField {...props} sx={{ marginLeft: '-5px' }}/>;

};

export default ReferenceArrayFieldNullable;
