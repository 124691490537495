import {
  Alert,
  Card,
  CardContent,
  Chip,
  Icon,
  InputAdornment,
  InputLabel,
  Stack,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import * as React from 'react';
import {
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslate } from 'ra-core';
import {
  AnyGeometry, Asset, AssetRef, Device, DeviceType,
} from '@x-guard/xgac-types/xgac';
import { MapContainer } from 'react-leaflet';
import L, {
  LatLng, latLngBounds, Layer, Map, Polygon,
} from 'leaflet';
import GeometryUtil from 'leaflet-geometryutil';
import CircularProgress from '@mui/material/CircularProgress';
import * as turf from '@turf/turf';
import { Feature, FeatureCollection, Properties } from '@turf/turf';
import { useRefresh } from 'react-admin';
import HomeIcon from '@mui/icons-material/Home';
import Grid2 from '@mui/material/Unstable_Grid2';
import { StepDialogCard } from '../cards/stepDialogCard';
import { GoogleCoordinatesInput } from './inputs/GoogleCoordinatesInput';
import { IntegerInput } from './inputs/integerInput';
import areaIcon from '../svg/area.svg';
import bhvIcon from '../svg/bhv-button.svg';
import gatewayIcon from '../svg/gateway_icon.svg';
import buildingIcon from '../svg/building.svg';
import factoryIcon from '../svg/factory.svg';
import defaultGatewayIcon from '../svg/gateway_default.svg';
import defaultButtonIcon from '../svg/button_default.svg';
import { pointsInPolygon } from '../../../utils/pointsInPolygon';
import { wait } from '../../../lib/websockets/webSocketHelpers';
import { CustomLayerControl } from './LeafletControls';
import { getCurrentCustomer } from '../../../lib/currentCustomer';
import { httpClient } from '../../../utils/httpClient';
import { PDOK_URL, XGAC_MAIN_API_URL } from '../../../config';
import { ButtonWithIconAndLabel, LabelButton } from '../buttons/ButtonPageButtons';
import useIsTabActive from '../../../utils/useIsTabActive';

const Grid = Grid2;

const apiUrl = XGAC_MAIN_API_URL;

const fillColorSelected = 'blue';
const colorSelected = 'blue';
const fillColorNotSelected = 'red';
const colorNotSelected = 'red';

export const AddBuildingDialog = (props: {
  openStep: number | null;
  setOpenStep: Dispatch<number | null>;
  setChosenFloors: Dispatch<number[]>;
  setChosenBuilding: Dispatch<SetStateAction<AnyGeometry[] | null>>;
  data: Array<Asset & { device: Device }>;
}) => {

  const [newPosition, setNewPosition] = useState<AnyGeometry | null>(null);
  const [positionWarning, setPositionWarning] = useState('');
  const [selectedBuildings, setSelectedBuildings] = useState<any[]>([]);
  const [hasError, setHasError] = useState<boolean>(false);
  const [proposedDevices, setProposedDevices] = useState<{
    buttons: FeatureCollection | null;
    gateways: FeatureCollection | null;
    buildingId: string;
    floors: number;
  }[]>([]);
  const [upperBuildingSettings, setUpperBuildingSettings] = useState<{
    buildingId: string;
    floors: number;
    type: 'office' | 'factory';
    buttonDistance: 25 | 30 | 35;
  }[]>([]);
  const deviceTotals = useMemo(() => {

    const totalButtons = proposedDevices.reduce((acc, building) => acc + ((building.buttons?.features.length || 0) * building.floors), 0);
    const totalGateways = proposedDevices.reduce((acc, building) => acc + ((building.gateways?.features.length || 0) * (Math.ceil(building.floors / 3))), 0);

    return {
      totalButtons,
      totalGateways,
    };

  }, [proposedDevices]);
  const translate = useTranslate();
  const currentCustomer = getCurrentCustomer();
  const refresh = useRefresh();

  const { openStep, setOpenStep } = props;

  useEffect(() => {

    if (newPosition) {

      if (!newPosition.properties.address?.streetNumber) {

        setPositionWarning('resources.buttons.text.building_dialog.no_streetnumber');
        return;

      }
      if (newPosition.properties.address.countryCode !== 'NL') {

        setPositionWarning('resources.buttons.text.building_dialog.not_in_netherlands');
        return;

      }
      setOpenStep(1);
      setPositionWarning('');

    }

  }, [newPosition]); // eslint-disable-line react-hooks/exhaustive-deps

  const closeDialog = () => {

    setOpenStep(null);
    setNewPosition(null);
    setProposedDevices([]);
    setSelectedBuildings([]);
    setUpperBuildingSettings([]);

  };

  const uploadDevicesAndCloseDialog = async () => {

    const deviceDtos = [];
    for (const building of proposedDevices) {

      for (let i = 0; i < building.floors; i++) {

        for (const proposedButton of building.buttons?.features || []) {

          deviceDtos.push({
            type: DeviceType.Lora,
            position: {
              type: 'Point',
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              coordinates: proposedButton.geometry.coordinates,
              properties: {
                address: {
                  formattedAddress: newPosition?.properties.address?.formattedAddress,
                  streetName: newPosition?.properties.address?.streetName,
                  streetNumber: newPosition?.properties.address?.streetNumber,
                  city: newPosition?.properties.address?.city,
                  state: newPosition?.properties.address?.state,
                  floor: i,
                  countryCode: newPosition?.properties.address?.countryCode,
                },
              },
            },
          });

        }
        if (i % 3 === 0) {

          for (const proposedGateway of building.gateways?.features || []) {

            deviceDtos.push({
              type: DeviceType.LoraGateway,
              position: {
                type: 'Point',
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                coordinates: proposedGateway.geometry.coordinates,
                properties: {
                  address: {
                    formattedAddress: newPosition?.properties.address?.formattedAddress,
                    streetName: newPosition?.properties.address?.streetName,
                    streetNumber: newPosition?.properties.address?.streetNumber,
                    city: newPosition?.properties.address?.city,
                    state: newPosition?.properties.address?.state,
                    floor: i,
                    countryCode: newPosition?.properties.address?.countryCode,
                  },
                },
              },
            });

          }

        }

      }

    }
    const addDevicesDto = {
      customer: {
        _id: currentCustomer?.value,
        _ref: 'Customer',
      },
      devices: deviceDtos,
    };

    const isDemo = props.data.length > 0 && props.data.filter((asset) => {

      return asset.device.deviceId.substring(0, 4) !== 'Demo';

    }).length === 0;

    const allIds: AssetRef[] = props.data.map((asset) => {

      return {
        _id: asset._id,
        _ref: 'Asset',
      };

    });

    if (isDemo) {

      await httpClient(`${apiUrl}/specials/bhv-knop/remove-unpaid-devices`, {
        method: 'POST',
      }, { assets: allIds });

    }

    await httpClient(`${apiUrl}/specials/bhv-knop/add-devices`, {
      method: 'POST',
    }, addDevicesDto);

    props.setChosenFloors([]);
    props.setChosenBuilding(selectedBuildings);
    refresh();
    closeDialog();

  };

  const getMarker = (icon: string, small?: boolean) => {

    if (small) {

      return L.divIcon({
        className: 'button-icon',
        iconSize: [20, 31],
        popupAnchor: [-5, -26],
        iconAnchor: [10, 30],
        html: `<img src="${icon}" style={{ width: '15px' }}/>`,
      });

    }
    return L.divIcon({
      className: 'button-icon',
      iconSize: [40, 61],
      popupAnchor: [-5, -46],
      iconAnchor: [10, 30],
      html: `<img src="${icon}" style={{ width: '30px' }}/>`,
    });

  };

  const SquareSingleMap = (innerProps: {
    addPoints?: boolean;
    feature: FeatureCollection;
    index?: number;
  }) => {

    const [map, setMap] = useState<Map | null>(null);
    const [circlesAdded, setCirclesAdded] = useState<boolean>(false);
    const middle = useMemo(() => {

      return turf.centerOfMass(innerProps.feature).geometry.coordinates as [number, number];

    }, [innerProps.feature]);

    function createGradient(svg: any, id: string, stops: any, defs: any) {

      const radGrad = document.createElementNS(svg.namespaceURI, 'radialGradient');
      radGrad.setAttribute('id', id);
      for (const s of stops) {

        const stop = document.createElementNS(svg.namespaceURI, 'stop');
        for (const k in s) {

          stop.setAttribute(k, s[k]);

        }
        radGrad.appendChild(stop);

      }
      defs.appendChild(radGrad);

    }

    const addCircles = () => {

      if (!map || circlesAdded) {

        return;

      }
      map?.eachLayer((l: Layer) => {

        if (l instanceof L.Marker) {

          const geoJsonLayer = l.toGeoJSON();
          if (geoJsonLayer.properties.type === 'gateway') {

            const circle = L.circle([geoJsonLayer.geometry.coordinates[1], geoJsonLayer.geometry.coordinates[0]], {
              radius: 100,
              weight: 0,
              fillOpacity: 0.2,
            });

            circle.addTo(map);

            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const svg = circle.getElement().farthestViewportElement;
            const defs = svg.querySelector('defs') || svg.insertBefore(document.createElementNS(svg.namespaceURI, 'defs'), svg.firstChild);

            createGradient(svg, 'radgrad', [
              { offset: '0%', 'stop-color': 'rgba(0, 133, 33, 1)' },
              { offset: '20%', 'stop-color': 'rgba(0, 133, 33,  1)' },
              { offset: '90%', 'stop-color': 'rgba(0, 133, 33, 0.1)' },
              { offset: '95%', 'stop-color': 'rgba(0, 133, 33, 0)' },
            ], defs);

            circle.setStyle({
              fillColor: 'url(#radgrad)',
            });

          }

        }

      });
      setCirclesAdded(true);

    };

    map?.whenReady(() => {

      const featureIds = innerProps.feature.features.map((feature) => {

        return feature.properties?.identificatie;

      });
      for (const indexFeature of innerProps.feature.features) {

        const innerFeature = {
          type: 'Feature',
          geometry: indexFeature.geometry,
          properties: indexFeature.properties,
          id: indexFeature.properties?.identificatie,
        };
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const layer = L.geoJSON(innerFeature).setStyle({
          fillColor: fillColorSelected,
          color: colorSelected,
          fillOpacity: 0.4,
        });
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        let hasLayer = false;
        map?.eachLayer((l: Layer) => {

          if (l instanceof L.Polygon) {

            if (l.toGeoJSON().id === innerFeature.id) {

              hasLayer = true;

            } else if (!featureIds.includes(l.toGeoJSON().id)) {

              map.removeLayer(l);

            }

          }

        });

        if (!hasLayer) {

          layer.addTo(map);

        }

        if (innerProps.addPoints) {

          map?.eachLayer((l: Layer) => {

            if (l instanceof L.Marker) {

              map?.removeLayer(l);

            }

          });

          for (const building of proposedDevices) {

            if (building.buttons) {

              L.geoJSON(building?.buttons.features, {
                pointToLayer(geoJsonPoint: Feature<any>, latlng: LatLng): Layer {

                  return L.marker(latlng, {
                    icon: getMarker(defaultButtonIcon, true),
                  });

                },
              }).addTo(map);

            }
            if (building.gateways) {

              L.geoJSON(building?.gateways.features, {
                pointToLayer(geoJsonPoint: Feature<any>, latlng: LatLng): Layer {

                  return L.marker(latlng, {
                    icon: getMarker(defaultGatewayIcon, true),
                  });

                },
              }).addTo(map);

            }

          }

        }

      }
      addCircles();

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      map?.fitBounds(L.geoJSON(innerProps.feature).getBounds(), { maxZoom: 19 });

    });

    return (
      <MapContainer
        center={middle}
        zoom={19}
        ref={(ref: any) => setMap(ref)}
        zoomControl={false}
        style={{
          height: '300px',
          width: '300px',
          borderRadius: '20px',
          zIndex: 0,
        }}
      >
        {innerProps.index && (
          <div
            className="building-map-upper-counter"
          >
            <div className="building-counter-icon">
              {innerProps.index}
            </div>
          </div>
        )}
        <CustomLayerControl map={map}/>
      </MapContainer>
    );

  };
  const handleClickOnPolygon = (
    layer: any,
    tempSelectedBuildings: any[],
    setTempSelectedBuildings: Dispatch<SetStateAction<any[]>>,
  ) => {

    if (tempSelectedBuildings.find((building) => {

      return building.id === layer.toGeoJSON().id;

    })) {

      setTempSelectedBuildings(tempSelectedBuildings.filter((building) => {

        return building.id !== layer.toGeoJSON().id;

      }));
      layer.setStyle({
        fillColor: fillColorNotSelected,
        color: colorNotSelected,
      });

    } else {

      setTempSelectedBuildings([...tempSelectedBuildings, layer.toGeoJSON()]);
      layer.setStyle({
        fillColor: fillColorSelected,
        color: colorSelected,
      });

    }

  };

  const AddBuildingMapStep = () => {

    const [map, setMap] = useState<Map | null>(null);
    const [tempSelectedBuildings, setTempSelectedBuildings] = useState<any[]>(selectedBuildings || []);
    const [combinedAreaSize, setCombinedAreaSize] = useState<number>(0);
    const isTabActive = useIsTabActive();

    useEffect(() => {

      if (!isTabActive) {

        setSelectedBuildings(tempSelectedBuildings);

      }

    }, [isTabActive]); // eslint-disable-line react-hooks/exhaustive-deps
    useEffect(() => {

      setCombinedAreaSize(tempSelectedBuildings.reduce((acc, building) => acc + building.areaSize, 0));

    }, [tempSelectedBuildings]);

    useEffect(() => {

      if (!map) {

        return;

      }
      map?.eachLayer((l: Layer) => {

        if (l instanceof L.Polygon) {

          l.off('click');
          l.on('click', () => handleClickOnPolygon(l, tempSelectedBuildings, setTempSelectedBuildings));

        }
        if (l instanceof L.Marker) {

          l.remove();

        }

      });
      tempSelectedBuildings.forEach((building: any, index) => {

        const center = turf.centerOfMass(building.geometry);
        const marker = L.marker([center.geometry.coordinates[1], center.geometry.coordinates[0]], {
          icon: L.divIcon({
            iconAnchor: [10, 10],
            className: 'building-counter-icon',
            html: `<span>${index + 1}</span>`,
          }),
        }).addTo(map);
        let foundLayer: any;
        map?.eachLayer((layer: Layer) => {

          if (layer instanceof L.Polygon && layer.toGeoJSON().id === building.id) {

            foundLayer = layer;

          }

        });
        if (foundLayer) {

          marker.on('click', () => handleClickOnPolygon(foundLayer, tempSelectedBuildings, setTempSelectedBuildings));

        }

      });

    }, [tempSelectedBuildings, map]);

    const mapHasError = useMemo(() => {

      if (tempSelectedBuildings.length <= 1) {

        return false;

      }
      for (const building of tempSelectedBuildings) {

        const otherBuildings = tempSelectedBuildings.filter((b) => b.id !== building.id);
        const bboxes = otherBuildings.map((b) => turf.bboxPolygon(turf.bbox(b.geometry)));
        let shouldSkip = false;
        for (const bbox of bboxes) {

          const buildingBbox = turf.bboxPolygon(turf.bbox(building.geometry));

          if (turf.booleanOverlap(buildingBbox, bbox)
            || turf.intersect(buildingBbox, bbox)
            || turf.booleanContains(bbox, buildingBbox)
            || turf.booleanContains(buildingBbox, bbox)) {

            shouldSkip = true;

          }

        }
        if (shouldSkip) {

          continue;

        }
        const otherFeatureCollection = turf.featureCollection(bboxes);
        const allPoints = turf.explode(otherFeatureCollection);

        const buildingBbox = turf.bboxPolygon(turf.bbox(building.geometry));
        const buildingPoints = turf.explode(buildingBbox);
        let closestDistance = Infinity;
        turf.featureEach(buildingPoints, (currentFeature) => {

          const closestPoint = turf.nearestPoint(currentFeature, allPoints);
          const distance = turf.distance(closestPoint, currentFeature) * 1000;
          if (!closestDistance || distance < closestDistance) {

            closestDistance = distance;

          }

        });
        if (closestDistance > 30) {

          return true;

        }

      }
      return false;

    }, [tempSelectedBuildings]);

    const loadGeoData = (data: { features: any[] }) => {

      if (!newPosition || !map) {

        return;

      }

      data.features.forEach((feature) => {

        const innerFeature = {
          type: 'Feature',
          geometry: feature.geometry,
          properties: feature.properties,
          areaSize: Math.round(turf.area(feature.geometry)),
          id: feature.properties.identificatie,
        };

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        L.geoJSON(innerFeature).setStyle({
          fillColor: fillColorNotSelected,
          color: colorNotSelected,
          fillOpacity: 0.4,
        })
          .on('mouseover', (e: any) => {

            e.target.setStyle({
              fillOpacity: 0.6,
            });

          })
          .on('mouseout', (e: any) => {

            e.target.setStyle({
              fillOpacity: 0.4,
            });

          })
          .addTo(map);

      });

      const polygonLayers: Polygon[] = [];
      map?.eachLayer((layer: Layer) => {

        if (layer instanceof L.Polygon) {

          polygonLayers.push(layer);

        }

      });
      const filteredBuildings = tempSelectedBuildings.filter((building) => {

        return polygonLayers.find((layer) => layer.toGeoJSON().id === building.id);

      });

      if (filteredBuildings.length > 0) {

        setTempSelectedBuildings(filteredBuildings);
        map?.eachLayer((layer: Layer) => {

          if (layer instanceof L.Polygon) {

            if (filteredBuildings.find((building) => building.id === layer.toGeoJSON().id)) {

              layer.setStyle({
                fillColor: fillColorSelected,
                color: colorSelected,
              });

            }

          }

        });
        map?.fitBounds(L.geoJSON(filteredBuildings).getBounds(), { maxZoom: 19, animate: false });

        return;

      }

      const foundLayer = GeometryUtil.closestLayer(map, polygonLayers, [newPosition.coordinates[1], newPosition.coordinates[0]] as [number, number]);
      setTempSelectedBuildings([foundLayer?.layer.toGeoJSON()]);
      const bounds = foundLayer?.layer.getBounds();
      if (bounds) {

        map?.fitBounds(bounds, { maxZoom: 19, animate: false });

      }
      foundLayer?.layer.setStyle({
        fillColor: fillColorSelected,
        color: colorSelected,
      });

    };

    useEffect(() => {

      if (!map) {

        return;

      }

      const bounds = map.getBounds().pad(1.5);
      const url = `${PDOK_URL}&bbox=${bounds.toBBoxString()},CRS`;

      fetch(url)
        .then((response) => {

          return response.json();

        })
        .then((data) => {

          loadGeoData(data);

        });

    }, [map]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
      <StepDialogCard
        open={openStep === 1}
        title={'resources.buttons.text.building_dialog.1.title'}
        onClose={closeDialog}
        onBack={() => {

          setOpenStep(0);
          setSelectedBuildings([]);
          setUpperBuildingSettings([]);

        }}
        onNext={() => {

          setSelectedBuildings(tempSelectedBuildings);
          setUpperBuildingSettings([]);
          setOpenStep(2);

        }}
        nextLabel="resources.buttons.text.building_dialog.1.next_button"
        nextDisabled={!tempSelectedBuildings || tempSelectedBuildings.length === 0}
        content={
          <>
            <div className="building-add-explanation">
              <span>{translate('resources.buttons.text.building_dialog.1.explanation')}</span>
            </div>
            <MapContainer
              center={newPosition?.coordinates ? [newPosition.coordinates[1], newPosition.coordinates[0]] as [number, number] : [52.1009166, 5.6462914]}
              ref={(ref: any) => setMap(ref)}
              zoom={19}
              zoomControl={false}
              style={{
                height: '400px',
                width: '100%',
                zIndex: 0,
              }}
            >
              <div style={{
                position: 'absolute',
                top: '20px',
                left: '20px',
                zIndex: 1000,
              }}>
                <Card style={{ minWidth: '175px', minHeight: '80px' }}>
                  {!tempSelectedBuildings ? (

                    <div style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '80px',
                    }}>
                      <CircularProgress/>
                    </div>
                  ) : (
                    <div className="building-add-1-map-area-div">
                      <span style={{ display: 'block' }}>
                        {translate('general.fields.position.address.name')}:
                        <span
                          style={{ color: 'blue' }}> {newPosition?.properties.address?.streetName} {newPosition?.properties.address?.streetNumber}</span>
                      </span>
                      <Stack direction={'row'} spacing={1}>
                        <Chip
                          variant={'outlined'}
                          icon={<HomeIcon/>}
                          label={translate(
                            'resources.buttons.text.building_dialog.1.building_count',
                            { smart_count: tempSelectedBuildings.length },
                          )}
                          sx={{
                            textTransform: 'none',
                            borderRadius: '15px',
                            height: '30px',
                          }}
                          size="medium"
                        />
                        <Chip
                          variant={'outlined'}
                          icon={<Icon sx={{ marginRight: '10px', height: '70%' }}><img src={areaIcon} alt="Area icon"/>
                          </Icon>}
                          label={translate(
                            'resources.buttons.text.building_dialog.1.surface_area',
                            { area: combinedAreaSize || 0 },
                          )}
                          sx={{
                            textTransform: 'none',
                            borderRadius: '15px',
                            height: '30px',
                          }}
                          size="medium"
                        />
                      </Stack>
                    </div>
                  )}
                </Card>
              </div>
              {(mapHasError || tempSelectedBuildings.length > 5) && (
                <div className="map-building-warning">
                  <div>
                    {mapHasError && (
                      <span>
                        {translate(
                          'resources.buttons.text.building_distance_warning',
                          { address: `${newPosition?.properties.address?.streetName} ${newPosition?.properties.address?.streetNumber}` },
                        )}
                      </span>
                    )}
                    {tempSelectedBuildings.length > 5 && (
                      <span>
                        {translate('resources.buttons.text.building_dialog.max_buildings', { count: 5 })}
                      </span>
                    )}
                  </div>
                </div>
              )}
              <CustomLayerControl map={map}/>
            </MapContainer>
          </>
        }/>
    );

  };

  const FloorSelectionMapStep = () => {

    const [innerFloors, setInnerFloors] = useState<number>(1);
    const [officeOrFactory, setOfficeOrFactory] = useState<'office' | 'factory'>('office');
    const [buttonDistance, setButtonDistance] = useState<25 | 30 | 35>(30);
    const [buildingSettings, setBuildingSettings] = useState<{
      buildingId: string;
      floors: number;
      type: 'office' | 'factory';
      buttonDistance: 25 | 30 | 35;
    }[]>(upperBuildingSettings || []);
    const [openIndex, setOpenIndex] = useState<number>(Math.max(0, buildingSettings.length - 1));
    const featureAtIndex = useMemo(() => {

      return selectedBuildings[openIndex];

    }, [openIndex]);

    // boolean value to determine if the view should have more options than just floor. based on the size of the building
    const simpleView = useMemo(() => {

      if (!selectedBuildings || selectedBuildings.length === 0) {

        return false;

      }
      for (const building of selectedBuildings) {

        if (turf.area(building.geometry) > 2000) {

          return false;

        }

      }
      return true;

    }, []);

    useEffect(() => {

      const foundBuilding = buildingSettings.find((building) => {

        return building.buildingId === featureAtIndex.id;

      });
      if (foundBuilding) {

        setInnerFloors(foundBuilding.floors);
        setOfficeOrFactory(foundBuilding.type);
        setButtonDistance(foundBuilding.buttonDistance);

      }

    }, [featureAtIndex]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {

      if (!featureAtIndex) {

        return;

      }
      const size = turf.area(featureAtIndex.geometry);
      if (size > 10000) {

        setOfficeOrFactory('factory');
        setButtonDistance(35);

      } else {

        setOfficeOrFactory('office');
        setButtonDistance(30);

      }

    }, [featureAtIndex]);

    const goToNextStep = async () => {

      // check if it needs adding again. When you go back, it should not just add again
      const newBuildingSettings = [...buildingSettings.filter((building) => {

        return building.buildingId !== featureAtIndex.id;

      }), {
        buildingId: featureAtIndex.id,
        floors: innerFloors,
        type: officeOrFactory,
        buttonDistance,
      }];
      setBuildingSettings(newBuildingSettings);
      if (openIndex < selectedBuildings.length - 1) {

        setOpenIndex(openIndex + 1);
        return;

      }
      const deviceArray = [];
      if (newBuildingSettings.length === selectedBuildings.length) {

        setUpperBuildingSettings(newBuildingSettings);

      }
      setOpenStep(3);
      await wait(1);

      for (const buildingSetting of newBuildingSettings) {

        const geoLayer = selectedBuildings.find((building) => {

          return building.id === buildingSetting.buildingId;

        });
        if (!geoLayer) {

          continue;

        }
        const innerFeature = {
          type: 'Feature',
          geometry: geoLayer.geometry,
          properties: geoLayer.properties,
          id: buildingSetting.buildingId,
        };

        const bounds = turf.bbox(innerFeature.geometry);
        if (!bounds) {

          return;

        }
        const mapBounds = latLngBounds([bounds[1], bounds[0]], [bounds[3], bounds[2]]).pad(0.5);
        const buttonGrid = pointsInPolygon(innerFeature.geometry, mapBounds, Math.ceil(buildingSetting.buttonDistance * 1.4));

        const gatewayGrid = turf.featureCollection(
          pointsInPolygon(innerFeature.geometry, mapBounds, buildingSetting.type === 'factory' ? 150 : 85).features.map((feature: Feature<any>) => {

            return {
              type: 'Feature',
              geometry: feature.geometry,
              properties: {
                type: 'gateway',
              },
            };

          }) as Feature<any>[],
        );
        const allGateways: Feature<any, Properties>[] = [];
        deviceArray.forEach((building) => {

          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          allGateways.push(building.gateways.features);

        });
        const flattenedGateways = turf.featureCollection(allGateways.flat());

        // clean up gateways, only add gateways with a distance of 50 meters or more
        const cleanedGateways = [];
        for (const gateway of gatewayGrid.features) {

          if (flattenedGateways.features.length === 0) {

            cleanedGateways.push(gateway);
            continue;

          }
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          const nearestGateway = turf.nearestPoint(gateway, flattenedGateways);
          const nearestGatewayDistance = turf.distance(gateway, nearestGateway, { units: 'meters' });

          if (nearestGateway && nearestGatewayDistance > 50) {

            cleanedGateways.push(gateway);

          }

        }
        deviceArray.push({
          buttons: buttonGrid,
          gateways: turf.featureCollection(cleanedGateways),
          buildingId: buildingSetting.buildingId,
          floors: buildingSetting.floors,
        });

      }
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      setProposedDevices(deviceArray);

    };

    return (
      <StepDialogCard
        open={ openStep === 2}
        title={translate('resources.buttons.text.building_dialog.2.title', { building: openIndex + 1, max: selectedBuildings.length })}
        onClose={closeDialog}
        onBack={() => {

          if (openIndex > 0) {

            setOpenIndex(openIndex - 1);
            return;

          }
          setOpenStep(1);

        }}
        onNext={goToNextStep}
        hintText={translate('resources.buttons.text.building_dialog.change_later')}
        nextLabel={openIndex < selectedBuildings.length - 1
          ? 'resources.buttons.text.building_dialog.2.next_builing_button'
          : 'resources.buttons.text.building_dialog.2.next_button'
        }
        content={
          <>
            <CardContent className="card-with-map-content">
              <div>
                <SquareSingleMap
                  feature={turf.featureCollection([featureAtIndex])}
                  index={openIndex + 1}
                />
              </div>
              <div style={{
                width: '100%',
                padding: '0 20px',
              }}>
                {!simpleView && (
                  <>
                    <span style={{ fontSize: '20px' }}>
                      {translate('resources.buttons.text.building_dialog.2.office_or_factory')}
                    </span>
                    <div>
                      <Grid container spacing={1}>
                        <Grid xs={3}>
                          <ButtonWithIconAndLabel
                            icon={buildingIcon}
                            label={translate('resources.buttons.text.building_dialog.2.office')}
                            selected={officeOrFactory === 'office'}
                            onClick={() => {

                              setOfficeOrFactory('office');

                            }}/>
                        </Grid>
                        <Grid xs={3}>
                          <ButtonWithIconAndLabel
                            icon={factoryIcon}
                            label={translate('resources.buttons.text.building_dialog.2.factory')}
                            selected={officeOrFactory === 'factory'}
                            onClick={() => {

                              setOfficeOrFactory('factory');

                            }}/>
                        </Grid>
                      </Grid>
                    </div>
                    <div style={{
                      marginTop: '30px',
                    }}>
                      <span style={{ fontSize: '20px' }}>
                        {translate('resources.buttons.text.building_dialog.2.button_distance_title')}
                      </span>
                      <span style={{ fontSize: '12px', color: 'gray', display: 'block' }}>
                        {translate('resources.buttons.text.building_dialog.2.button_distance_sub_title')}
                      </span>
                      <div style={{
                        width: '80%',
                      }}>
                        <Grid container spacing={1} alignItems="end">
                          <Grid xs={4}>
                            <LabelButton
                              label={translate('resources.buttons.text.building_dialog.2.button_distance_button', { distance: 25 })}
                              selected={buttonDistance === 25}
                              onClick={() => {

                                setButtonDistance(25);

                              }}/>
                          </Grid>
                          <Grid xs={4}>
                            <LabelButton
                              label={translate('resources.buttons.text.building_dialog.2.button_distance_button', { distance: 30 })}
                              selected={buttonDistance === 30}
                              recommended={officeOrFactory === 'office'}
                              onClick={() => {

                                setButtonDistance(30);

                              }}/>
                          </Grid>
                          <Grid xs={4}>
                            <LabelButton
                              label={translate('resources.buttons.text.building_dialog.2.button_distance_button', { distance: 35 })}
                              selected={buttonDistance === 35}
                              recommended={officeOrFactory === 'factory'}
                              onClick={() => {

                                setButtonDistance(35);

                              }}/>
                          </Grid>
                        </Grid>
                      </div>
                    </div>
                  </>
                )}
                <div style={{
                  marginTop: '30px',
                }}>
                  <span
                    style={{ fontSize: '20px' }}>{translate('resources.buttons.text.building_dialog.2.how_many_floors')}</span>
                  <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginTop: '10px',
                    marginBottom: '10px',
                  }}>
                    <IntegerInput value={innerFloors} setValue={setInnerFloors} min={1}/>
                    <span
                      style={{
                        marginLeft: '10px',
                        fontSize: '15px',
                      }}>
                      {translate('resources.buttons.text.building_dialog.2.floors')}
                    </span>
                  </div>
                </div>
              </div>
            </CardContent>
          </>
        }
      />
    );

  };

  const PropositionOrderStep = () => {

    const [loading, setLoading] = useState<boolean>(false);

    const ProposedItem = (innerProps: { label: string; number: number; icon: any }) => {

      return (
        <div className={'building-add-3-proposed-item'}>
          <img src={innerProps.icon} alt="Item"/>
          <span>{`${innerProps.number}x ${translate(innerProps.label)}`}</span>
        </div>
      );

    };
    return (
      <StepDialogCard
        open={openStep === 3}
        title={'resources.buttons.text.building_dialog.3.title'}
        onClose={closeDialog}
        onBack={() => {

          setOpenStep(2);
          setProposedDevices([]);

        }}
        onNext={
          proposedDevices.length > 0 ? async () => {

            setLoading(true);
            await wait(1);
            uploadDevicesAndCloseDialog();

          } : undefined
        }
        hintText={translate('resources.buttons.text.building_dialog.change_later')}
        nextLabel="resources.buttons.text.building_dialog.3.next_button"
        content={
          <>
            {(proposedDevices && !loading) ? (
              <CardContent className="card-with-map-content">
                <div>
                  <SquareSingleMap
                    addPoints={true}
                    feature={turf.featureCollection(selectedBuildings)}
                  />
                </div>
                <div style={{
                  width: '100%',
                  padding: '20px',
                }}>
                  <span>
                    {translate('resources.buttons.text.building_dialog.building')}:
                    <span
                      style={{ color: 'blue' }}> {newPosition?.properties.address?.streetName} {newPosition?.properties.address?.streetNumber}
                    </span>
                  </span>
                  <div className="building-add-3-proposed-items-div">
                    <ProposedItem label={'general.text.emergency_button'} number={deviceTotals.totalButtons}
                      icon={bhvIcon}/>
                    <ProposedItem label={'general.text.gateway'} number={deviceTotals.totalGateways}
                      icon={gatewayIcon}/>
                  </div>
                </div>
              </CardContent>
            ) : (
              <div className="loading-card-content">
                <CircularProgress/>
              </div>
            )}
          </>
        }
      />
    );

  };

  return (
    <>
      <StepDialogCard
        open={openStep === 0}
        title={'resources.buttons.text.building_dialog.0.title'}
        content={
          <>
            <div className="building-add-explanation building-add-0-explanation">
              <span>{translate('resources.buttons.text.building_dialog.0.explanation')}</span>
            </div>
            <div className="building-add-0-input">
              <InputLabel>
                <span style={{
                  fontWeight: 'bold', display: 'block', fontSize: '15px', color: 'black',
                }}>
                  {translate('resources.buttons.text.building_dialog.0.input_upper_label')}
                </span>
              </InputLabel>
              <GoogleCoordinatesInput
                value={newPosition}
                setValue={setNewPosition}
                label={translate('resources.buttons.text.building_dialog.0.input_label')}
                variant="outlined"
                sx={{
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '5px',
                    width: '100%',
                    height: '40px',
                    backgroundColor: '#F2F2F2 !important',
                    border: 'none',
                  },
                  '& .MuiOutlinedInput-notchedOutline': {
                    border: 'none',
                  },
                  width: '60%',
                }}
                inputProps={{
                  endAdornment: <InputAdornment position={'end'}><SearchIcon/></InputAdornment>,
                }}
                country={'nl'}
                setHasError={setHasError}
              />
              {hasError && (
                <Alert severity="error" sx={{ marginTop: '10px' }}>
                  {translate('resources.buttons.text.invalid_address')}
                </Alert>
              )}
              {positionWarning !== ''
                && <Alert severity="warning" sx={{ marginTop: '10px' }}>
                  {translate(positionWarning)}
                </Alert>
              }
            </div>
          </>
        }
        onClose={closeDialog}
      />
      <AddBuildingMapStep/>
      <FloorSelectionMapStep/>
      <PropositionOrderStep/>
    </>
  );

};
