import {
  Dispatch, SetStateAction, useEffect, useState,
} from 'react';
import { Identifier } from 'react-admin';
import { MapContainer, ScaleControl } from 'react-leaflet';
import * as React from 'react';
import { Zone } from '@x-guard/xgac-types/xgac';
import uniqolor from 'uniqolor';
import L, { LatLng, Layer } from 'leaflet';
import { Feature } from '@turf/turf';
import * as turf from '@turf/turf';
import { CenterControl, CustomLayerControl, FloorFilter } from '../../apps/bhvk/components/LeafletControls';
import { KioskUser } from '../../lib/constants/customTypes';
import { compareZoneArrays, uniqColorOptionsForZones } from './zoneMap';
import defaultKioskIcon from '../../apps/bhvk/svg/screen.svg';
import selectedKioskIcon from '../../apps/bhvk/svg/screen_selected.svg';

const getKioskIcon = (icon: string) => {

  return L.divIcon({
    className: 'button-icon',
    iconSize: [25, 26],
    popupAnchor: [-5, -26],
    iconAnchor: [10, 46],
    html: `<img src="${icon}" style={{ width: '20px' }}/>`,
  });

};

export const KioskMap = (props: {
  kiosks?: Array<KioskUser>;
  map: any;
  setMap: Dispatch<SetStateAction<any>>;
  expandedId: Identifier;
  zonesInKiosks: Array<Zone>;
  possibleFloors: number[];
  selectedFloors: number[];
  setSelectedFloors: Dispatch<SetStateAction<number[]>>;
  handleClickOnMarker: (id: Identifier) => void;
}) => {

  const {
    kiosks,
    map,
    setMap,
    expandedId,
    zonesInKiosks,
    possibleFloors,
    selectedFloors,
    setSelectedFloors,
    handleClickOnMarker,
  } = props;
  const [zoomed, setZoomed] = useState(false);
  const [innerZones, setInnerZones] = useState<Array<Zone>>([]);

  const refreshOnClicks = () => {

    map?.eachLayer((layer: any) => {

      if (layer instanceof L.Marker) {

        if (layer.toGeoJSON().id) {

          layer.off('dblclick');
          layer.on('dblclick', () => {

            handleClickOnMarker(layer.toGeoJSON().id || '');

          });

        }

      }

    });

  };

  useEffect(() => {

    const [added, removed] = compareZoneArrays(zonesInKiosks as any, innerZones as any);
    setInnerZones(zonesInKiosks);

    const currentLayers = map?.pm.getGeomanLayers();
    added.forEach((addedZone) => {

      const color = uniqolor(
        addedZone._id,
        uniqColorOptionsForZones,
      );

      const zoneStyle = {
        color: color.color,
        fillColor: color.color,
        fillOpacity: 0.3,
        weight: 2,
      };

      const zoneToAdd = {
        type: 'Feature',
        id: addedZone._id,
        properties: {
          name: addedZone.name,
        },
        geometry: addedZone.location,
        key: addedZone._id,
        updatedAt: addedZone.updatedAt,
      };

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore geoman typings are not correct
      const layer = L.geoJson(zoneToAdd, {
        style: zoneStyle,
      });
      if (map) {

        layer?.addTo(map);

      }

    });

    removed.forEach((removedZone) => {

      currentLayers?.forEach((layer: any) => {

        if (layer.feature?.id === removedZone._id) {

          map?.removeLayer(layer);

        }

      });

    });

  }, [innerZones, kiosks, map, zonesInKiosks]);

  useEffect(() => {

    if (!map || zonesInKiosks.length === 0) return;
    if (!zoomed) {

      const zoneBounds = zonesInKiosks.map((zone) => L.geoJSON(zone.location).getBounds());
      const bounds = zoneBounds.reduce((acc, val) => acc.extend(val), new L.LatLngBounds(zoneBounds[0].getSouthWest(), zoneBounds[0].getNorthEast()));
      map.fitBounds(bounds);
      setZoomed(true);

    }

  }, [map, zonesInKiosks, zoomed]);

  useEffect(() => {

    if (kiosks && map) {

      const geomanLayers = map.pm.getGeomanLayers();
      const kioskLayers = geomanLayers.filter((layer: any) => layer.feature?.properties?.type === 'kiosk');
      const kioskLayerIds = kioskLayers.map((layer: any) => layer.feature.id);
      const kioskIds = kiosks.map((kiosk) => kiosk._id);

      const addedKiosks = kiosks.filter((kiosk) => !kioskLayerIds.includes(kiosk._id));
      const removedKiosks = kioskLayers.filter((layer: any) => !kioskIds.includes(layer.feature.id));

      addedKiosks.forEach((kiosk) => {

        if (!kiosk.asset.position) return;
        const kioskToAdd = {
          type: 'Feature',
          id: kiosk._id,
          properties: {
            name: kiosk.asset.name,
            type: 'kiosk',
          },
          geometry: {
            ...kiosk.asset.position,
            type: 'Point',
          },
          key: kiosk._id,
          updatedAt: kiosk.asset.updatedAt,
        };

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore geoman typings are not correct
        const kioskMarker = L.geoJson(kioskToAdd, {
          pointToLayer(geoJsonPoint: Feature<any>, latlng: LatLng): Layer {

            return L.marker(latlng, {
              icon: getKioskIcon(expandedId === kiosk._id ? selectedKioskIcon : defaultKioskIcon),
            });

          },
        });

        kioskMarker.addTo(map);

      });

      removedKiosks.forEach((layer: any) => {

        map.removeLayer(layer);

      });

      map?.eachLayer((layer: any) => {

        if (layer instanceof L.Marker) {

          const kiosk = kiosks.find((k) => k._id === layer.toGeoJSON().id);
          if (kiosk && kiosk.asset.position) {

            layer.setLatLng([kiosk.asset.position.coordinates[1], kiosk.asset.position.coordinates[0]]);

          }

        }

      });

      if (addedKiosks.length > 0 || removedKiosks.length > 0) {

        refreshOnClicks();

      }

    }

  }, [kiosks, map]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {

    map?.eachLayer((layer: any) => {

      if (layer instanceof L.Marker) {

        if (layer.toGeoJSON().id === expandedId) {

          layer.setIcon(getKioskIcon(selectedKioskIcon));

          layer.setZIndexOffset(1);

          const mapBounds = map.getBounds();
          if (!mapBounds.contains(layer.getLatLng())) {

            map.setView(layer.getLatLng());

          }
          layer.dragging?.enable();
          layer.off('drag');
          layer.on('drag', (event) => {

            // check if drag is within zone
            const point = event.target.getLatLng();
            if (!kiosks) {

              return;

            }
            const kiosk = kiosks.find((k) => k._id === expandedId);
            if (!kiosk) return;
            map?.eachLayer((zoneLayer: any) => {

              if (zoneLayer instanceof L.Polygon) {

                if (zoneLayer.toGeoJSON().id === kiosk.kioskConfig.zone?._id) {

                  const polygon = zoneLayer.toGeoJSON();
                  const pointInZone = turf.booleanPointInPolygon([point.lng, point.lat], polygon);
                  if (!pointInZone) {

                    const centerOfZone = turf.centerOfMass(polygon);
                    const line = turf.lineString([[point.lng, point.lat], centerOfZone.geometry.coordinates]);
                    const intersection = turf.lineIntersect(polygon, line);
                    if (intersection.features.length > 0) {

                      event.target.setLatLng(new LatLng(intersection.features[0].geometry.coordinates[1], intersection.features[0].geometry.coordinates[0]));

                    }

                  }

                }

              }

            });

          });

        } else {

          layer.setIcon(getKioskIcon(defaultKioskIcon));

          const kiosk = kiosks?.find((k) => k._id === layer.toGeoJSON().id);
          if (kiosk && kiosk.asset.position) {

            layer.setLatLng([kiosk.asset.position.coordinates[1], kiosk.asset.position.coordinates[0]]);

          }

          layer.dragging?.disable();
          layer.setZIndexOffset(0);

        }

      }

    });
    refreshOnClicks();

  }, [expandedId, kiosks]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <MapContainer
      ref={(ref: any) => setMap(ref)}
      center={[52.1009166, 5.6462914]}
      zoom={17}
      minZoom={4}
      zoomControl={false}
      style={{ height: '100%' }}
    >
      <ScaleControl position="bottomright" imperial={false}/>
      <div>
        <div className="leaflet-control-container-custom">
          <CustomLayerControl
            map={map}
            toggleVisible={true}
          />
          <CenterControl onClick={() => {

            setZoomed(false);

          }}/>
        </div>
        <FloorFilter floors={possibleFloors} selectedFloors={selectedFloors} onChange={setSelectedFloors} />
      </div>
    </MapContainer>
  );

};
