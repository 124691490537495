import {
  maxValue,
  minValue,
  NumberInput,
  required,
  SelectInput,
  TextInput,
} from 'react-admin';
import React from 'react';
import { locales } from '../../lib/constants/localesOptions';

export const PositionForm = ({ source, disabled }: any) => {

  return (
    <div style={{ width: '100%' }}>
      <SelectInput source={`${source}.properties.address.countryCode`}
        choices={locales} defaultValue='nl'
        format={(v?: string) => v?.toLowerCase()}
        label="general.fields.position.address.countrycode"
        helperText={false}
        disabled={disabled}
        validate={required()}
        shouldUnregister
      />
      <NumberInput source={`${source}.coordinates.1`}
        label="general.fields.position.latitude"
        helperText={false}
        sx={{ display: 'block', width: '100%' }}
        disabled={disabled}
        shouldUnregister
        validate={ disabled ? [minValue(-90), maxValue(90)] : [required(), minValue(-90), maxValue(90)]}/>
      <NumberInput source={`${source}.coordinates.0`}
        label="general.fields.position.longitude"
        helperText={false}
        sx={{ display: 'block', width: '100%' }}
        disabled={disabled}
        validate={disabled ? [minValue(-90), maxValue(90)] : [required(), minValue(-90), maxValue(90)]}
        shouldUnregister/>
      <TextInput source={`${source}.properties.address.state`}
        label="general.fields.position.address.state"
        helperText={false}
        disabled={disabled}
        sx={{ display: 'block', width: '100%' }}
        shouldUnregister/>
      <TextInput source={`${source}.properties.address.city`}
        label="general.fields.position.address.city"
        helperText={false}
        disabled={disabled}
        sx={{ display: 'block', width: '100%' }}
        shouldUnregister/>
      <TextInput source={`${source}.properties.address.streetName`}
        label="general.fields.position.address.streetName"
        helperText={false}
        disabled={disabled}
        sx={{ display: 'block', width: '100%' }}/>
      <TextInput source={`${source}.properties.address.streetNumber`}
        label="general.fields.position.address.streetNumber"
        helperText={false}
        disabled={disabled}
        sx={{ display: 'block', width: '100%' }}
        shouldUnregister/>
      <NumberInput source={`${source}.properties.address.floor`}
        label="general.fields.position.address.floor"
        helperText={false}
        disabled={disabled}
        sx={{ display: 'block', width: '100%' }}
        shouldUnregister/>
      <TextInput name='formattedAddress' source={`${source}.properties.address.formattedAddress`}
        label="general.fields.position.address.formattedAddress" disabled
        shouldUnregister
      />
    </div>
  );

};
