import {
  InputProps, useInput,
} from 'react-admin';
import { usePlacesWidget } from 'react-google-autocomplete';
import { TextField } from '@mui/material';
import { PointGeometry } from '@x-guard/xgac-types/xgac';
import * as turf from '@turf/turf';
import { XGAC_MAPS_KEY } from '../../../../config';
import { googleAddressComponentsDecoder } from '../../../../lib/GoogleAddressComponentsDecoder';

const withinDistance = (coord1: [number, number], coord2: [number, number], distance: number) => {

  return turf.distance(turf.point(coord1), turf.point(coord2), { units: 'kilometers' }) <= distance / 1000;

};

export const GoogleRaCoordinatesInput = (props: InputProps & { sx: any; handleSave: any; country?: string }) => {

  const { field } = useInput({ source: props.source });
  const { ref } = usePlacesWidget({
    apiKey: XGAC_MAPS_KEY,
    options: {
      types: [],
      componentRestrictions: props.country ? { country: props.country } : undefined,
      fields: ['formatted_address', 'geometry', 'name', 'address_components'],
    },
    onPlaceSelected: (place) => {

      const newCoordinates: [number, number] = [place.geometry.location.lng(), place.geometry.location.lat()];
      const parsedGoogleInput = googleAddressComponentsDecoder(place.address_components);

      const newPosition = {
        type: 'Point',
        // eslint-disable-next-line no-nested-ternary
        coordinates: field.value?.coordinates
          ? withinDistance(newCoordinates, field.value?.coordinates, 200) ? field.value?.coordinates : newCoordinates
          : newCoordinates,
        properties: {
          ...(field.value?.properties ?? {}),
          address: {
            ...(field.value?.properties?.address ?? {}),
            formattedAddress: place.formatted_address,
            streetName: parsedGoogleInput.route?.long_name,
            streetNumber: parsedGoogleInput.street_number?.long_name || null,
            city: parsedGoogleInput.locality?.long_name,
            state: parsedGoogleInput.administrative_area_level_1?.long_name,
            countryCode: parsedGoogleInput.country?.short_name || 'NL',
          },
        },
      } as PointGeometry;
      field.onChange(newPosition);
      props.handleSave({ position: newPosition });

    },

  });

  return (
    <TextField
      label="Location"
      inputRef={ref}
      fullWidth
      multiline
      size="small"
      color="info"
      sx={props.sx}
      defaultValue={field.value?.properties?.address?.formattedAddress}
    />
  );

};
