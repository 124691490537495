import {
  TextField,
  ArrayInput,
  SimpleForm,
  SimpleFormIterator,
  TextInput,
  EditButton,
  BooleanInput,
  AutocompleteInput,
  required,
  AddItemButton,
  useTranslate,
} from 'react-admin';
import {
  List, Datagrid, IfCanAccess,
} from '@react-admin/ra-rbac';
import * as React from 'react';
import { Typography } from '@mui/material';
import { JsonInput } from 'react-admin-json-view';
import { CustomToolbar } from '../../../components/CustomToolBar';
import SearchFields, { FilterResetter } from '../components/fields/Filters';
import ActiveBooleanField from '../../../components/fields/ActiveBooleanField';
import SimpleChipListField from '../../../components/fields/SimpleChipListField';
import CustomBulkActionButtons from '../../../components/buttons/CustomBulkActionButtons';
import { exporter } from '../../../utils/customExporter';
import { ArrayInputWithAutoComplete } from '../../../components/fields/ArrayInputWithAutoComplete';
import { CreateWithSuccess } from '../../../components/baseForms/CreateWithSuccess';
import { EditWithSuccess } from '../../../components/baseForms/EditWithSuccess';
import { LIST_DEBOUNCE } from '../../../config';
import { StabilityAlert, StabilityLevel } from '../../../components/StabilityAlert';
import { DateFieldWithTime } from '../../../components/fields/DateFieldWithTime';

const eventCodes: Array<{ name: string; id: string }> = [
  { name: 'ALARM_GREEN', id: 'ALARM_GREEN' },
  { name: 'ALARM_ORANGE', id: 'ALARM_ORANGE' },
  { name: 'ALARM_RED', id: 'ALARM_RED' },
  { name: 'MANDOWN', id: 'MANDOWN' },
];

export const ObservationTriggerList = () => (
  <>
    <StabilityAlert stability={StabilityLevel.Stable}/>
    <List filters={SearchFields} debounce={LIST_DEBOUNCE} exporter={exporter} title="resources.observation-triggers.text.title">
      <>
        <FilterResetter/>
        <Datagrid rowClick="toggleSelection" bulkActionButtons={<CustomBulkActionButtons/>}>
          <TextField source="name" label="general.fields.name"/>
          <SimpleChipListField source="eventCodes"/>
          <TextField source="description"/>
          <ActiveBooleanField source="status" />
          <DateFieldWithTime source="createdAt" label="general.fields.createdAt" timeOnHover={true}/>
          <DateFieldWithTime source="updatedAt" label="general.fields.updatedAt" timeOnHover={true}/>
          <IfCanAccess action="edit">
            <EditButton/>
          </IfCanAccess>
        </Datagrid>
      </>
    </List>
  </>
);

export const ObservationTriggerEdit = () => {

  const translate = useTranslate();
  return (
    <>
      <StabilityAlert stability={StabilityLevel.Unstable}/>
      <EditWithSuccess title="resources.observation-triggers.text.title">
        <SimpleForm toolbar={<CustomToolbar/>}>
          <Typography variant="h6" gutterBottom>
            {translate('resources.observation-triggers.text.general')}
          </Typography>
          <TextInput source="name" validate={required()} label="general.fields.name"/>
          <ArrayInputWithAutoComplete choices={eventCodes} source="eventCodes" validate={required()}/>
          <TextInput source="description"/>
          <BooleanInput source="status" format={(v) => (v === 'active')} parse={(v) => (v ? 'active' : 'inactive')}
            label="resources.observation-triggers.fields.active_status"/>
          <Typography variant="h6" gutterBottom>
            {translate('resources.observation-triggers.text.action_config')}
          </Typography>
          <ArrayInput source="actions"><SimpleFormIterator
            addButton={<div><AddItemButton/><Typography variant="body1" sx={{ display: 'inline', fontSize: '15px' }}>
              {translate('resources.observation-triggers.text.add_action')}
            </Typography></div>}>
            <TextInput source="type"/>
            <JsonInput source="config" reactJsonOptions={{
              // Props passed to react-json-view
              name: 'config',
              collapsed: false,
              enableClipboard: true,
              displayDataTypes: false,
            }}/>
          </SimpleFormIterator></ArrayInput>
          <Typography variant="h6" gutterBottom>
            {translate('resources.observation-triggers.text.filter')}
          </Typography>
          <JsonInput source="filter" reactJsonOptions={{
            name: translate('resources.observation-triggers.text.filter'),
          }}/>
        </SimpleForm>
      </EditWithSuccess>
    </>
  );

};

export const ObservationTriggerCreate = () => {

  const translate = useTranslate();
  return (
    <>
      <StabilityAlert stability={StabilityLevel.Unstable}/>
      <CreateWithSuccess title="resources.observation-triggers.text.title">
        <SimpleForm>
          <Typography variant="h6" gutterBottom>
            {translate('resources.observation-triggers.text.general')}
          </Typography>
          <TextInput source="name" validate={required()} label="general.fields.name"/>
          <ArrayInput source="eventCodes" validate={required()} >
            <SimpleFormIterator disableReordering
              addButton={<div><AddItemButton/><Typography variant="body1" sx={{ display: 'inline', fontSize: '15px' }}>
                {translate('resources.observation-triggers.text.add_eventcode')}
              </Typography></div>}>
              <AutocompleteInput
                onCreate={(value) => {

                  if (value) {

                    const newEventCode = { id: value, name: value };
                    eventCodes.push(newEventCode);
                    return newEventCode;

                  }
                  return null;

                }}
                choices={eventCodes}
              />
            </SimpleFormIterator>
          </ArrayInput>
          <TextInput source="description"/>
          <BooleanInput source="status" format={(v) => (v === 'active')} parse={(v) => (v ? 'active' : 'inactive')}
            label="resources.observation-triggers.fields.active_status"
          />
          <Typography variant="h6" gutterBottom>
            {translate('resources.observation-triggers.text.action_config')}
          </Typography>
          <ArrayInput source="actions"><SimpleFormIterator
            addButton={<div><AddItemButton/><Typography variant="body1" sx={{ display: 'inline', fontSize: '15px' }}>
              {translate('resources.observation-triggers.text.add_action')}
            </Typography></div>}>
            <TextInput source="type"/>
            <JsonInput source="config" reactJsonOptions={{
              // Props passed to react-json-view
              name: 'config',
              collapsed: false,
              enableClipboard: true,
              displayDataTypes: false,
            }}/>
          </SimpleFormIterator></ArrayInput>
          <Typography variant="h6" gutterBottom>
            {translate('resources.observation-triggers.text.filter')}
          </Typography>
          <JsonInput source="filter" reactJsonOptions={{
            name: translate('resources.observation-triggers.text.filter'),
          }}/>
        </SimpleForm>
      </CreateWithSuccess>
    </>
  );

};
