import * as React from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
// react admin
import {
  CustomRoutes, defaultTheme, resolveBrowserLocale, Admin,
} from 'react-admin';
import { useEffect } from 'react';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import * as raNl from 'ra-language-dutch';
import _ from 'lodash';
import {
  ThemeOptions, createTheme,
} from '@mui/material';
import * as raEn from 'ra-language-english';
import { Helmet } from 'react-helmet';
import { queryClient } from '../../utils/queryClient';
import { dataProvider } from '../../dataProviders';
import authProvider, { refreshContext } from '../../utils/authProvider';

import { Resource } from '../../components/Resource';

// Components
import { UserSnap } from '../../lib/usersnap';
import { HomePage } from './pages/homePage';
import { AlarmList } from './entities/alarm';

// languages
import nl from '../../config/i18n/locales/nl';
import { DutchFlag, EnglishFlag } from '../../components/locales/Flags';
import { BHVKLayout } from './Layout';
import { AssetCreate, AssetEdit, AssetList } from './entities/asset';
import { ButtonList } from './entities/button';
import { BhvKnopHelpFrame } from './components/bhvKnopHelpFrame';
import { InvoicesPage } from './pages/invoices';
import { CreateAlarmPage } from './pages/createAlarmPage';
import { BhvKnopOrdersFrame } from './components/bhvKnopOrdersFrame';
import { BhvKnopOrderFrame } from './components/bhvKnopOrderFrame';
import { ReportTriggerList } from './entities/report';
import en from '../../config/i18n/locales/en';
import { BUILD_REF_NAME } from '../../config';
import LoginPage from '../../components/login';
import { OverlayMapList } from '../../components/overlay';
import { GroupCreate, GroupEdit, GroupList } from './entities/groups';

const theme: ThemeOptions & {
  sidebar: {
    width: number;
    closedWidth: number;
  };
} = {
  sidebar: {
    width: 200,
    closedWidth: 55,
  },
  ...createTheme({
    ...defaultTheme,
    components: {
      MuiScopedCssBaseline: {
        styleOverrides: {
          root: {
            fontFamily: '"Fira Sans Condensed", sans-serif !important',
            fontWeight: 400,
            fontSize: '1rem',
            lineHeight: 1.5,
            color: '#011008 !important',
          },
        },
      },
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      RaMenuItem: {
        styleOverrides: {
          root: {
            '&:hover': {
              backgroundColor: 'transparent !important',
            },
          },
        },
      },
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      MuiSvgIcon: {
        styleOverrides: {
          root: {
            width: '20px',
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            boxShadow: 'none !important',
          },
        },
      },
      MuiDivider: {
        styleOverrides: {
          root: {
            borderBottomWidth: 0,
          },
        },
      },
      MuiChip: {
        styleOverrides: {
          root: {
            borderRadius: '3px',
            fontWeight: '700',
            height: '20px',
            textTransform: 'uppercase',
            fontFamily: '"Fira Sans Condensed", sans-serif',
          },
          label: {
            padding: '0px 6px',
          },
        },
      },
      MuiPaper: {
        defaultProps: {
          elevation: 0,
        },
      },
    },
    shape: {
      borderRadius: 5,
    },
    /*

    $greenBlack: #011008;
    $greenDark: #034220;
    $greenPrimary: #0F8F49;
    $greenLight: #9FD2B6;
    $greenWhite: #E2F2E9;

    $orangeBlack: #3C1709;
    $orangeDark: #792D12;
    $orangePrimary: #F15A24;
    $orangeLight: #FCDED3;
    $orangeWhite: #FEF8F6;

    */
    palette: {
      background: {
        default: '#FFF',
      },
      primary: {
        light: '#9FD2B6',
        main: '#0F8F49',
        dark: '#034220',
        contrastText: '#FFF',
      },
      secondary: {
        light: '#FCDED3',
        main: '#F15A24',
        dark: '#792D12',
        contrastText: '#FFF',
      },
      error: {
        main: '#D0382A',
      },
      info: {
        main: '#FFFFFF',
        contrastText: '#0F8F49',
      },
      warning: {
        main: '#EC812A',
      },
      success: {
        main: '#008E8E',
        light: '#008E8E',
        dark: '#008E8E',
        contrastText: '#FFFFFF',
      },
    },
  }),
};

const translations = { en: _.merge(raEn.default, en), nl: _.merge(raNl.default, nl) };

export const i18nProvider = polyglotI18nProvider(
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  (locale) => (translations[locale] ? translations[locale] : translations.nl),
  resolveBrowserLocale(),
  [
    { locale: 'en', name: <EnglishFlag/> },
    { locale: 'nl', name: <DutchFlag/> },
  ],
  { allowMissing: true },
);

const BHVKApp = () => {

  // Remove the permissions, so they are rebuild.
  useEffect(() => {

    const context = async () => {

      localStorage.removeItem('permissions');
      await refreshContext();
      await authProvider.getPermissions({});

    };
    context();

  }, []);

  return (
    <BrowserRouter>
      <script>{`
        const tld = window.location.origin.split('.').pop() === 'nl' ? 'nl' : 'dev';
        document.domain = 'bhv-knop.' + tld;
      `}</script>
      <title>BHV-Knop.nl Dashboard</title>
      <link rel="icon" href="https://bhv-knop.nl/wp-content/uploads/2023/08/cropped-favicon-192x192.png" sizes="192x192" />
      <link rel="apple-touch-icon" href="https://bhv-knop.nl/wp-content/uploads/2023/08/cropped-favicon-180x180.png" />
      <meta name="msapplication-TileImage" content="https://bhv-knop.nl/wp-content/uploads/2023/08/cropped-favicon-270x270.png" />
      <Helmet>
        {(window.location.href.match('localhost') === null && BUILD_REF_NAME === 'main') && (
          <script>
            {`(function(l,e,a,d,i,n,f,o){if(!l[i]){l.GlobalLeadinfoNamespace=l.GlobalLeadinfoNamespace||[];
          l.GlobalLeadinfoNamespace.push(i);l[i]=function(){(l[i].q=l[i].q||[]).push(arguments)};l[i].t=l[i].t||n;
          l[i].q=l[i].q||[];o=e.createElement(a);f=e.getElementsByTagName(a)[0];o.async=1;o.src=d;f.parentNode.insertBefore(o,f);}
        }(window,document,'script','https://cdn.leadinfo.net/ping.js','leadinfo','LI-653F938F44D04'));
        `}
          </script>
        )}
      </Helmet>
      <UserSnap/>
      <Admin
        dataProvider={dataProvider}
        authProvider={authProvider}
        loginPage={LoginPage}
        layout={BHVKLayout}
        lightTheme={theme}
        disableTelemetry={true}
        i18nProvider={i18nProvider}
        queryClient={queryClient}
      >
        {/* Mainapi */}
        <Resource name="alarms" list={AlarmList }/>
        <Resource name="assets" recordRepresentation="name" list={AssetList} edit={AssetEdit} create={AssetCreate}/>
        <Resource name="app-users" recordRepresentation="name"/>
        <Resource name="asset-groups" recordRepresentation="name" list={GroupList} create={GroupCreate} edit={GroupEdit}/>
        <Resource name="contact-lists" recordRepresentation="name"/>
        <Resource name="buttons" list={ButtonList}/>
        <Resource name="devices"/>
        <Resource name="zones" recordRepresentation="name"/>
        <Resource name="users/without-app" recordRepresentation="name"/>
        <Resource name="users" recordRepresentation="name"/>
        <Resource name="customers" recordRepresentation="name"/>
        <Resource name="report-triggers" list={ReportTriggerList}/>
        <Resource name="responsePartners" recordRepresentation="name"/>
        <Resource name="all-assets" recordRepresentation="name"/>
        <Resource name="overlays" list={OverlayMapList}/>
        {/* Ats */}
        <Resource name="customer-links"/>
        <Resource name="process-templates" recordRepresentation="processName"/>
        <Resource name="task-templates" recordRepresentation="templateName"/>

        {/* qr codes */}
        <Resource name="templates"/>
        <Resource name="lite-templates"/>
        <Resource name="codes"/>
        <Resource name="actions"/>
        <CustomRoutes>
          <Route path="/" element={<HomePage/>}/>
          <Route path="/facturen" element={<InvoicesPage/>}/>
          <Route path="/maak-alarm" element={<CreateAlarmPage/>}/>
          <Route path="/bestellingen/details" element={<BhvKnopOrdersFrame/>}/>
          <Route path="/bestellingen" element={<BhvKnopOrdersFrame/>}/>
          <Route path="/bestellen/" element={<BhvKnopOrderFrame/>}/>
          <Route path="/bestellen/:any" element={<BhvKnopOrderFrame/>}/>
          <Route path="/bestellen/:any/:any" element={<BhvKnopOrderFrame/>}/>
          <Route path="/help" element={<BhvKnopHelpFrame />}/>
          <Route path="/help/:category" element={<BhvKnopHelpFrame />}/>
          <Route path="/help/:category/:article" element={<BhvKnopHelpFrame />}/>
          <Route path="/login/hashed/:username/:key" element={<LoginPage/>}/>
        </CustomRoutes>
      </Admin>
    </BrowserRouter>
  );

};
export default BHVKApp;
