import { useState } from 'react';
import {
  TextInput, TextInputProps, useInput,
} from 'react-admin';
import { GrayTextInputWithUpperLabel } from '../../apps/bhvk/components/inputs/GrayTextInputWithUpperLabel';

export const PhoneNumberInput = (props: TextInputProps & { colorVariant?: 'gray' | 'normal' }) => {

  const { field } = useInput({ source: props.source });
  const [value, setValue] = useState<string>(field.value);
  const [changed, setChanged] = useState<boolean>(false);

  const handleBlur = (event: any) => {

    if (event.target.value.length === 0 && props.required === false) {

      setValue('');
      field.onChange('');
      return;

    }

    if (event.target.value.charAt(3) === '0') {

      const formatted = event.target.value.slice(0, 3) + event.target.value.slice(4);
      setValue(formatted);
      field.onChange(formatted);

    }

  };

  const validatePhoneNumber = (passedValue?: string) => {

    if (!passedValue || (passedValue.length === 0 && props.required === false)) return undefined;
    if (!/^\+[0-9]{8,}$/.test(passedValue)) {

      return 'resources.users.text.invalid_phone';

    }
    return undefined;

  };

  const onChange = (event: any) => {

    if (!changed) {

      if (event.target.value.length === 1 && event.target.value !== '+') {

        event.target.value = `+31${event.target.value}`;

      }
      setChanged(true);

    }

    event.target.value = event.target.value.replaceAll(' ', '');
    setValue(event.target.value);
    field.onChange(event.target.value);

  };

  if (props.colorVariant === 'gray') {

    return (
      <GrayTextInputWithUpperLabel
        value={value}
        onBlur={handleBlur}
        placeholder="+31"
        onChange={onChange}
        validate={validatePhoneNumber}
        label="resources.assets.fields.phoneNumber"
        {...props}
      />
    );

  }
  return (
    <TextInput
      value={value}
      onBlur={handleBlur}
      onChange={onChange}
      placeholder="+31"
      validate={validatePhoneNumber}
      label="resources.assets.fields.phoneNumber"
      {...props}
    />
  );

};
