import { useRecordContext } from 'react-admin';

const LengthField = ({ source }: any) => {

  const record = useRecordContext();
  if (!record[source]) return <div className="gray">0</div>;
  if (record[source].length === 0) return <div className="gray">{record[source].length}</div>;
  return <div className="MuiTypography-body2">{record[source].length}</div>;

};

export default LengthField;
