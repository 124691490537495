import {
  Dispatch, SetStateAction, useEffect, useState,
} from 'react';
import { MapContainer, ScaleControl } from 'react-leaflet';
import * as React from 'react';
import { Overlay } from '@x-guard/xgac-types/xgac';
import _ from 'lodash';
import L, { Map } from 'leaflet';
import 'leaflet-toolbar';
import '@x-guard/xgac-leaflet-distortable-image';
import 'leaflet-toolbar/dist/leaflet.toolbar.css';
import { Identifier } from 'react-admin';
import { useLocaleState, useTranslate } from 'ra-core';
import { CenterControl, CustomLayerControl, FloorFilter } from '../../apps/bhvk/components/LeafletControls';

const AnyL = L as any;

const overlayMarkerOptions = {
  draggable: true,
  preventMarkerRemoval: false,
  snappable: false,
  allowRemoval: true,
};
export const overlayToolbarActions = [
  AnyL.DistortAction,
  AnyL.DragAction,
  AnyL.FreeRotateAction,
  AnyL.ScaleAction,
];
const compareOverlayArrays = (newItems: Overlay[], oldItems: Overlay[]): [added: Overlay[], removed: Overlay[]] => {

  const diff = (left: Overlay[], right: Overlay[]) => left.filter((x) => {

    return right.findIndex((y) => x._id === y._id) === -1;

  });

  return [diff(newItems, oldItems), diff(oldItems, newItems)];

};
export const OverlayMap = (props: {
  overlays: Array<Overlay>;
  handleClickOnOverlay: (overlayId: string) => void;
  map: Map | null;
  setMap: Dispatch<SetStateAction<any>>;
  selectedOverlay: Identifier;
  isCreating: boolean;
  newMarkerPlaced: boolean;
  setNewMarkerPlaced: (newMarkerPlaced: boolean) => void;
  possibleFloors: number[];
  chosenFloors: number[];
  setChosenFloors: (floors: number[]) => void;
}) => {

  const {
    overlays, map, setMap, isCreating, newMarkerPlaced, setNewMarkerPlaced,
  } = props;
  const [innerOverlays, setInnerOverlays] = useState<Overlay[]>([]);

  const [zoomed, setZoomed] = useState<boolean>(false);
  const [locale] = useLocaleState();
  const translate = useTranslate();

  const customTranslation = {
    tooltips: {
      placeMarker: translate('resources.overlays.text.place_marker'),
    },
  };
  map?.pm?.setLang(locale as any, customTranslation);
  const addLayersToMap = (addedOverlays: Overlay[]) => {

    if (!map) {

      return;

    }
    for (const addedOverlay of addedOverlays) {

      if (!addedOverlay.imageUrl) {

        continue;

      }

      try {

        const imageLayer = AnyL.distortableImageOverlay(addedOverlay.imageUrl, {
          corners: [
            L.latLng(addedOverlay.points.nw.lat, addedOverlay.points.nw.lng),
            L.latLng(addedOverlay.points.ne.lat, addedOverlay.points.ne.lng),
            L.latLng(addedOverlay.points.sw.lat, addedOverlay.points.sw.lng),
            L.latLng(addedOverlay.points.se.lat, addedOverlay.points.se.lng),
          ],
          mode: 'freeRotate',
          editable: false,
          selected: addedOverlay._id === props.selectedOverlay,
          _id: addedOverlay._id,
          className: 'leaflet-overlay',
          actions: overlayToolbarActions,

        });
        if (imageLayer) {

          imageLayer.addTo(map);

        }
        L.DomEvent.on(imageLayer?.getElement(), 'dblclick', (e) => {

          e.stopPropagation();
          props.handleClickOnOverlay(addedOverlay._id);

        });

      } catch (e) {

        console.error('Error adding overlay to map', e);

      }

    }

  };
  useEffect(() => {

    if (overlays) {

      if (!_.isEqual(innerOverlays, overlays)) {

        const [added, removed] = compareOverlayArrays(overlays, innerOverlays);
        setInnerOverlays(overlays);
        const allBounds = overlays.map((innerOverlay) => {

          return L.polygon([innerOverlay.points.ne, innerOverlay.points.nw, innerOverlay.points.se, innerOverlay.points.sw]);

        });

        if (map) {

          if (added.length > 0) {

            addLayersToMap(added);
            if (!zoomed) {

              map.fitBounds(allBounds.map((bounds) => bounds?.getBounds()) as any);
              setZoomed(true);

            }

          }

          if (removed.length > 0) {

            removed.forEach((removedOverlay) => {

              map.eachLayer((layer: any) => {

                if (layer instanceof AnyL.DistortableImageOverlay && layer.options._id === removedOverlay._id) {

                  if (layer.editing) {

                    const layers = layer.editing.currentHandle?._layers ?? {};
                    Object.values(layers).forEach((innerLayer: any) => innerLayer.remove());
                    layer.editing.currentHandle = null;

                  }
                  map.removeLayer(layer);

                }

              });

            });

          }

        }
        setInnerOverlays(overlays);

      }

    }

  }, [overlays]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {

    if (!map || !overlays || overlays.length === 0) {

      return;

    }
    if (!zoomed) {

      const allBounds = overlays.map((innerOverlay) => {

        return L.polygon([innerOverlay.points.ne, innerOverlay.points.nw, innerOverlay.points.se, innerOverlay.points.sw]);

      });
      map?.fitBounds(allBounds.map((bounds) => bounds?.getBounds()) as any);

      setZoomed(true);

    }

  }, [zoomed, map]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {

    if (!map) {

      return;

    }
    if (isCreating) {

      if (!newMarkerPlaced) {

        map?.pm?.enableDraw('Marker', {
          snappable: false,
          markerEditable: true,
          finishOn: 'click',
          markerStyle: {
            draggable: true,
            icon: L.divIcon({
              className: 'marker',
              iconAnchor: [11, 36],
              popupAnchor: [0, -24],
              html: `<div class="pin pincolor-1972b9"></div><style>
  .pin.pincolor-1972b9 {
    border-color: #1972b9;
  }
  .pin.pincolor-1972b9::after { border-top-color: #1972b9 }
</style>`,
            }),
          },
        });

        map?.on('pm:create', (e: any) => {

          map?.pm.disableDraw();
          setNewMarkerPlaced(true);
          e.layer.myId = -1;
          e.layer.pm?.enable(overlayMarkerOptions);

        });

      }

    } else {

      map?.pm?.disableDraw();
      map?.eachLayer((layer: any) => {

        if (layer instanceof AnyL.Marker) {

          map?.removeLayer(layer);

        }

      });

    }

  }, [isCreating, newMarkerPlaced, map]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <MapContainer
      ref={(ref: any) => setMap(ref)}
      center={[52.1009166, 5.6462914]}
      zoom={17}
      minZoom={4}
      zoomControl={false}
      style={{ height: '100%' }}>
      <ScaleControl position="bottomright" imperial={false}/>
      <div>
        <div className="leaflet-control-container-custom">
          <CustomLayerControl
            map={map}
            toggleVisible={true}
          />
          <CenterControl onClick={() => {

            setZoomed(false);

          }}/>
        </div>
        <FloorFilter floors={props.possibleFloors} selectedFloors={props.chosenFloors} onChange={props.setChosenFloors}/>
      </div>
    </MapContainer>
  );

};
