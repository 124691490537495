import { FC, useEffect, useState } from 'react';
import { USERSNAP_GLOBAL_API_KEY } from '../config';

export const UserSnap: FC = () => {

  const userData = JSON.parse(localStorage.getItem('context') || '{}');
  const currentCustomer = JSON.parse(sessionStorage.getItem('currentCustomer') || '{}')
    || JSON.parse(localStorage.getItem('lastSavedCustomer') || '{}')
  || JSON.parse(localStorage.getItem('customers') || '[]')[0];

  // no typings available for the UserSnap API
  // eslint-disable-next-line
    const [api, setApi] = useState<any>(null);

  // If there is userData present, set the visitor value to the username
  useEffect(() => {

    if (api && userData && currentCustomer) {

      const onOpen = (event: any) => {

        const email = userData.user.properties.email;

        event.api.setValue('custom', {
          username: userData.user.username,
          connectedCustomer: {
            id: currentCustomer.value,
            name: currentCustomer.label,
          },
        });

        if (email) {

          event.api.setValue('visitor', email);

        }

      };

      api.on('open', onOpen);

      return () => {

        api.off('open', onOpen);

      };

    }

    return undefined;

  }, [api, currentCustomer, userData]);

  useEffect(() => {

    (window as any).onUsersnapCXLoad = (userSnap:any) => {

      userSnap.init();

      setApi(userSnap);

    };

    const script = document.createElement('script');

    script.defer = true;
    script.src = `https://widget.usersnap.com/global/load/${USERSNAP_GLOBAL_API_KEY}?onload=onUsersnapCXLoad`;

    document.getElementsByTagName('head')[0].appendChild(script);

    // Don't need to rerun this hook when locale changes, as the locale can only be set once on initialisation
    // eslint-disable-next-line

  }, []);

  return null;

};
