import {
  AutocompleteArrayInput,
  Button,
  Datagrid,
  List,
  ReferenceArrayInput,
  ReferenceField,
  TextField,
  TextInput,
  useGetIdentity,
  useNotify,
  useRecordContext,
} from 'react-admin';
import DescriptionIcon from '@mui/icons-material/Description';
import {
  Alert, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Tooltip,
} from '@mui/material';
import { useEffect, useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Grid2 from '@mui/material/Unstable_Grid2';
import { useListContext, useTranslate } from 'ra-core';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CloseIcon from '@mui/icons-material/Close';
import { CustomerRef, Zone, ZoneRef } from '@x-guard/xgac-types/xgac';
import _ from 'lodash';
import * as React from 'react';
import { BooleanSetterField } from '../../../components/fields/BooleanSetterField';
import { httpClient } from '../../../utils/httpClient';
import { XGAC_MAIN_API_URL } from '../../../config';
import { getCurrentCustomer } from '../../../lib/currentCustomer';
import SimpleChipListField from '../../../components/fields/SimpleChipListField';
import authProvider from '../../../utils/authProvider';
import { FilterResetter } from '../components/fields/Filters';
import { DateFieldWithTime } from '../../../components/fields/DateFieldWithTime';

const apiUrl = XGAC_MAIN_API_URL;

const Grid = Grid2;
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const DescriptionField = (props: any) => {

  const record = useRecordContext();
  if (!record) return null;
  const description = record.description;

  return (
    <Tooltip title={description} placement="top" arrow>
      <DescriptionIcon color={description ? 'primary' : 'disabled'} />
    </Tooltip>
  );

};

const StatusBlock = (props: { loading: boolean; content: JSX.Element }) => {

  return (
    <div className={'fingerprint-status-block'}>
      {props.loading ? (
        <div style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
        }}>
          <CircularProgress />
        </div>
      ) : (
        props.content
      )}
    </div>
  );

};

const FingerPrintStatistics = (props: { lastLoad: Date; activated: boolean; setActivated: (activated: boolean) => void }) => {

  const { activated, setActivated } = props;
  const [progress, setProgress] = useState<{ totalZones: number; fingerprintedZones: number; notFingerprintedZones: string[]; progress: number } | null>(null);
  const [devices, setDevices] = useState<string[]>([]);
  const [beaconCodes, setBeaconCodes] = useState<string[]>([]);
  const [lastSavedCustomer, setLastSavedCustomer] = useState<string | null>(null);
  const currentCustomer = getCurrentCustomer()?.value;
  const [notFingerPrintedZones, setNotFingerPrintedZones] = useState<Zone[]>([]);
  const [zoneDialogOpen, setZoneDialogOpen] = useState<boolean>(false);
  const identity = useGetIdentity();
  const translate = useTranslate();

  useEffect(() => {

    if (currentCustomer && currentCustomer !== lastSavedCustomer) {

      setLastSavedCustomer(currentCustomer);

    }

  }, [currentCustomer, identity]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {

    if (!lastSavedCustomer) return;

    setActivated(false);
    setNotFingerPrintedZones([]);
    setProgress(null);
    setDevices([]);
    setBeaconCodes([]);

  }, [lastSavedCustomer]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {

    if (!activated) return;
    if (!lastSavedCustomer) return;
    httpClient(`${apiUrl}/fingerprints/stats/fingerprint-progress/${lastSavedCustomer}`, { method: 'GET' }).then((response) => {

      setProgress(response.json);

    }).then(() => {

      httpClient(`${apiUrl}/fingerprints/stats/devices/${lastSavedCustomer}`, { method: 'GET' }).then((response) => {

        setDevices(response.json.map((device: any) => device._id));

      }).then(() => {

        httpClient(`${apiUrl}/fingerprints/stats/beacon-count/${lastSavedCustomer}`, { method: 'GET' }).then((response) => {

          setBeaconCodes(response.json.codes?.sort() || []);

        });

      });

    });

  }, [lastSavedCustomer, activated, props.lastLoad]);

  useEffect(() => {

    if (!progress) return;
    if (progress?.notFingerprintedZones.length === 0) {

      setNotFingerPrintedZones([]);
      return;

    }
    httpClient(
      `${apiUrl}/zones?includeFingerprints=true&_id=${progress?.notFingerprintedZones}&$sort=fingerprintCount&$max=100`,
      { method: 'GET' },
    ).then((response) => {

      setNotFingerPrintedZones(response.json.result);

    });

  }, [progress]);

  return (
    <div className="fingerprint-status-header">
      <Grid container spacing={4}>
        {activated ? (
          <><Grid xs={4}>
            <StatusBlock loading={!progress} content={<div
              style={{
                textAlign: 'center',
                cursor: 'pointer',
              }}
              onClick={() => setZoneDialogOpen(true)}
            >
              <span>{translate('resources.fingerprints.text.progress_zones')}</span>
              <span style={{
                fontSize: '40px',
                fontWeight: 'bold',
              }}>{progress?.progress || 0}%</span>
              <span style={{
                fontSize: '30px',
              }}>{progress?.fingerprintedZones}/{progress?.totalZones}</span>
            </div>}/>
          </Grid><Grid xs={4}>
            <StatusBlock loading={devices.length === 0} content={<div style={{
              textAlign: 'center',
            }}>
              <span>{translate('resources.fingerprints.text.progress_devices_in_fingerprint')}</span>
              {devices.length < 4 ? (
                <>
                  <ul>
                    {devices.map((device) => (
                      <li key={device}>{device}</li>
                    ))}
                  </ul>
                </>
              ) : (
                <>
                  <Tooltip
                    placement={'right'}
                    arrow
                    title={<div>
                      <ul>
                        {devices.map((device) => (
                          <li key={device}>{device}</li>
                        ))}
                      </ul>
                    </div>}>
                    <span>
                      <span style={{
                        fontSize: '40px',
                        fontWeight: 'bold',
                      }}>{devices.length}</span>
                      <span style={{
                        fontSize: '30px',
                      }}>{translate('resources.devices.text.title').toLowerCase()}</span>
                    </span>
                  </Tooltip>
                </>
              )}
            </div>}/>
          </Grid><Grid xs={4}>
            <StatusBlock loading={beaconCodes.length === 0} content={<div style={{
              textAlign: 'center',
            }}>
              <Tooltip
                placement={'left'}
                arrow
                title={<div>
                  <ul>
                    {beaconCodes?.map((code) => (
                      <li key={code}>{code}</li>
                    ))}
                  </ul>
                </div>}>
                <span>
                  <span>{translate('resources.fingerprints.text.progress_beacons_in_fingerprint')}</span>
                  <span>
                    <span style={{
                      fontSize: '40px',
                      fontWeight: 'bold',
                    }}>{beaconCodes.length}</span>
                    <span style={{
                      fontSize: '30px',
                    }}>{translate('resources.beacons.text.title').toLowerCase()}</span>
                  </span>
                </span>
              </Tooltip>
            </div>}/>
          </Grid></>
        ) : (
          <Grid xs={12}>
            <StatusBlock loading={false} content={
              <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minHeight: '110px',
              }}>
                <Button
                  onClick={() => setActivated(true)}
                  variant="contained"
                  label={translate('resources.fingerprints.text.check_progress')}
                /> </div>}/>
          </Grid>
        )}
      </Grid>
      <Dialog open={zoneDialogOpen} maxWidth="md" fullWidth onClose={() => setZoneDialogOpen(false)}>
        <DialogTitle className="flex-in-between">
          <span>{translate('resources.zones.text.not_fingerprinted_zones')}</span>
          <IconButton onClick={() => setZoneDialogOpen(false)}>
            <CloseIcon/>
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Datagrid
            rowClick={false}
            data={notFingerPrintedZones}
            total={notFingerPrintedZones.length}
            sort={{ field: 'fingerprintCount', order: 'ASC' }}
            bulkActionButtons={false}
            isLoading={false}
          >
            <ReferenceField reference={'zones'} source="_id" label="resources.zones.text.title_single" />
            <TextField source="fingerprintCount" label="Scans" />
          </Datagrid>
        </DialogContent>
      </Dialog>
    </div>
  );

};

const ClickToCustomerField = (props: any) => {

  const record = useRecordContext();
  const translate = useTranslate();
  const isAdmin = authProvider.isAdmin();
  if (!record) return null;
  if (isAdmin) {

    return (
      <ReferenceField reference={'customers'} source="customer._id" label="resources.customers.text.title_single" {...props} />
    );

  }
  return (
    <span>{translate('resources.fingerprints.text.other_customer')}</span>
  );

};

const FingerprintAlert = (props: { message: string; action: () => void }) => (
  <Alert
    severity="error"
    onClick={() => props.action()}
    icon={<ErrorOutlineIcon/>} sx={{
      marginBottom: '20px',
    }}>
    <span className="link-span">{props.message}</span>
  </Alert>
);

const FingerprintBeaconOverlap = (props: { lastLoad: Date }) => {

  const [lastSavedCustomer, setLastSavedCustomer] = useState<string | null>(null);
  const [overlappingBeacons, setOverlappingBeacons] = useState<{ commonCodes: string[]; customer: CustomerRef }[]>([]);
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const currentCustomer = getCurrentCustomer()?.value;
  const identity = useGetIdentity();
  const translate = useTranslate();

  useEffect(() => {

    if (currentCustomer && currentCustomer !== lastSavedCustomer) {

      setLastSavedCustomer(currentCustomer);

    }

  }, [currentCustomer, identity]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {

    if (!lastSavedCustomer) return;
    setOverlappingBeacons([]);

  }, [lastSavedCustomer]);
  useEffect(() => {

    if (!lastSavedCustomer) return;
    httpClient(`${apiUrl}/fingerprints/stats/common-beacons/${lastSavedCustomer}`, { method: 'GET' }).then((response) => {

      setOverlappingBeacons(response.json);

    });

  }, [lastSavedCustomer, props.lastLoad]);

  if (!overlappingBeacons.length) return null;

  return (
    <>
      <FingerprintAlert message={translate('resources.zones.text.customers_with_same_codes')} action={() => setDialogOpen(true)} />
      <Dialog open={dialogOpen} maxWidth="md" fullWidth onClose={() => setDialogOpen(false)}>
        <DialogTitle className="flex-in-between">
          <span>{translate('resources.zones.text.customers_with_same_codes_title')}</span>
          <IconButton onClick={() => setDialogOpen(false)}>
            <CloseIcon/>
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Datagrid
            rowClick={false}
            data={overlappingBeacons}
            total={overlappingBeacons.length}
            sort={{ field: 'customer._id', order: 'DESC' }}
            bulkActionButtons={false}
            isLoading={false}
          >
            <ClickToCustomerField label="resources.customers.text.title_single"/>
            <SimpleChipListField source="commonCodes" label="resources.beacons.fields.codes" />
          </Datagrid>
        </DialogContent>
      </Dialog>
    </>
  );

};

const FingerprintZoneSimilarity = (props: { lastLoad: Date }) => {

  const [lastSavedCustomer, setLastSavedCustomer] = useState<string | null>(null);
  const [zoneSimilarity, setZoneSimilarity] = useState<any>(null);
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [similarZones, setSimilarZones] = useState<{ zone1: string; zone2: string; similarity: number }[]>([]);
  const currentCustomer = getCurrentCustomer()?.value;
  const identity = useGetIdentity();
  const translate = useTranslate();

  useEffect(() => {

    if (currentCustomer && currentCustomer !== lastSavedCustomer) {

      setLastSavedCustomer(currentCustomer);

    }

  }, [currentCustomer, identity]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {

    if (!lastSavedCustomer) return;
    setSimilarZones([]);

  }, [lastSavedCustomer]);

  useEffect(() => {

    if (!lastSavedCustomer) return;
    httpClient(`${apiUrl}/fingerprints/stats/similarities`, { method: 'POST' }, {
      customer: {
        _id: lastSavedCustomer,
        _ref: 'Customer',
      },
    }).then((response) => {

      setZoneSimilarity(response.json);

    });

  }, [lastSavedCustomer, props.lastLoad]);

  useEffect(() => {

    const tempSimilarZones: { zone1: string; zone2: string; similarity: number }[] = [];
    if (!zoneSimilarity) return;
    for (const zone of Object.keys(zoneSimilarity)) {

      const currentZone = zoneSimilarity[zone];
      for (const compareZone of Object.keys(zoneSimilarity[zone])) {

        if (currentZone[compareZone] !== 0) {

          if (tempSimilarZones.find((item) => item.zone1 === compareZone && item.zone2 === zone)) continue;
          tempSimilarZones.push({ zone1: zone, zone2: compareZone, similarity: currentZone[compareZone] });

        }

      }

    }
    setSimilarZones(tempSimilarZones);

  }, [zoneSimilarity]);

  if (!similarZones.length) return null;

  return (
    <>
      <FingerprintAlert
        message={translate('resources.zones.text.zones_with_similar_prints', { smart_count: similarZones.length })}
        action={() => setDialogOpen(true)}
      />
      <Dialog open={dialogOpen} maxWidth="md" fullWidth onClose={() => setDialogOpen(false)}>
        <DialogTitle className="flex-in-between">
          <span>{translate('resources.zones.text.zones_with_similar_prints_title')}</span>
          <IconButton onClick={() => setDialogOpen(false)}>
            <CloseIcon/>
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Datagrid
            rowClick={false}
            data={similarZones}
            total={similarZones.length}
            sort={{ field: 'similarity', order: 'DESC' }}
            bulkActionButtons={false}
            isLoading={false}
          >
            <ReferenceField reference={'zones'} source="zone1" label="resources.zones.text.title_single" />
            <ReferenceField reference={'zones'} source="zone2" label="resources.fingerprints.text.is_similar_to" />
            <TextField source="similarity" label="resources.fingerprints.text.similar_scans" />
          </Datagrid>
        </DialogContent>
      </Dialog>
    </>
  );

};

const FingerprintEnoughBeacons = (props: { lastLoad: Date }) => {

  const [lastSavedCustomer, setLastSavedCustomer] = useState<string | null>(null);
  const [zonesWithNotEnoughBeacons, setZonesWithNotEnoughBeacons] = useState<ZoneRef[]>([]);
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [zones, setZones] = useState<Zone[]>([]);
  const currentCustomer = getCurrentCustomer()?.value;
  const identity = useGetIdentity();
  const translate = useTranslate();

  useEffect(() => {

    if (currentCustomer && currentCustomer !== lastSavedCustomer) {

      setLastSavedCustomer(currentCustomer);

    }

  }, [currentCustomer, identity]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {

    if (!lastSavedCustomer) return;
    setZonesWithNotEnoughBeacons([]);

  }, [lastSavedCustomer]);

  useEffect(() => {

    if (!lastSavedCustomer) return;
    httpClient(`${apiUrl}/fingerprints/stats/enough-beacons/${lastSavedCustomer}`, { method: 'GET' }).then((response) => {

      setZonesWithNotEnoughBeacons(response.json.zones.map((zone: any) => zone.zone));

    });

  }, [lastSavedCustomer, props.lastLoad]);

  useEffect(() => {

    if (!zonesWithNotEnoughBeacons.length) {

      setZones([]);
      return;

    }
    httpClient(`${apiUrl}/zones?includeFingerprints=true&_id=${zonesWithNotEnoughBeacons.map((zone) => zone._id)}`, { method: 'GET' }).then((response) => {

      setZones(response.json.result);

    });

  }, [zonesWithNotEnoughBeacons]);

  if (!zonesWithNotEnoughBeacons.length) return null;
  return (
    <>
      <FingerprintAlert
        message={translate('resources.zones.text.zones_with_bad_prints', { smart_count: zonesWithNotEnoughBeacons.length })}
        action={() => setDialogOpen(true)}
      />
      <Dialog open={dialogOpen} maxWidth="md" fullWidth onClose={() => setDialogOpen(false)}>
        <DialogTitle className="flex-in-between">
          <span>{translate('resources.zones.text.zones_with_bad_prints_title')}</span>
          <IconButton onClick={() => setDialogOpen(false)}>
            <CloseIcon/>
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Datagrid
            rowClick={false}
            data={zones}
            total={zones.length}
            sort={{ field: 'fingerprintCount', order: 'ASC' }}
            bulkActionButtons={false}
            isLoading={false}
          >
            <ReferenceField reference={'zones'} source="_id" label="resources.zones.text.title_single" />
            <TextField source="fingerprintCount" label="Scans" />
          </Datagrid>
        </DialogContent>
      </Dialog>
    </>
  );

};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const FingerprintDeviceField = (props: any) => {

  const record = useRecordContext();
  return <span>{record.deviceInfo.brand} {record.deviceInfo.model} </span>;

};
const ListRefresher = (props: { setLastLoad: (date: Date) => void }) => {

  const [savedData, setSavedData] = useState<any[]>([]);
  const { isFetching, data } = useListContext();
  useEffect(() => {

    if (isFetching && !_.isEqual(data, savedData)) {

      setSavedData(data);
      props.setLastLoad(new Date());

    }

  }, [isFetching]); // eslint-disable-line react-hooks/exhaustive-deps

  return null;

};

const FingerprintListActions = (props: { lastLoad: Date }) => {

  const [missingBeaconCodes, setMissingBeaconCodes] = useState<string[]>([]);
  const [lastSavedCustomer, setLastSavedCustomer] = useState<string | null>(null);
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [activated, setActivated] = useState<boolean>(false);
  const currentCustomer = getCurrentCustomer()?.value;
  const notify = useNotify();
  const identity = useGetIdentity();
  const translate = useTranslate();

  useEffect(() => {

    if (currentCustomer && currentCustomer !== lastSavedCustomer) {

      setLastSavedCustomer(currentCustomer);

    }

  }, [currentCustomer, identity]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {

    if (!lastSavedCustomer) return;
    setMissingBeaconCodes([]);
    setActivated(false);

  }, [lastSavedCustomer]);

  useEffect(() => {

    setActivated(false);

  }, [props.lastLoad]);

  useEffect(() => {

    if (!activated) return;
    if (!lastSavedCustomer) return;
    httpClient(`${apiUrl}/fingerprints/stats/missing-beacons/${lastSavedCustomer}`, { method: 'GET' }).then((response) => {

      setMissingBeaconCodes(response.json.missingCodes?.sort() || []);

    });

  }, [lastSavedCustomer, activated]);

  const handleCreateBeacons = () => {

    setLoading(true);
    httpClient(`${apiUrl}/fingerprints/create-beacons`, { method: 'POST' }, {
      customer: {
        _id: lastSavedCustomer,
        _ref: 'Customer',
      },
    }).then((result) => {

      setDialogOpen(false);
      notify('resources.fingerprints.text.beacons_created', { type: 'success', messageArgs: { smart_count: result.json.created } });

    }).catch(() => {

      notify('resources.fingerprints.text.beacons_not_created', { type: 'error' });

    }).finally(() => {

      setLoading(false);

    });

  };

  return (
    <>
      {activated ? (
        <>
          <Button
            onClick={() => setDialogOpen(true)}
            variant="contained"
            label={translate('resources.fingerprints.text.create_beacons')}
            disabled={missingBeaconCodes.length === 0}
          />
          <Dialog open={dialogOpen} maxWidth="md" fullWidth onClose={() => setDialogOpen(false)}>
            <DialogTitle className="flex-in-between">
              <span>{translate('resources.fingerprints.text.missing_codes')}</span>
              <IconButton onClick={() => setDialogOpen(false)}>
                <CloseIcon/>
              </IconButton>
            </DialogTitle>
            {!loading ? (
              <>
                <DialogContent>
                  <Datagrid
                    rowClick={false}
                    data={missingBeaconCodes.map((code) => ({ code })) || []}
                    total={missingBeaconCodes.length}
                    sort={{ field: 'code', order: 'ASC' }}
                    bulkActionButtons={false}
                    isLoading={false}
                  >
                    <TextField source="code" label="resources.beacons.text.code" sortable={false}/>
                  </Datagrid>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleCreateBeacons} variant="contained" label={translate('resources.fingerprints.text.create_beacons_button')} />
                </DialogActions>
              </>
            ) : (
              <DialogContent sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '200px',
              }}>
                <CircularProgress />
              </DialogContent>
            )}
          </Dialog>
        </>
      ) : (
        <Button
          onClick={() => setActivated(true)}
          variant="contained"
          label={translate('resources.fingerprints.text.check_beacons')}
        />
      )}
    </>
  );

};

export const FingerprintList = () => {

  const [lastLoad, setLastLoad] = useState(new Date());
  const [activated, setActivated] = useState<boolean>(false);

  const FingerprintActions = () => (
    <>
      {activated
        && (
          <Button onClick={() => setActivated(false)} label="resources.fingerprints.text.disable_statistics" variant={'contained'} sx={{
            marginRight: '10px',
          }} />
        )}
      <FingerprintListActions lastLoad={lastLoad}/>
    </>
  );

  const fingerPrintFilters = [
    <ReferenceArrayInput source="zoneIds" reference="zones" alwaysOn filter={{
      type: 'fingerprinted',
    }}>
      <>
        <AutocompleteArrayInput fullWidth/>
        <FilterResetter/>
      </>
    </ReferenceArrayInput>,
    <TextInput source="beaconCode" alwaysOn label="resources.beacons.fields.beacon_code"/>,
  ];
  return (
    <>
      {activated && (
        <>
          <FingerprintBeaconOverlap lastLoad={lastLoad}/>
          <FingerprintZoneSimilarity lastLoad={lastLoad}/>
          <FingerprintEnoughBeacons lastLoad={lastLoad}/>
        </>
      )}
      <FingerPrintStatistics lastLoad={lastLoad} activated={activated} setActivated={setActivated}/>
      <List exporter={false} filters={fingerPrintFilters} actions={<FingerprintActions/>} >
        <ListRefresher setLastLoad={setLastLoad} />
        <Datagrid rowClick="toggleSelection">
          <ReferenceField source="zone._id" reference="zones" />
          <DescriptionField label="Description"/>
          <ReferenceField source="user._id" reference="users" label="resources.fingerprints.fields.userId"/>
          <FingerprintDeviceField label="general.text.device"/>
          <TextField source="deviceInfo.appBundleId" label="App Bundle ID" />
          <TextField source="deviceInfo.appVersion" label="App Version" />
          <TextField source="dataCount" label="Scans" />
          <BooleanSetterField source="enabled" label="Enabled"/>
          <DateFieldWithTime source="createdAt" label="general.fields.createdAt" timeOnHover={true}/>
          <DateFieldWithTime source="updatedAt" label="general.fields.updatedAt" timeOnHover={true}/>
        </Datagrid>
      </List>
    </>
  );

};
