import {
  BulkExportButton,
  BulkExportButtonProps,
  Button,
  DataProvider,
  ExportButton,
  Loading,
  useResourceContext,
  useTranslate,
} from 'react-admin';
import {
  Alarm, Asset, AssetRef, Beacon, Customer, CustomerUserRelation, Device, Zone,
} from '@x-guard/xgac-types/xgac';
import { fetchRelatedRecords, useListContext } from 'ra-core';
import * as React from 'react';
import {
  Box, Dialog, DialogActions, DialogContent, DialogTitle, LinearProgress, TextField, Typography,
} from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import moment from 'moment/moment';

import * as XLSX from 'xlsx';
import { useEffect } from 'react';
import _ from 'lodash';
import { xgacDataProvider } from '../dataProviders/xgacDataProvider';
import { ZoneWithBeacons } from '../lib/constants/customTypes';

const resourceExporterFields: any = {
  alarms: [
    'created_at',
    'acknowledged',
    'acknowledgment_comment',
    'name',
    'severity',
    'merges',
    'asset',
    'type',
    'alarm_center',
    'id',
  ],
  'app-users': [
    'name',
    'my_security',
    'on_call_for_others',
    'username',
    'user_id',
    'email',
    'phone',
    'groups',
    'last_active_at',
    'created_at',
    'updated_at',
    'id',
  ],
  'asset-groups': [
    'name',
    'description',
    'number_of_assets',
    'assets',
    'created_at',
    'updated_at',
    'id',
  ],
  users: [
    'username',
    'name',
    'email',
    'phoneNumber',
    'roles',
    'created_at',
    'updated_at',
    'id',
  ],
  'users/without-app': [
    'username',
    'name',
    'email',
    'phoneNumber',
    'roles',
    'created_at',
    'updated_at',
    'id',
  ],
  beacons: [
    'codes',
    'zone',
    'rssiAddend',
    'createdAt',
    'updatedAt',
    'id',
  ],
  zones: [
    'name',
    'description',
    'address',
    'type',
    'assetsInside',
    'responsePriority',
    'created_at',
    'updated_at',
    'id',
  ],
  zonesWithBeacons: [
    'name',
    'description',
    'address',
    'type',
    'assetsInside',
    'responsePriority',
    'beaconCodes',
    'created_at',
    'updated_at',
    'id',
  ],
  assets: [
    'name',
    'email',
    'phone',
    'groups',
    'created_at',
    'updated_at',
    'id',
  ],
};

const filterFields = (entity: any, fields?: string[]) => {

  return _.omitBy(entity, (value, key) => {

    if (fields && fields.length > 0) {

      return !fields.includes(key);

    }
    return false;

  });

};

const timeFormatter = (value: string) => {

  return moment(value).format('YYYY-MM-DD HH:mm:ss');

};

export const jsonExporter = (entities: any[], resource: string) => {

  const workbook = XLSX.utils.book_new();
  const worksheet = XLSX.utils.json_to_sheet(entities);
  XLSX.utils.book_append_sheet(workbook, worksheet, resource);

  const customer = JSON.parse(sessionStorage.getItem('currentCustomer') || '{}');
  XLSX.writeFile(workbook, `${moment().format('YYYY_MM_DD')}_${resource}_${customer.label}.xlsx`);

};
const normalise = (value: number, max: number) => ((value) * 100) / (max);
export const exporter = (entities: any[]) => {

  const entitiesForExport = entities.map((entity) => {

    const { id, author, ...entityForExport } = entity;

    Object.keys(entityForExport).forEach((key) => {

      if (Array.isArray(entityForExport[key])) {

        entityForExport[key] = JSON.stringify(entityForExport[key]);

      }

      if (['updatedAt', 'createdAt'].includes(key)) {

        entityForExport[key] = timeFormatter(entityForExport[key]);

      }

    });

    return entityForExport;

  });
  jsonExporter(entitiesForExport, 'downloads');

};

export const AlarmExporter = (alarms: Alarm[], fields?: string[]) => {

  const alarmsForExport = alarms.map((alarm: Alarm) => {

    const fullFields = {
      created_at: timeFormatter(alarm.createdAt),
      acknowledged: alarm.ack.value,
      acknowledgment_comment: alarm.ack.comment || 'N/A',
      name: alarm.name,
      severity: alarm.severity,
      merges: alarm.mergeHistory?.length,
      asset: alarm.asset?.name,
      type: alarm.type,
      alarm_center: (alarm.alarmCenter as unknown as (Customer | undefined))?.name || 'N/A',
      id: alarm._id,
    };

    return filterFields(fullFields, fields);

  });

  jsonExporter(alarmsForExport, 'alarms');

};

export const appUserExporter = async (appUsers: any[], passedFetchRelatedRecords: any, fields?: string[]) => {

  const formattedAppUsers = appUsers.map((appUser) => {

    return { ...appUser, user_id: appUser.user._id };

  });

  const users = await passedFetchRelatedRecords(formattedAppUsers, 'user_id', 'users');
  const groups = await passedFetchRelatedRecords(formattedAppUsers, 'assetGroups', 'asset-groups');

  const appUsersForExport = formattedAppUsers.map((appUser) => {

    const formattedGroups = appUser.assetGroups?.map((group: any) => {

      const groupId = group._id || group;
      return groups[groupId]?.name;

    });

    const fullFields = {
      name: appUser.name,
      my_security: appUser.app.mode,
      on_call_for_others: appUser.available,
      username: users[appUser.user._id]?.username,
      user_id: users[appUser.user._id]?._id,
      email: appUser.properties.email,
      phone: appUser.properties.phoneNumber,
      groups: formattedGroups?.join('\n'),
      last_active_at: appUser.lastObservationAt ? timeFormatter(appUser.lastObservationAt) : null,
      created_at: timeFormatter(appUser.createdAt),
      updated_at: timeFormatter(appUser.updatedAt),
      id: appUser._id,
    };

    return filterFields(fullFields, fields);

  });

  jsonExporter(appUsersForExport, 'app-users');

};

export const assetExporter = async (assets: Asset[], passedFetchRelatedRecords: any, fields?: string[]) => {

  const groups = await passedFetchRelatedRecords(assets, 'assetGroups', 'asset-groups');

  const assetsForExport = assets.map((innerAsset) => {

    const formattedGroups = innerAsset.assetGroups?.map((group: any) => {

      let groupRef = group;
      if (typeof group === 'string') {

        groupRef = {
          _id: group,
          _ref: 'AssetGroup',
        };

      }
      return groups[groupRef]?.name;

    });

    const fullFields = {
      name: innerAsset.name,
      email: innerAsset.properties.email,
      phone: innerAsset.properties.phoneNumber,
      groups: formattedGroups?.join('\n'),
      created_at: timeFormatter(innerAsset.createdAt),
      updated_at: timeFormatter(innerAsset.updatedAt),
      id: innerAsset._id,
    };

    return filterFields(fullFields, fields);

  });

  jsonExporter(assetsForExport, 'assets');

};

export const assetGroupExporter = (assetGroups: any[], passedFetchRelatedRecords: any, fields?: string[]) => {

  const assetsInGroupMap = assetGroups.map((group: any) => {

    return {
      id: group._id,
      assetIds: group.assets.map((asset: AssetRef) => asset._id),
    };

  });

  passedFetchRelatedRecords(assetsInGroupMap, 'assetIds', 'all-assets').then((assets: any) => {

    const mappedGroups = assetGroups.map((group: any) => {

      const groupAssets: string[] = [];
      group.assets.forEach((assetInGroup: AssetRef) => {

        groupAssets.push(assets[assetInGroup._id].name);

      });
      const fullFields = {
        name: group.name,
        description: group.description,
        number_of_assets: group.assets.length,
        assets: groupAssets.join('\n'),
        created_at: timeFormatter(group.createdAt),
        updated_at: timeFormatter(group.updatedAt),
        id: group._id,
      };

      return filterFields(fullFields, fields);

    });

    jsonExporter(mappedGroups, 'asset_groups');

  });

};

export const buttonAssetExporter = (assets: Array<Asset & { device: Device }>, fields?: string[]) => {

  const mappedAssets = assets.map((asset: Asset & { device: Device }) => {

    const fullFields = {
      name: asset.name,
      device: asset.device?.name,
      address: asset.position?.properties.address?.formattedAddress,
      floor: asset.position?.properties.address?.floor,
      created_at: timeFormatter(asset.createdAt),
      updated_at: timeFormatter(asset.updatedAt),
    };

    return filterFields(fullFields, fields);

  });
  jsonExporter(mappedAssets, 'assetsWithDevice');

};

export const userExporter = (users: any, passedFetchRelatedRecords: any, fields?: string[]) => {

  passedFetchRelatedRecords(users, '_id', 'customer-user-relations-by-user').then((relations: CustomerUserRelation[]) => {

    const rolesByUser: any = {};
    for (const relation of Object.values(relations)) {

      if (rolesByUser[relation.user?._id]) {

        rolesByUser[relation.user?._id].push(relation.roles);

      } else {

        rolesByUser[relation.user._id] = relation.roles;

      }

    }
    const mappedUsers = users.map((user: any) => {

      const fullFields = {
        username: user.username,
        name: user.name,
        email: user.properties.email,
        phoneNumber: user.properties.phoneNumber,
        roles: rolesByUser[user._id]?.join('\n'),
        created_at: timeFormatter(user.created_at),
        updated_at: timeFormatter(user.updated_at),
        id: user._id,
      };

      return filterFields(fullFields, fields);

    });
    jsonExporter(mappedUsers, 'users');

  });

};

export const beaconExporter = (beacons: Beacon[], passedFetchRelatedRecords: any, fields?: string[]) => {

  passedFetchRelatedRecords(beacons, 'zone', 'zones').then((zones: any) => {

    const mappedBeacons = beacons.map((beacon: Beacon) => {

      const zoneForBeacon = beacon.zone?._id ? zones[beacon.zone?._id].name : null;

      const fullFields = {
        codes: beacon.codes.join('\n'),
        zone: zoneForBeacon,
        rssiAddend: beacon.rssiAddend,
        createdAt: timeFormatter(beacon.createdAt),
        updatedAt: timeFormatter(beacon.updatedAt),
        id: beacon._id,
      };

      return filterFields(fullFields, fields);

    });
    jsonExporter(mappedBeacons, 'beacons');

  });

};

export const zoneExporter = (zones: Zone[], fields?: string[]) => {

  const mappedZones = zones.map((zone: Zone) => {

    const fullFields = {
      name: zone.name,
      description: zone.description,
      address: zone.location.properties.address?.formattedAddress,
      type: zone.type,
      assetsInside: zone.assetsInside.length,
      responsePriority: zone.responsePriority,
      created_at: timeFormatter(zone.createdAt),
      updated_at: timeFormatter(zone.updatedAt),
      id: zone._id,
    };

    return filterFields(fullFields, fields);

  });
  jsonExporter(mappedZones, 'zones');

};
export const zoneWithBeaconsExporter = (zones: ZoneWithBeacons[], fields?: string[]) => {

  const mappedZones = zones.map((zone: ZoneWithBeacons) => {

    const fullFields = {
      name: zone.name,
      description: zone.description,
      address: zone.location.properties.address?.formattedAddress,
      type: zone.type,
      assetsInside: zone.assetsInside.length,
      responsePriority: zone.responsePriority,
      beaconCodes: zone.beacons.map((beacon: Beacon) => beacon.codes.join('\n')).join('\n'),
      created_at: timeFormatter(zone.createdAt),
      updated_at: timeFormatter(zone.updatedAt),
      id: zone._id,
    };

    return filterFields(fullFields, fields);

  });
  jsonExporter(mappedZones, 'zonesWithBeacons');

};
export const CustomExportButton = (props: {
  resource: string;
  getRecords: any;
  progress: number;
  total: number | null;
  buttonLabel: string;
  disabled?: boolean;
  variant?: string;
  sx?: any;
  hasTimeFilter?: boolean;
}) => {

  const translate = useTranslate();
  const [exporterFields, setExporterFields] = React.useState<string[]>([]);
  const [selectedExporterFields, setSelectedExporterFields] = React.useState<string[]>([]);
  const [fieldsSelectorDialogOpen, setFieldsSelectorDialogOpen] = React.useState(false);
  const [filterFrom, setFilterFrom] = React.useState<string | null>(null);
  const [filterTo, setFilterTo] = React.useState<string | null>(null);
  const [loading, setLoading] = React.useState(false);

  useEffect(() => {

    if (resourceExporterFields[props.resource]) {

      setExporterFields(resourceExporterFields[props.resource]);
      const fieldsInStorage = localStorage.getItem(`${props.resource}-exporter-fields`);
      if (fieldsInStorage) {

        setSelectedExporterFields(JSON.parse(fieldsInStorage));

      }

    }

  }, [props.resource]);
  const fullExporter = async (resource: string, allEntities: any[], fields?: string[]) => {

    if (resource === 'alarms') {

      AlarmExporter(allEntities, fields);

    }
    if (resource === 'app-users') {

      await appUserExporter(allEntities, fetchRelatedRecords(xgacDataProvider as any as DataProvider), fields);

    }

    if (resource === 'asset-groups') {

      assetGroupExporter(allEntities, fetchRelatedRecords(xgacDataProvider as any as DataProvider), fields);

    }
    if (resource === 'users/without-app') {

      userExporter(allEntities, fetchRelatedRecords(xgacDataProvider as any as DataProvider), fields);

    }
    if (resource === 'beacons') {

      beaconExporter(allEntities, fetchRelatedRecords(xgacDataProvider as any as DataProvider), fields);

    }
    if (resource === 'zones') {

      zoneExporter(allEntities, fields);

    }
    if (resource === 'zonesWithBeacons') {

      zoneWithBeaconsExporter(allEntities, fields);

    }
    if (resource === 'assets/without-app' || resource === 'assets') {

      await assetExporter(allEntities, fetchRelatedRecords(xgacDataProvider as any as DataProvider), fields);

    }
    setLoading(false);

  };

  const callFullExporter = async () => {

    if (exporterFields.length > 0) {

      setFieldsSelectorDialogOpen(true);

    } else {

      const allEntities = await props.getRecords();
      await fullExporter(props.resource, allEntities);

    }

  };

  const closeFieldsSelectorDialog = () => {

    setFilterTo(null);
    setFilterFrom(null);
    setFieldsSelectorDialogOpen(false);
    localStorage.setItem(`${props.resource}-exporter-fields`, JSON.stringify(selectedExporterFields));

  };
  const callExporterWithFields = async () => {

    setLoading(true);
    let allEntities: any;
    if (props.hasTimeFilter && (filterFrom || filterTo)) {

      const usedFilterFrom = filterFrom ? new Date(filterFrom).toISOString() : new Date('1970-01-01T00:00').toISOString();
      const usedFilterTo = filterTo ? new Date(filterTo).toISOString() : new Date().toISOString();
      allEntities = await props.getRecords({ createdAt_between: [usedFilterFrom, usedFilterTo] });

    } else {

      allEntities = await props.getRecords();

    }
    await fullExporter(props.resource, allEntities, selectedExporterFields);
    setLoading(false);
    closeFieldsSelectorDialog();

  };

  return (
    <>
      <Button
        disabled={props.disabled}
        onClick={() => callFullExporter()}
        startIcon={<FileDownloadIcon/>}
        label={translate(props.buttonLabel)}
        sx={{ minWidth: 'max-content', whiteSpace: 'nowrap', ...props.sx }}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        variant={props.variant}
      />
      <Dialog open={loading} maxWidth="md" fullWidth>
        <DialogTitle>{translate('general.text.exporting')}</DialogTitle>

        <DialogContent>
          {(props.progress === 0 && props.total === 0)
            && (
              <Loading loadingSecondary=""/>
            )}
          {props.progress !== 0
            && (
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box sx={{ width: '100%', mr: 1 }}>
                  <LinearProgress variant="determinate"
                    value={normalise(props.progress, props.total || 0)}
                  />
                </Box>
                <Box sx={{ minWidth: 35 }}>
                  <Typography variant="body2" color="text.secondary">{`${props.progress}/${props.total}`}</Typography>
                </Box>
              </Box>
            )}
        </DialogContent>
      </Dialog>
      <Dialog open={fieldsSelectorDialogOpen} onClose={closeFieldsSelectorDialog} maxWidth={'sm'} fullWidth>
        <DialogTitle>{translate('general.text.export_select_columns')}</DialogTitle>
        <DialogContent>
          {props.hasTimeFilter && (
            <div className="export-date-filter">
              <TextField
                type="datetime-local"
                label={`${translate('general.text.from')} (${translate('general.text.optional')})`}
                variant={'outlined'}
                InputLabelProps={{ shrink: true }}
                value={filterFrom}
                onChange={(e) => setFilterFrom(e.target.value)}
              />
              <span>-</span>
              <TextField
                type="datetime-local"
                label={`${translate('general.text.until')} (${translate('general.text.optional')})`}
                InputLabelProps={{ shrink: true }}
                variant={'outlined'}
                value={filterTo}
                onChange={(e) => setFilterTo(e.target.value)}
              />
            </div>
          )}
          <Box key="selectAll" className="export-select-row" borderBottom="3px solid #DCDCDC !important"
            onClick={() => {

              if (selectedExporterFields.length === exporterFields.length) {

                setSelectedExporterFields([]);

              } else {

                setSelectedExporterFields([...exporterFields]);

              }

            }}>
            <Box sx={{ minWidth: 35 }}>
              <input
                type="checkbox"
                checked={selectedExporterFields.length === exporterFields.length}
                onChange={(e) => {

                  if (e.target.checked) {

                    setSelectedExporterFields([...exporterFields]);

                  } else {

                    setSelectedExporterFields([]);

                  }

                }}
              />
            </Box>
            <Box sx={{ width: '100%', mr: 1 }}>
              <Typography variant="body2" color="text.secondary" sx={{ fontStyle: 'italic' }}>{translate('general.text.toggle_all')}</Typography>
            </Box>
          </Box>
          {exporterFields.map((field: string, index) => {

            return (
              <Box key={field} className="export-select-row" sx={index === exporterFields.length - 1 ? { borderBottom: 'none !important' } : {}}
                onClick={() => {

                  if (selectedExporterFields.includes(field)) {

                    setSelectedExporterFields(selectedExporterFields.filter((selectedField) => selectedField !== field));

                  } else {

                    setSelectedExporterFields([...selectedExporterFields, field]);

                  }

                }}>
                <Box sx={{ minWidth: 35 }}>
                  <input
                    type="checkbox"
                    checked={selectedExporterFields.includes(field)}
                    onChange={(e) => {

                      if (e.target.checked) {

                        setSelectedExporterFields([...selectedExporterFields, field]);

                      } else {

                        setSelectedExporterFields(selectedExporterFields.filter((selectedField) => selectedField !== field));

                      }

                    }}
                  />
                </Box>
                <Box sx={{ width: '100%', mr: 1 }}>
                  <Typography variant="body2" color="text.secondary">{field}</Typography>
                </Box>
              </Box>
            );

          })}
        </DialogContent>
        <DialogActions>
          <Button onClick={closeFieldsSelectorDialog} label="ra.action.cancel"/>
          <Button onClick={callExporterWithFields} label="ra.action.export"/>
        </DialogActions>
      </Dialog>
    </>
  );

};
export const FullExportButton = (props: { resource: string }) => {

  const [progress, setProgress] = React.useState(0);
  const [total, setTotal] = React.useState<number | null>(null);

  if (!resourceExporterFields[props.resource]) {

    return <ExportButton label="general.text.export_all"/>;

  }
  const allDownloader = async (resource: string, filter = {}) => {

    setProgress(0);
    setTotal(null);
    let tempTotal: number | null = null;
    const allEntities: any[] = [];
    const addEntities = async (page: number) => {

      const requestResult = await xgacDataProvider.getList(resource, {
        pagination: { page, perPage: 100 },
        sort: { field: resource === 'beacons' ? 'codes' : 'createdAt', order: 'ASC' },
        filter,
      });

      if (page === 1) {

        setTotal(requestResult.total || null);
        tempTotal = requestResult.total || null;

      }

      allEntities.push(...requestResult.data);
      setProgress(allEntities.length);
      if (tempTotal && requestResult.data?.length > 0 && allEntities.length < (tempTotal || 0)) {

        await addEntities(page + 1);

      }

    };

    await addEntities(1);
    return allEntities;

  };

  const getEntities = async (filter: any) => {

    return allDownloader(props.resource, filter || {});

  };

  return (
    <CustomExportButton
      resource={props.resource}
      getRecords={getEntities}
      progress={progress}
      total={total}
      buttonLabel="general.text.export_all"
      hasTimeFilter={true}
    />
  );

};

export const CustomBulkExportButton = (props: BulkExportButtonProps) => {

  const [progress, setProgress] = React.useState(0);
  const [total, setTotal] = React.useState<number | null>(null);
  const resourceFromContext = useResourceContext();
  const resource = props.resource || resourceFromContext;
  const { selectedIds } = useListContext();

  if (!resourceExporterFields[resource]) {

    return <BulkExportButton label="general.text.export_selection" exporter={exporter}/>;

  }
  const selectedIdsDownloader = async () => {

    setProgress(0);
    setTotal(null);

    const requestResult = await xgacDataProvider.getMany(resource, {
      ids: selectedIds,
    });

    if (requestResult.data) {

      setTotal(requestResult.data.length || null);
      setProgress(requestResult.data.length);

    }
    return requestResult.data;

  };
  const getEntities = async () => {

    if (!selectedIds) {

      return null;

    }
    return selectedIdsDownloader();

  };

  return (
    <CustomExportButton resource={resource} getRecords={getEntities} progress={progress} total={total} buttonLabel="general.text.export_selection"/>
  );

};
