import { LatLng } from 'leaflet';
import { httpClient } from './httpClient';
import { googleAddressComponentsDecoder } from '../lib/GoogleAddressComponentsDecoder';
import { XGAC_MAIN_API_URL } from '../config';

const apiUrl = XGAC_MAIN_API_URL;
export const reverseCoordinates = async (coordinates: LatLng, size?: number) => {

  const reverseRequest = await httpClient(`${apiUrl}/zones/reverse-geocode/${coordinates.lat}/${coordinates.lng}`);

  const preferedOrder = ['street_number', 'route', 'locality', 'administrative_area_level_1', 'country'];
  const orderedPlaces = reverseRequest.json.sort((a: any, b: any) => {

    return preferedOrder.indexOf(a.types[0]) - preferedOrder.indexOf(b.types[0]);

  }).filter((place: any) => {

    return preferedOrder.some((type: string) => place.types.includes(type));

  });

  if (reverseRequest) {

    const place = reverseRequest.json.find((innerPlace: any) => {

      if (size) {

        if (size < 10000) {

          return innerPlace.types.includes('street_address');

        }
        if (size < 60000) {

          return innerPlace.types.includes('route');

        }
        if (size < 200000) {

          return innerPlace.types.includes('postal_code');

        }
        if (size < 50000000) {

          return innerPlace.types.includes('locality');

        }
        if (size < 200000000) {

          return innerPlace.types.includes('administrative_area_level_1');

        }

        return innerPlace.types.includes('country');

      }
      return innerPlace.types.includes('street_address');

    }) || orderedPlaces[0] || { address_components: [] };

    const parsedGoogleInput = googleAddressComponentsDecoder(place.address_components || []);

    return {
      place,
      parsedGoogleInput,
    };

  }
  return { place: null, parsedGoogleInput: null };

};
