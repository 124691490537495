import React from 'react';
import {
  Create, useNotify, useRedirect, useResourceContext, useTranslate,
} from 'react-admin';
import { refreshContext } from '../../utils/authProvider';

export const CreateWithSuccess = (props: any) => {

  function capitalizeFirstLetter(string: string) {

    return string.charAt(0).toUpperCase() + string.slice(1, -1);

  }

  const notify = useNotify();
  const redirect = useRedirect();
  const resource = useResourceContext();
  const translate = useTranslate();
  const OnSuccess = () => {

    if (resource === 'customers') {

      refreshContext();

    }

    notify(`${capitalizeFirstLetter(resource)} ${translate('general.text.saved').toLowerCase()}`, { type: 'success' });

    redirect(props.redirect ?? `/${resource}`);

  };

  return (<Create mutationOptions={{ onSuccess: OnSuccess }}{...props}></Create>);

};
