import {
  AppBar, Button,
} from '@mui/material';
import {
  UserMenuClasses,
  useGetIdentity,
  useTranslate,
  useRefresh,
  useUnselectAll,
} from 'react-admin';
import AccountCircle from '@mui/icons-material/AccountCircle';
import { useLocation } from 'react-router-dom';
import authProvider from '../../utils/authProvider';
import ReactSelect from '../../lib/react-select/reactSelect';
import { BUILD_REF_NAME, BUILD_COMMIT_SHA } from '../../config';
import { CustomLocaleMenuButton } from '../../components/locales/CustomLocaleMenuButton';

export const MyAppBar = (props: any) => {

  const location = useLocation();
  const translate = useTranslate();
  const { isLoading, data } = useGetIdentity();
  const refresh = useRefresh();
  const { refetch } = useGetIdentity();
  const resource = location.pathname.slice(1);
  const unselectAll = useUnselectAll(resource);

  const changeCustomer = async (event: any) => {

    const url = window.location.pathname.split('/');
    let lastPart = url.pop();
    localStorage.setItem('lastSavedCustomer', JSON.stringify(event));
    sessionStorage.setItem('currentCustomer', JSON.stringify(event));
    unselectAll();

    if (!lastPart) {

      lastPart = '';

    }
    refresh();
    if (refetch) {

      refetch();

    }
    if (lastPart === 'live-map') {

      window.location.reload();

    }

    if (url.pop() === 'reports') {

      window.location.reload();

    }

  };

  const defaultCustomer = () => {

    const sessionCustomer = sessionStorage.getItem('currentCustomer');
    const localCustomer = localStorage.getItem('lastSavedCustomer');

    const currentCustomer = sessionCustomer || localCustomer;
    if (!sessionCustomer && localCustomer) {

      sessionStorage.setItem('currentCustomer', localCustomer);

    }
    if (!currentCustomer) {

      const customerString = localStorage.getItem('customers');
      if (!customerString) return {};
      const customers = JSON.parse(customerString);
      sessionStorage.setItem('currentCustomer', JSON.stringify(customers[0]));
      return customers[0];

    }

    return JSON.parse(currentCustomer);

  };

  return (
    <AppBar
      elevation={0}
      sx={{
        '& .RaAppBar-title': {
          flex: 1,
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
        },
        padding: '5px 20px',
        flexDirection: 'row',
        justifyContent: 'space-between',
      }}
      color="primary"
      {...props}
      title={null}
    >
      <div style={{ display: 'flex', flexDirection: 'row', height: 50 }}>
        <img src="/assets/img/bhvk.svg" height="40" alt="X-Guard" style={{ verticalAlign: 'middle', marginTop: '5px' }}/>
        {/* Commented out because of changes in trial and paid structure. Should return. when customer types are implemented */}
        {/* <Chip */}
        {/*  label={translate(isTrial ? 'general.text.free_trial' : 'general.text.licence_active')} */}
        {/*  color={'info'} */}
        {/*  style={{ marginLeft: '10px', marginBottom: '3px', alignSelf: 'flex-end' }} */}
        {/* /> */}

        {BUILD_REF_NAME === 'develop'
          ? <a
            style={{ color: 'white', alignSelf: 'end', paddingLeft: '10px' }}
            target={'_blank'}
            href={`https://github.com/X-Guard/xgac-r-management-dashboard/commit/${BUILD_COMMIT_SHA}`}
            rel="noreferrer">{`${BUILD_COMMIT_SHA}`.substring(0, 7)}</a>
          : null}

      </div>
      <div style={{
        display: 'flex', flexDirection: 'row', height: 48, paddingTop: '5px',
      }}>
        {(localStorage.getItem('customers') && authProvider.isAdmin())
              && <div style={{ width: 300, marginRight: '1em', marginTop: 4 }}>
                <ReactSelect
                  onChange={changeCustomer}
                  options={JSON.parse(localStorage.getItem('customers') ?? '') || []}
                  defaultValue={defaultCustomer}
                  isDisabled={(JSON.parse(localStorage.getItem('customers') ?? '') || []).length <= 1}
                />
              </div>
        }
        {!isLoading && data?.fullName ? (
          <Button
            style={{ color: 'white', textTransform: 'none' }}
            aria-label={translate('ra.auth.user_menu', { _: 'ra.auth.user_menu' })}
            className={UserMenuClasses.userButton}
            color="primary"
            startIcon={<AccountCircle />}
            disabled={true}
          >
            {data.fullName}
          </Button>
        ) : null }
        <div style={{ marginTop: 3 }}>
          <CustomLocaleMenuButton/>
        </div>
      </div>
    </AppBar>
  );

};
