import {
  AddItemButton,
  ArrayInput,
  FormDataConsumer,
  InputProps,
  regex,
  required,
  SimpleFormIterator,
  TextInput,
  useInput,
} from 'react-admin';
import {
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import * as React from 'react';
import { useTranslate } from 'ra-core';
import { useState } from 'react';
import { daysOfWeek } from '../../lib/constants/selectChoices';

const specialOptions = [
  { id: 'all', name: 'resources.static-responders.text.days.all' },
  { id: 'weekdays', name: 'resources.static-responders.text.days.weekdays' },
  { id: 'weekends', name: 'resources.static-responders.text.days.weekends' },
];
const specialOptionMapping: Record<string, number[]> = {
  all: [1, 2, 3, 4, 5, 6, 7],
  weekdays: [1, 2, 3, 4, 5],
  weekends: [6, 7],
};

const numberArrayToSpecialOptions = (value?: number[]) => {

  if (!value) {

    return [];

  }

  let displayDays = [...value];
  const newDisplayValue = Object.keys(specialOptionMapping).filter((key) => {

    if (specialOptionMapping[key].every((v: number) => displayDays.includes(v))) {

      displayDays = displayDays.filter((v: number) => !specialOptionMapping[key].includes(v));
      return key;

    }
    return null;

  });

  return [...newDisplayValue.filter((v) => v !== null), ...displayDays];

};

const DayInput = (props: InputProps) => {

  const { field } = useInput({
    source: props.source, validate: [required()], shouldUnregister: true, isRequired: true,
  });
  const translate = useTranslate();
  const [displayValue, setDisplayValue] = useState<Array<number | string>>(numberArrayToSpecialOptions(field.value || []));
  numberArrayToSpecialOptions(field.value);
  const handleChange = (event: any) => {

    let value = event.target.value;

    if (value.length > 1 && value[0] === 'all') {

      value = value.splice(1);

    }

    const mappedValue = value.map((v: string) => {

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (specialOptionMapping[v]) {

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return specialOptionMapping[v];

      }
      return v;

    }).flat().filter((v: number, index: number, array: number[]) => {

      return array.indexOf(v) === index;

    });
    field.onChange(mappedValue);

    setDisplayValue(numberArrayToSpecialOptions(mappedValue));

  };
  return (
    <>
      <FormControl fullWidth sx={{
        display: 'inline-block',
        minWidth: '150px',
      }}>
        <InputLabel>{translate(typeof props.label === 'string' ? props.label : 'resources.static-responders.fields.days_of_week')}</InputLabel>
        <Select
          multiple
          value={displayValue}
          label={false}
          fullWidth
          required
          onChange={handleChange}
        >
          {specialOptions.map((option) => (
            <MenuItem key={option.id} value={option.id}>{translate(option.name)}</MenuItem>
          ))}
          <Divider/>
          {daysOfWeek.map((day) => (
            <MenuItem key={day.id} value={day.id}>{translate(day.name)}</MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );

};
export const ScheduleInput = (props: InputProps) => {

  const translate = useTranslate();
  const timeValidator = [regex(/^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/, 'general.text.invalid_time')];
  const timeEndBeforeStartValidator = (value: any) => {

    if (!Array.isArray(value)) {

      return undefined;

    }
    let hasError = false;
    value.forEach((v: any) => {

      if (v.timeStart && v.timeEnd && (v.timeStart >= v.timeEnd)) {

        hasError = true;

      }

    });

    if (hasError) {

      return 'general.text.time_end_before_start';

    }
    return undefined;

  };
  return (
    <ArrayInput source={props.source} label={props.label || 'resources.static-responders.fields.schedule.entries'} validate={timeEndBeforeStartValidator}>
      <SimpleFormIterator
        addButton={<div><AddItemButton/><
          Typography variant="body1" sx={{ display: 'inline', fontSize: '15px' }}>
          {translate('resources.static-responders.fields.schedule_entry_add')}
        </Typography></div>}
        disableReordering={true}
      >
        <FormDataConsumer>
          {({ getSource }) => {

            if (!getSource) {

              return null;

            }
            return (
              <div style={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
                gap: '10px',
              }}>
                <DayInput source={getSource('daysOfWeek')} label="resources.static-responders.fields.days_of_week"/>
                <TextInput source={getSource('timeStart')} label="resources.static-responders.fields.time_start" required validate={timeValidator}/>
                <TextInput source={getSource('timeEnd')} label="resources.static-responders.fields.time_end" required validate={timeValidator}/>
              </div>
            );

          }}
        </FormDataConsumer>

      </SimpleFormIterator>
    </ArrayInput>
  );

};
