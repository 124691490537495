import {
  Box,
  Card,
  CardContent,
} from '@mui/material';
import {
  Button,
  useCheckAuth,
  useGetIdentity,
} from 'react-admin';
import Grid2 from '@mui/material/Unstable_Grid2';
import { useEffect, useState } from 'react';
import { BatteryCharging20, BatteryChargingFull, SignalCellularAlt } from '@mui/icons-material';
import { Asset, Device } from '@x-guard/xgac-types/xgac';
import { GetListResult, useRedirect, useTranslate } from 'ra-core';
import CircularProgress from '@mui/material/CircularProgress';
import queryString from 'query-string';
import { useGetListLive } from '@react-admin/ra-realtime';
import { xgacDataProvider } from '../../../dataProviders/xgacDataProvider';
import { PhoneInputCard } from '../cards/phoneInputCard';
import { AlarmListSmall } from '../entities/alarm';
import { CounterCard } from '../components/counterCard';
import { httpClient } from '../../../utils/httpClient';
import { XGAC_MAIN_API_URL } from '../../../config';
import { getCurrentCustomer } from '../../../lib/currentCustomer';
import authProvider from '../../../utils/authProvider';
import { ZoneOverview } from '../../../components/pages/zoneOverview';

const apiUrl = XGAC_MAIN_API_URL;

const Grid = Grid2;

export const HomePage = () => {

  const userIdentity = useGetIdentity();
  const [currentCustomer, setCurrentCustomer] = useState<string>(getCurrentCustomer()?.value || '');
  const [user, setUser] = useState<any>(null);
  const [customerUpdatedAt, setCustomerUpdatedAt] = useState<Date | null>(null);
  const [alarmCount, setAlarmCount] = useState<number>(0);
  const [devices, setDevices] = useState<any[] | null>(null);
  const [onlineDevices, setOnlineDevices] = useState<number>(0);
  const [emptyDevices, setEmptyDevices] = useState<number>(0);
  const [gatewayCount, setGatewayCount] = useState<number | null>(null);
  const [onlineGateways, setOnlineGateways] = useState<number | null>(null);
  const redirect = useRedirect();
  const checkAuth = useCheckAuth();
  const roles = authProvider.getRoles();
  const translate = useTranslate();
  const isTrial = roles.includes('bhvk_trial');
  checkAuth();

  const listResult = useGetListLive('specials/bhv-knop/device-list', {
    pagination: { page: 1, perPage: 1000 },
    filter: { type: '!loraGateway' },
    sort: { field: 'battery', order: 'ASC' },
  });

  const zoneOverviewListResult = useGetListLive('report-triggers', {
    pagination: { page: 1, perPage: 1 },
    filter: { 'config.type': 'zoneOverview' },
    sort: { field: 'createdAt', order: 'DESC' },
  });

  useEffect(() => {

    if (userIdentity.data?.id) {

      xgacDataProvider.getOne('users', { id: userIdentity.data?.id }).then((receivedUser: any) => {

        setUser(receivedUser.data);

      }).catch(() => {

        setUser(null);

      });

    }

  }, [userIdentity.data?.id]);

  useEffect(() => {

    if (user?.customer?.updatedAt) {

      setCustomerUpdatedAt(new Date(user.customer.updatedAt));

    }

  }, [user]);

  useEffect(() => {

    const newCurrentCustomer = getCurrentCustomer()?.value;
    if (newCurrentCustomer && newCurrentCustomer !== currentCustomer) {

      setCurrentCustomer(newCurrentCustomer);
      setCustomerUpdatedAt(new Date());

    }

  }, [currentCustomer, userIdentity]);

  useEffect(() => {

    if (!listResult.data) return;
    setDevices(listResult.data);
    const batteryThreshold = 20;
    const tempEmptyDevices = listResult.data.filter((device: Asset & { device: Device }) => {

      return typeof device.device.battery !== 'undefined' && device.device.battery < batteryThreshold;

    });
    setEmptyDevices(isTrial ? 0 : tempEmptyDevices.length);

    xgacDataProvider.getList('alarms', {
      pagination: { page: 1, perPage: 1 },
      sort: { field: 'createdAt', order: 'DESC' },
      filter: {},
    }).then((receivedAlarms: GetListResult) => {

      if (!receivedAlarms.total) return;
      setAlarmCount(receivedAlarms.total);

    });

    httpClient(`${apiUrl}/specials/bhv-knop/gateways?${queryString.stringify({ 'customer._id': getCurrentCustomer()?.value })}`).then((response: any) => {

      if (!response.json) return;
      const onlineGatewayResult = response.json.result?.filter((gateway: any) => gateway.gatewayStatus.online === true) || [];
      setGatewayCount(response.json.total);
      setOnlineGateways(isTrial ? response.json.total : onlineGatewayResult.length);

    });

  }, [isTrial, customerUpdatedAt, listResult.data]);

  useEffect(() => {

    if (isTrial) {

      setOnlineDevices(1);
      return;

    }

    if (devices && devices.length > 0) {

      const currentTimestamp = new Date().getTime();

      const tempOnlineDevices = devices.filter((device: Asset & { device: Device }) => {

        const lastSeenTimestamp = new Date(device.lastObservationAt || 0).getTime();
        return (currentTimestamp - lastSeenTimestamp) < 1000 * 60 * 60 * 20;

      });
      setOnlineDevices(tempOnlineDevices.length);

    }

  }, [isTrial, devices]);

  return <>
    <Grid container spacing={2}>
      <Grid xs={12}>
        <Card color={'red'}>
          <CardContent>
            <h1>{`${translate('general.text.welcome')} `} <small>{translate('homepage.text.welcome_text2')}</small></h1>
            <p> {translate('homepage.text.welcome_subtext')}</p>
          </CardContent>
        </Card>
      </Grid>
      {user && !user.properties?.phoneNumber && (
        <PhoneInputCard userSetter={setUser} user={user} />
      )}
      <Grid xs={12}>
        <Card>
          <CardContent style={{ height: 40 }}>
            <h4>{translate('homepage.text.actual_status')}</h4>
          </CardContent>
        </Card>
      </Grid>
      <Grid xs={12} md={7}>
        <Card style={{ height: '100%' }} color={'secondary'}>
          <CardContent style={{ height: '100%' }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Box>
                <h4 style={{ marginTop: 0 }}>{translate('resources.devices.text.buttons')}</h4>
              </Box>
              <Box>
                <Button
                  variant={'contained'}
                  color={'secondary'}
                  size={'large'}
                  label="general.text.order_singular"
                  onClick={() => {

                    redirect('/bestellen');

                  }}
                />
              </Box>
            </Box>
            <Grid container spacing={2} style={{ marginTop: '1em' }}>
              <Grid xs={12} sm={6} md={6}>
                <CounterCard
                  count={onlineDevices}
                  total={devices?.length || 0}
                  warning={onlineDevices < (devices?.length || 0) }
                  actionButton={onlineDevices < (devices?.length || 0) ? {
                    label: 'general.text.solve_issues',
                    onClick: () => {

                      redirect('/help/hardware/er-is-een-knop-offline-wat-moet-ik-doen');

                    },
                  } : undefined}
                  showButton={onlineDevices < (devices?.length || 0) ? {
                    label: 'resources.buttons.text.show_buttons',
                    onClick: () => {

                      redirect('/buttons');

                    },
                  } : undefined}
                  label={translate('general.text.online')}
                  icon={SignalCellularAlt} />
              </Grid>
              <Grid xs={12} sm={6} md={6}>
                <CounterCard
                  count={(devices?.length || 0) - (emptyDevices || 0)}
                  total={devices?.length || 0}
                  warning={emptyDevices > 0}
                  actionButton={emptyDevices > 0 ? {
                    label: 'general.text.solve_issues',
                    onClick: () => {

                      redirect('/help/hardware/er-is-een-knop-offline-wat-moet-ik-doen');

                    },
                  } : undefined}
                  showButton={emptyDevices > 0 ? {
                    label: 'resources.buttons.text.show_buttons',
                    onClick: () => {

                      redirect('/buttons');

                    },
                  } : undefined}

                  label={translate('resources.buttons.text.sufficient_battery')}
                  icon={emptyDevices > 0 ? BatteryCharging20 : BatteryChargingFull} />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      <Grid xs={12} md={5}>
        <Card style={{ height: '100%', display: 'flex', flexDirection: 'column' }} color={'secondary'}>
          <CardContent style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Box>
                <h4 style={{ marginTop: 0 }}>{translate('general.text.gateways')}</h4>
              </Box>
              <Box>
                <Button
                  variant={'contained'}
                  color={'secondary'}
                  size={'large'}
                  label="general.text.order_singular"
                  onClick={() => {

                    redirect('/bestellen');

                  }}
                />
              </Box>
            </Box>
            <Grid container spacing={2} style={{ marginTop: '1em', flexGrow: 1 }}>
              <Grid xs={12}>
                {gatewayCount !== null && onlineGateways !== null && (
                  <CounterCard
                    count={onlineGateways}
                    total={gatewayCount}
                    warning={onlineGateways < gatewayCount}
                    actionButton={onlineGateways < gatewayCount ? {
                      label: 'general.text.solve_issues',
                      onClick: () => {

                        redirect('/help/hardware/er-is-een-knop-offline-wat-moet-ik-doen');

                      },
                    } : undefined}
                    showButton={onlineGateways < gatewayCount ? {
                      label: 'homepage.text.show_gateways',
                      onClick: () => {

                        redirect('/buttons');

                      },
                    } : undefined}
                    label={translate('general.text.online')}
                    icon={SignalCellularAlt} />
                )}
                {(gatewayCount === null || onlineGateways === null) && (
                  <Card color={'white'} style={{ height: '100%' }} elevation={2}>
                    <CardContent sx={{ height: '100%' }}>
                      <Box sx={{
                        display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%',
                      }}>
                        <CircularProgress/>
                      </Box>
                    </CardContent>
                  </Card>
                )}
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      {(zoneOverviewListResult.data?.length || 0) > 0 && (
        <Grid xs={12}>
          <Card style={{ height: '100%', display: 'flex', flexDirection: 'column' }} color={'secondary'}>
            <CardContent>
              <h4 style={{
                marginBottom: '1em',
              }}>{translate('resources.reports.text.bhvk_title')}</h4>
              <ZoneOverview disableAdd/>
            </CardContent>
          </Card>
        </Grid>
      )}
      <Grid xs={12}>
        <Card color={'secondary'}>
          <CardContent>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Box>
                <h4 style={{ marginTop: 0 }}>{translate('menu.items.alarms')} <small>{translate('general.text.last_days', { count: 7 })}</small></h4>
              </Box>
              <Box>
                {alarmCount > 0 && (
                  <Button
                    variant={'contained'}
                    color={'primary'}
                    size={'small'}
                    label={translate('resources.alarms.text.view_alarms')}
                    onClick={() => redirect('/alarms?page=1')}
                    sx={{ marginRight: '1em' }}
                  />
                )}
                <Button
                  variant={'contained'}
                  color={'secondary'}
                  size={'small'}
                  label="menu.items.create_alarm"
                  onClick={() => redirect('/maak-alarm')}
                />
              </Box>
            </Box>
            <Card color={'white'} style={{ height: '100%', marginTop: '1em' }} elevation={2}>
              <CardContent>
                <AlarmListSmall
                  filter={{
                    createdAt: `>${new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)}`,
                  }}
                  empty={<div>
                    <p>{translate('resources.alarms.text.no_alarms')}</p>
                    <Button
                      variant={'contained'}
                      color={'secondary'}
                      size={'small'}
                      label="menu.items.create_alarm"
                      onClick={() => redirect('/maak-alarm')}
                    />
                  </div>}
                />
              </CardContent>
            </Card>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  </>;

};
