import { BooleanField, useRecordContext } from 'react-admin';
import FalseIcon from '../icons/FalseIcon';
import TrueIcon from '../icons/TrueIcon';

const ActiveBooleanField = ({ source, ...props }: any) => {

  const record = useRecordContext();
  if (!record[source]) return <BooleanField source="active" record={{ active: false }} FalseIcon={FalseIcon} {...props}/>;
  const customRecord = { status: (record[source] === 'active' || record[source].entries.length > 0 || false) };
  return <BooleanField source="status" record={customRecord} TrueIcon={TrueIcon} FalseIcon={FalseIcon} {...props}/>;

};

export default ActiveBooleanField;
