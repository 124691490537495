import {
  Dispatch, SetStateAction, SyntheticEvent, useEffect, useState,
} from 'react';
import _ from 'lodash';
import {
  Autocomplete as MuiAutocomplete,
  TextField as MuiTextField, Typography,
} from '@mui/material';
import * as React from 'react';
import { useTranslate } from 'react-admin';
import { qrCodeDataProvider } from '../dataProviders/qrCodeDataProvider';
import { getCurrentCustomer } from '../lib/currentCustomer';

const useGetPossibleTemplates = () => {

  const [templates, setTemplates] = useState<{ id: string; label: string; code: string; hasEmailToCreatedAssetAction: boolean }[]>([]);
  const [lastCustomer, setLastCustomer] = useState<string | null>(null);
  const currentCustomer = getCurrentCustomer()?.value;

  useEffect(() => {

    if (currentCustomer && lastCustomer !== currentCustomer) {

      setLastCustomer(currentCustomer);
      qrCodeDataProvider.getList('lite-templates', {
        pagination: { page: 1, perPage: 100 },
        filter: {},
        sort: { field: 'createdAt', order: 'ASC' },
      }).then((templateResponse) => {

        const filteredTemplates = templateResponse.data.filter((template: any) => {

          return template.code?.code !== undefined;

        });
        setTemplates(filteredTemplates.map((template: any) => {

          return {
            id: template.id, label: template.name, code: template.code.code, hasEmailToCreatedAssetAction: template.hasEmailToCreatedAssetAction,
          };

        }));

      });

    }

  }, [currentCustomer]); // eslint-disable-line react-hooks/exhaustive-deps

  return templates;

};

export const RegistrationCodePicker = (props: { setCode: Dispatch<SetStateAction<string | null>> }) => {

  const translate = useTranslate();
  const templates = useGetPossibleTemplates();
  const [pickedTemplate, setPickedTemplate] = useState<{ id: string; label: string; code: string; hasEmailToCreatedAssetAction: boolean } | null>(null);
  const currentCustomer = getCurrentCustomer()?.value;
  const [lastCustomer, setLastCustomer] = useState<string | null>(null);
  const storedDefaultTemplate = localStorage.getItem(`${currentCustomer}_defaultTemplate`);

  useEffect(() => {

    if (currentCustomer && currentCustomer !== lastCustomer) {

      setLastCustomer(currentCustomer);
      setPickedTemplate(null);

    }

  }, [currentCustomer]); // eslint-disable-line react-hooks/exhaustive-deps

  if (storedDefaultTemplate && !pickedTemplate) {

    const storedDefaultTemplateObject = JSON.parse(storedDefaultTemplate);
    const foundTemplate = _.find(templates, { id: storedDefaultTemplateObject.id });
    if (foundTemplate) {

      props.setCode(foundTemplate.code);
      setPickedTemplate(foundTemplate);
      return null;

    }

  }

  const handleChangeTemplate = (event: SyntheticEvent, value: { id: string; label: string; code: string; hasEmailToCreatedAssetAction: boolean } | null) => {

    if (value) {

      props.setCode(value.code);
      setPickedTemplate(value);
      localStorage.setItem(`${currentCustomer}_defaultTemplate`, JSON.stringify(value));

    }

  };
  if (templates?.length === 1) {

    props.setCode(templates[0].code);
    return null;

  }

  if (templates.length === 0) {

    return null;

  }

  return (
    <>
      <Typography variant="h6" gutterBottom>
        {translate('general.fields.registration_profile')}
      </Typography>
      <MuiAutocomplete
        renderInput={(params) => <MuiTextField {...params}
          label={translate('general.fields.registration_profile')}
          helperText={pickedTemplate?.hasEmailToCreatedAssetAction ? <small>{translate('resources.app-users.text.warning_sends_email')}</small> : null}
          FormHelperTextProps={{
            sx: {
              color: 'error.main',
            },
          }}
        />}
        options={templates}
        value={pickedTemplate}
        sx={{
          paddingBottom: '19px',
        }}
        fullWidth
        size="small"
        onChange={handleChangeTemplate}
      />
    </>
  );

};
