const useNodeEnv = (() => {

  try {

    return typeof process.env !== 'undefined';

  } catch (e) {

    return false;

  }

})();

const env = <T extends string | boolean = string>(key: string, defaultValue?: T): T => {

  const value = (useNodeEnv ? process.env : import.meta.env)[key] ?? defaultValue;

  if (typeof value === 'undefined') {

    throw new Error(`env key: ${key} is undefined. Make sure it's defined`);

  }

  return value as unknown as T;

};

export const BUILD_REF_NAME: string = env('VITE_XGAC_REF_NAME', '');
export const BUILD_COMMIT_SHA: string = env('VITE_XGAC_COMMIT_SHA', '');

export const XGAC_MAIN_API_URL = env('VITE_XGAC_MAIN_API_URL');
export const XGAC_REPORT_API_URL = env('VITE_XGAC_REPORT_API_URL');
export const DEVICE_REGISTRATION_API_URL = env('VITE_DEVICE_REGISTRATION_API_URL');

export const XGAC_LORA_API_URL = env('VITE_XGAC_LORA_API_URL');
export const ATS_API_URL = env('VITE_ATS_API_URL');

export const XGAC_WS_API_URL = env('VITE_XGAC_WS_API_URL');
export const XGAC_AUTH_API_URL = env('VITE_XGAC_AUTH_API_URL');
export const XGAC_AUTH_CLIENT_ID = env('VITE_XGAC_AUTH_CLIENT_ID');

export const XGAC_KIOSK_URL = env('VITE_XGAC_KIOSK_URL');

export const USERSNAP_GLOBAL_API_KEY = env('VITE_USERSNAP_GLOBAL_API_KEY');

export const XGAC_ALARM_MANAGER_BASEURL = env('VITE_XGAC_ALARM_MANAGER_BASEURL');

export const ATS_CUSTOMER_LINK_RED_ORANGE_WITHOUT_ALARMCENTER_TEMPLATE_ID = env('VITE_ATS_CUSTOMER_LINK_RED_ORANGE_WITHOUT_ALARMCENTER_TEMPLATE_ID');
export const ATS_CUSTOMER_LINK_RED_ORANGE_WITH_ALARMCENTER_TEMPLATE_ID = env('VITE_ATS_CUSTOMER_LINK_RED_ORANGE_WITH_ALARMCENTER_TEMPLATE_ID');
export const XGAC_ATS_CUSTOMER_LINK_GREEN_TEMPLATE_ID = env('VITE_ATS_CUSTOMER_LINK_GREEN_TEMPLATE_ID');
export const ATS_CUSTOMER_LINK_ALL_TEMPLATE_ID = env('VITE_ATS_CUSTOMER_LINK_ALL_TEMPLATE_ID');
export const XGAC_SENTRY_DSN = env('VITE_XGAC_SENTRY_DSN');
export const XGAC_OTHER_DOMAIN = env('VITE_XGAC_OTHER_DOMAIN');

export const LIST_DEBOUNCE = 1250;

export const VITE_XGAC_DASHBOARD_MODE: string = env('VITE_XGAC_DASHBOARD_MODE', 'default');

export const XGAC_MAPS_KEY = env('VITE_XGAC_MAPS_KEY');

export const PDOK_URL = 'https://service.pdok.nl/lv/bag/wfs/v2_0?request=GetFeature&service=WFS&typeName=bag:pand&version=2.0.0&count=1000&outputFormat=json&srsName=EPSG:4326';
