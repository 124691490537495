import { Typography } from '@mui/material';
import {
  ReferenceInput,
  SimpleForm,
  TextInput,
  required,
  BooleanInput,
  AutocompleteInput,
  Datagrid,
  BooleanField,
  TextField,
  List,
  useRecordContext,
  ChipField,
  Button,
  useCreatePath,
  Link,
  DateField,
  SaveButton,
  Toolbar,
  ReferenceField,
  useTranslate,
  CreateButton,
  TopToolbar,
} from 'react-admin';
import { get } from 'lodash';
import * as React from 'react';
import EmergencyShareIcon from '@mui/icons-material/EmergencyShare';
import { ListLive } from '@react-admin/ra-realtime';
import { CreateWithSuccess } from '../../../components/baseForms/CreateWithSuccess';
import { XGAC_ALARM_MANAGER_BASEURL } from '../../../config';
import TextFieldNullable from '../../../components/fields/TextFieldNullable';
import DateFieldNullable from '../../../components/fields/DateFieldNullable';
import { alarmSeverities, alarmTypes } from '../../../lib/constants/selectChoices';
import authProvider from '../../../utils/authProvider';
import { PageExplanation } from '../../../components/PageExplanation';
import { CustomBulkExportButton, FullExportButton } from '../../../utils/customExporter';
import { HasRoles } from '../../../components/HasRoles';
import { MassAcknowledgmentButton } from '../components/buttons/MassAcknowledgmentButton';
import { getAlarmColor } from '../../../utils/getAlarmColor';
import { getCurrentCustomer } from '../../../lib/currentCustomer';

const transform = (data: Record<string, any>) => {

  if (data.ack === undefined) data.ack = true;
  if (!data.type) {

    switch (data.severity) {

    case 'red':
      data.type = 'panic';
      break;
    case 'orange':
      data.type = 'assistance';
      break;
    case 'green':
      data.type = 'test';
      break;
    default:
      data.type = 'panic';

    }

  }

  const transformed: any = {
    ...data,
    asset: { _ref: 'Asset', _id: data.asset._id },
    ack: { value: data.ack !== true, needed: data.ack || true },
  };

  // save in local storage for next time
  localStorage.setItem('prefill-create-alarm', JSON.stringify(data));
  localStorage.setItem(`prefill-create-alarm-asset-${getCurrentCustomer()?.value}`, data.asset._id);

  return transformed;

};

const AlarmCreateToolbar = (props: React.PropsWithChildren) => <Toolbar {...props}>
  <SaveButton icon={<EmergencyShareIcon/>} label='resources.alarms.create_title' alwaysEnable={true} />
</Toolbar>;

export const AlarmCreate = () => {

  const translate = useTranslate();
  const currentCustomer = getCurrentCustomer();

  // find previously stored
  let defaults: any = {
    name: 'Panic',
    severity: 'red',
    procedure: '',
    ack: true,
    type: 'panic',
  };

  try {

    // get defaults from local storage
    const previousData = JSON.parse(localStorage.getItem('prefill-create-alarm') || '{}');
    const previousAsset = localStorage.getItem(`prefill-create-alarm-asset-${currentCustomer?.value}`);
    if (Object.keys(previousData).length) {

      // combine defaults
      defaults = {
        ...defaults,
        ...previousData,
      };

      // check asset
      if (previousAsset) {

        defaults = {
          ...defaults,
          asset: previousAsset,
        };

      } else {

        delete defaults.asset;

      }

    }

  } catch (e) {

    console.log('Could not prase previous data');

  }

  return (
    <>
      <CreateWithSuccess transform={transform} title={translate('resources.alarms.create_title')}>
        <SimpleForm toolbar={<AlarmCreateToolbar />}>
          <Typography variant="h6">{translate('resources.alarms.text.alarm_creator')}</Typography>
          <p style={{ width: '50%', minWidth: '150px' }}>{translate('resources.alarms.text.alarm_creator_explanation')}</p>
          <ReferenceInput source="asset._id" reference="all-assets" sort={{ field: 'name', order: 'ASC' }}>
            <AutocompleteInput label="general.fields.person_or_object" defaultValue={defaults.asset} validate={required()} />
          </ReferenceInput>

          <Typography variant="h6">{translate('resources.alarms.text.alarm_information')}</Typography>
          <p style={{ width: '50%', minWidth: '150px' }}>{translate('resources.alarms.text.alarm_information_explanation')}</p>
          <TextInput source="name" defaultValue={defaults.name} validate={required()} label="general.fields.name"/>
          <AutocompleteInput source={'severity'} defaultValue={defaults.severity} validate={required()} choices={alarmSeverities}/>
          <HasRoles anyOf={['developer_admin']}>
            <AutocompleteInput source={'type'} defaultValue={defaults.type} validate={required()} choices={alarmTypes} shouldUnregister/>
          </HasRoles>
          <TextInput
            source="procedure"
            validate={[required()]}
            defaultValue={defaults.procedure}
            placeholder={translate('resources.alarms.text.procedure_placeholder')}
            multiline />
          <HasRoles anyOf={['developer_admin']}>
            <Typography variant="h6">{translate('resources.alarms.text.ack_definition')}</Typography>
            <p style={{ width: '50%', minWidth: '150px' }}>
              {translate('resources.alarms.text.ack_explanation')}
            </p>
            <BooleanInput source="ack" defaultValue={defaults.ack} label="resources.alarms.text.ack.value" shouldUnregister/>
          </HasRoles>
        </SimpleForm>
      </CreateWithSuccess>
    </>
  );

};

const AcknowledgmentField = (props: any) => {

  const translate = useTranslate();
  const record = useRecordContext();
  const ackValue = get(record, 'ack');
  let label = translate('resources.alarms.text.ack.no_time');

  if (!ackValue.value) {

    label = translate('resources.alarms.text.ack.open_alarm');

  }
  if (!ackValue.needed) {

    label = translate('resources.alarms.text.ack.not_needed');

  }

  // const timeBetween = moment(ackValue.ackedAt).locale(locale[0]).from(moment(record.createdAt), true);

  return <span style={{ whiteSpace: 'nowrap' }}>
    <BooleanField
      {...props}
      valueLabelFalse={'resources.alarms.text.ack.open_alarm'}
      valueLabelTrue={'resources.alarms.text.ack.acked'}
      color={record.ack.value !== true ? '#d0382a' : 'black'}
      source="ack.value"
      sx={{ verticalAlign: 'text-bottom' }}/>
    <DateFieldNullable label={label} source="ack.ackedAt" showTime={true} neverText="general.text.no_time_yet"/>
  </span>;

};

const AlarmSeverityField = (props: any) => {

  const record = useRecordContext();
  const severity = get(record, props.source);
  const translate = useTranslate();

  const color = getAlarmColor(severity);

  return <ChipField {...props}
    source="value"
    record={{ value: translate(`resources.customer-links.fields.level.${severity}`) }}
    sx={{ backgroundColor: color, color: 'white', padding: 0 }}
  />;

};

const AMButton = () => {

  const record = useRecordContext();
  const link = `${XGAC_ALARM_MANAGER_BASEURL}/alarms/${record._id}?customerId=${record.customer._id}`;
  return <Button onClick={() => window.open(link, '_blank')} label='general.text.open_in_am' variant={'contained'}/>;

};

const AssetFieldClickable = () => {

  const record = useRecordContext();
  const createPath = useCreatePath();
  const roles = authProvider.getRoles();

  if (!record.asset) return <TextFieldNullable/>;
  if (roles.includes('admin') || roles.includes('developer_admin')) {

    return <Link
      to={createPath({ resource: 'app-users', type: 'edit', id: record.asset._id })}>{record.asset.name}</Link>;

  }
  return <TextField source="asset.name" />;

};

const TypeField = (props: any) => {

  const translate = useTranslate();
  const record = useRecordContext();
  const type = get(record, props.source);

  return <TextField {...props} source="type" record={{ type: translate(`resources.alarms.text.type_options.${type}`) }}/>;

};

const getRowStyle = (record: any) => {

  if (record.ack.value === true) {

    return {};

  }

  return {
    backgroundColor: '#fce5e3',
  };

};

const AlarmListActions = () => {

  return (
    <TopToolbar>
      <CreateButton sx={{
        marginRight: '20px',
      }}/>
      <FullExportButton resource="alarms"/>
      <HasRoles anyOf={['admin']}>
        <MassAcknowledgmentButton allAlarms={true}/>
      </HasRoles>
    </TopToolbar>
  );

};

const AlarmBulkActionButtons = () => {

  return (
    <>
      <CustomBulkExportButton/>
      <HasRoles anyOf={['admin']}>
        <MassAcknowledgmentButton allAlarms={false}/>
      </HasRoles>
    </>
  );

};

export const AlarmList = () => {

  return (
    <>
      <PageExplanation text="resources.alarms.text.page_info" />
      <ListLive sort={{ field: 'createdAt', order: 'DESC' }}
        queryOptions={{
          meta: {
            $select: 'createdAt,customer,ack,severity,name,type,alarmCenter,asset',
          },
        }}
        actions={<AlarmListActions/>}>
        <Datagrid bulkActionButtons={<AlarmBulkActionButtons/>}
          rowStyle={getRowStyle}>
          <DateField source="createdAt" showTime={true} label="general.fields.createdAt"/>
          <AcknowledgmentField label="resources.alarms.fields.ack.value"/>
          <TextField source="name" label="general.fields.name" />
          <AlarmSeverityField source="severity" />
          <AssetFieldClickable />
          <TypeField source="type" />
          <TextFieldNullable source="alarmCenter.name" />
          <AMButton />
        </Datagrid>
      </ListLive>
    </>
  );

};

export const OpenAlarmList = () => {

  return (
    <>
      <List sort={{ field: 'createdAt', order: 'DESC' }}
        actions={<TopToolbar><CreateButton/><FullExportButton resource="alarms"/></TopToolbar> }>
        <Datagrid bulkActionButtons={false} rowStyle={getRowStyle}>
          <ReferenceField reference='customers' source="customer._id"/>
          <DateField source="createdAt" showTime={true} label="general.fields.createdAt"/>
          <AcknowledgmentField label="resources.alarms.fields.ack.value"/>
          <TextField source="name" label="general.fields.name"/>
          <AlarmSeverityField source="severity"/>
          <AssetFieldClickable/>
          <TextField source="type" sx={{ textTransform: 'capitalize' }}/>
          <TextFieldNullable source="alarmCenter.name"/>
          <AMButton/>
        </Datagrid>
      </List>
    </>
  );

};
