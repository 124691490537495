import {
  TextField,
  SimpleForm,
  TextInput,
  NumberInput,
  EditButton,
  SelectInput,
  required,
  SearchInput,
  ArrayInput,
  SimpleFormIterator,
  ReferenceInput,
  AutocompleteInput,
  useGetRecordId,
  NumberField,
  useTranslate,
  useNotify,
  useRedirect,
  useCreatePath,
  useRefresh,
  BooleanInput,
  ListActions,
  useRecordContext,
  Identifier,
  useExpanded,
  Form,
  Button,
  Datagrid,
  Create,
  Confirm,
  useGetIdentity,
} from 'react-admin';
import {
  Edit, IfCanAccess,
} from '@react-admin/ra-rbac';
import * as React from 'react';
import {
  Card,
  CardContent,
  Chip,
  Dialog,
  DialogTitle,
  IconButton,
  Tooltip,
  Typography,
  TextField as MuiTextField,
  InputAdornment,
  SvgIcon,
  DialogContent,
  DialogActions,
} from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import L, {
  latLng,
  LatLng, Layer, Map, Point,
} from 'leaflet';
import { ListLive } from '@react-admin/ra-realtime';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import Grid2 from '@mui/material/Unstable_Grid2';
import uniqolor from 'uniqolor';
import EditIcon from '@mui/icons-material/Edit';
import {
  AssetRef,
  Beacon,
  PointGeometry,
  Zone,
} from '@x-guard/xgac-types/xgac';
import CheckIcon from '@mui/icons-material/Check';
import Battery1BarIcon from '@mui/icons-material/Battery1Bar';
import SignalCellularOffIcon from '@mui/icons-material/SignalCellularOff';
import CloseIcon from '@mui/icons-material/Close';
import * as turf from '@turf/turf';
import { useDebounce } from 'use-debounce';
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import GoogleIcon from '@mui/icons-material/Google';
import MapIcon from '@mui/icons-material/Map';
import _ from 'lodash';
import PinDropOutlinedIcon from '@mui/icons-material/PinDropOutlined';
import { Feature } from '@turf/turf';
import CircularProgress from '@mui/material/CircularProgress';
import { useGetList } from 'ra-core';
import { CustomToolbar } from '../../../components/CustomToolBar';
import LengthField from '../../../components/fields/LengthField';
import CustomBulkActionButtons from '../../../components/buttons/CustomBulkActionButtons';
import { CustomExportButton, FullExportButton } from '../../../utils/customExporter';
import TextFieldNullable from '../../../components/fields/TextFieldNullable';
import NumberFieldNullable from '../../../components/fields/NumberFieldNullable';
import { CreateWithSuccess } from '../../../components/baseForms/CreateWithSuccess';
import { EditWithSuccess } from '../../../components/baseForms/EditWithSuccess';
import { LIST_DEBOUNCE } from '../../../config';
import { StabilityAlert, StabilityLevel } from '../../../components/StabilityAlert';
import { xgacDataProvider } from '../../../dataProviders/xgacDataProvider';
import {
  MultipleZonesMap, uniqColorOptionsForZones, polygonZoneEditOptions, ZoneMap, markerZoneEditOptions,
} from '../../../components/maps/zoneMap';
import { EmptyHeader } from '../../bhvk/entities/button';
import { AssetTableInDialog } from '../../../components/AssetTableInDialog';
import { BeaconHealth, KioskUser, ZoneWithBeacons } from '../../../lib/constants/customTypes';
import { reportDataProvider } from '../../../dataProviders/reportDataProvider';
import authProvider from '../../../utils/authProvider';
import { CustomConfirmDialog } from '../../../components/CustomConfirmDialog';
import { wait } from '../../../lib/websockets/webSocketHelpers';
import { getCurrentCustomer } from '../../../lib/currentCustomer';
import { GoogleCoordinatesInput } from '../../bhvk/components/inputs/GoogleCoordinatesInput';
import { reverseCoordinates } from '../../../utils/reverseGeoCode';
import { HasRoles } from '../../../components/HasRoles';
import SimpleChipListField from '../../../components/fields/SimpleChipListField';
import { useOverlay } from '../../../utils/useOverlay';
import { RaIntegerInput } from '../../../components/inputs/raIntegerInput';
import { RoomCalculation } from '../../../components/roomCalculation';
import { DateFieldWithTime } from '../../../components/fields/DateFieldWithTime';
import { useLinksTriggersAndKiosks } from '../../../components/buttons/assetGroupDeleteButton';

const Grid = Grid2;

const transform = (data: Record<string, any>, mapData: { location: any }) => {

  return {
    ...data,
    description: data.description || null,
    location: {
      ...mapData.location,
      properties: {
        ...data.location.properties,
        address: {
          ...data.location.properties.address,
          formattedAddress: data.location.properties.address.formattedAddress,
        },
      },
    },
    staticResponders: data.staticResponders?.map((responder:any) => (
      {
        ...responder,
        staticResponder: { _id: responder.staticResponder, _ref: 'StaticResponder' },
        priority: responder.priority || null,
      }
    )),
  };

};

const validateZone = (layers: Layer[] | undefined) => {

  if (layers?.length !== 1) {

    return 'resources.zones.text.invalid_zone';

  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore types geoman zijn niet te importeren
  if (layers[0]?.pm.hasSelfIntersection()) {

    return 'resources.zones.text.invalid_zone_intersection';

  }

  return null;

};

const zoneTypes = [
  { name: 'resources.zones.text.zoneTypes.default', id: 'default' },
  { name: 'resources.zones.text.zoneTypes.response_area', id: 'responseArea' },
  { name: 'resources.zones.text.zoneTypes.police_department', id: 'policeDepartment' },
  { name: 'resources.zones.text.zoneTypes.fingerprinted', id: 'fingerprinted' },
  { name: 'resources.zones.text.zoneTypes.assetMonitoredArea', id: 'assetMonitoredArea' },
];
const QuickFilter = (props: { label: string; defaultValue: any; source: string }) => {

  const translate = useTranslate();
  return <Chip sx={{ marginBottom: 1 }} label={translate(props.label)} />;

};

const zoneFilters = [
  <SearchInput source="q" alwaysOn sx={{
    '& .MuiFilledInput-input': {
      height: '31px',
    },
    width: '100%',
  }}/>,
  <SelectInput source="type" choices={zoneTypes} alwaysOn label="resources.zones.filters.only_show"/>,
  <QuickFilter source="syncToAms" label="resources.zones.fields.syncToAms" defaultValue={true}/>,
];

const BeaconCounterField = (props: any) => {

  const [beacons, setBeacons] = useState<Beacon[] | null>(null);
  const [beaconTotal, setBeaconTotal] = useState<number>(0);
  const recordId = useGetRecordId();
  useEffect(() => {

    if (!recordId) {

      return;

    }
    const getBeaconCount = async () => {

      const beaconCountRequest = await xgacDataProvider.getBeaconsForZone(recordId);
      if (beaconCountRequest) {

        setBeacons(beaconCountRequest.result || []);
        setBeaconTotal(beaconCountRequest.total);

      }

    };
    getBeaconCount();

  }, [recordId]);
  if (beaconTotal > 1) {

    return <NumberField {...props} source="count" record={{ count: beaconTotal }} label="resources.beacons.text.title"/>;

  } if (beaconTotal === 1 && beacons?.[0]) {

    return <SimpleChipListField passedRecord={beacons[0]} source="codes"/>;

  }

  return <TextFieldNullable/>;

};

const ZoneColorById = (props: { size: number; withDot?: boolean; style?: any }) => {

  const record = useRecordContext();
  if (!record._id) {

    return null;

  }
  const color = uniqolor(
    record._id,
    uniqColorOptionsForZones,
  );
  return (
    <div style={{
      height: props.size,
      width: props.size,
      borderRadius: '5px',
      backgroundColor: color.color,
      ...props.style,
    }}>
      {props.withDot && (
        <div style={{
          height: props.size / 3,
          width: props.size / 3,
          backgroundColor: 'white',
          borderRadius: '50%',
          position: 'relative',
          top: '50%',
          left: '50%',
          msTransform: 'translate(-50%, -50%)',
          transform: 'translate(-50%, -50%)',
        }}></div>
      ) }
    </div>
  );

};
export const ZoneSideBar = (props: {
  expandedId: Identifier;
  toggleItemExpansion: (id: Identifier) => void;
  createMode: false | 'polygon' | 'marker';
  formEdited: boolean;
  startCreate: () => void;
  setSearchFieldValue: (value: string) => void;
  searchFieldValue: string;
  setSearchFocussed: (value: boolean) => void;
  searchFocussed: boolean;
  loadedZones: Array<ZoneWithBeacons>;
  isLoaded: boolean;
  ZoneCreateForm: any;
  ZoneEditForm: any;
  setSelectedFloors: (floors: number[]) => void;
  map: Map | null;
}) => {

  const {
    expandedId,
    toggleItemExpansion,
    createMode,
    startCreate,
    setSearchFieldValue,
    searchFieldValue,
    setSearchFocussed,
    searchFocussed,
    loadedZones,
    isLoaded,
    ZoneCreateForm,
    ZoneEditForm,
    formEdited,
    setSelectedFloors,
    map,
  } = props;

  const [beaconHealth, setBeaconHealth] = useState<Array<BeaconHealth>>([]);
  const [searchInput, setSearchInput] = useState<any>(null);

  const { links, triggers } = useLinksTriggersAndKiosks();
  const kiosks = useGetList('specials/kiosk-user', {
    pagination: { page: 1, perPage: -1 },
    sort: { field: 'name', order: 'ASC' },
    filter: {},
  });
  const translate = useTranslate();

  useEffect(() => {

    const getReport = async () => {

      const report = await reportDataProvider.getUnhealthyBeacons({ value: 7, type: 'day' });
      const beaconHealthArray: Array<BeaconHealth> = [];
      report.value.lowBattery.forEach((beacon: Beacon) => {

        beaconHealthArray.push({ ...beacon, healthy: 'lowBattery' });

      });
      report.value.inactive.forEach((beacon: Beacon) => {

        beaconHealthArray.push({ ...beacon, healthy: 'inactive' });

      });
      setBeaconHealth(beaconHealthArray);

    };
    getReport();

  }, []);

  const resetSearch = () => {

    searchInput.blur();
    setSearchFocussed(false);
    searchInput.value = '';
    setSearchFieldValue('');

  };

  return (
    <div className="map-sidebar-div">
      <div className="map-sidebar-header">
        <Typography variant="h6" fontWeight="bold">{translate('resources.zones.text.manage')}</Typography>
        <div className="flex-in-between" style={{ marginTop: '10px' }}>
          {createMode !== false || formEdited ? (
            <>
              <Tooltip title={translate('resources.zones.text.edit_warning')}>
                <span>
                  <Button
                    variant="contained"
                    disabled
                    label="ra.action.create"
                    startIcon={<AddIcon/>}
                    onClick={startCreate}
                    sx={{ marginRight: '10px', borderRadius: '5px', fontWeight: 'bolder' }}
                  />
                </span>
              </Tooltip>
              <Tooltip title={translate('resources.zones.text.edit_warning')}>
                <span>
                  <CustomExportButton resource={'zonesWithBeacons'} getRecords={() => {

                    return loadedZones;

                  }} progress={loadedZones.length}
                  disabled
                  total={loadedZones.length}
                  buttonLabel="ra.action.export"
                  variant={'contained'}
                  sx={{ borderRadius: '5px', fontWeight: 'bolder' }}
                  />
                </span>
              </Tooltip>
            </>
          ) : (
            <> <Button
              variant="contained"
              label="ra.action.create"
              startIcon={<AddIcon/>}
              onClick={startCreate}
              sx={{ marginRight: '10px', borderRadius: '5px', fontWeight: 'bolder' }}
            />
            {authProvider.isAdmin() && (
              <RoomCalculation zones={loadedZones} map={map} setSelectedFloors={setSelectedFloors}/>
            )}
            <CustomExportButton resource={'zonesWithBeacons'} getRecords={() => {

              return loadedZones;

            }} progress={loadedZones.length}
            total={loadedZones.length}
            buttonLabel="ra.action.export"
            variant={'contained'}
            sx={{ borderRadius: '5px', fontWeight: 'bolder' }}
            />
            </>
          )}
        </div>
        <MuiTextField
          label={false}
          variant="outlined"
          size="small"
          inputRef={(ref) => setSearchInput(ref)}
          autoFocus={searchFocussed}
          InputProps={{
            startAdornment: (<InputAdornment position={'start'}><SearchIcon/></InputAdornment>),
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={(e) => {

                  e.stopPropagation();
                  resetSearch();

                }} disabled={searchFieldValue.length === 0}>
                  <CloseIcon/>
                </IconButton>
              </InputAdornment>
            ),
          }}
          placeholder={translate('general.text.filter')}
          value={searchFieldValue}
          onChange={(event) => setSearchFieldValue(event.target.value)}
          onBlur={() => setSearchFocussed(false)}
          onClick={() => setSearchFocussed(true)}
          sx={{
            '& .MuiOutlinedInput-root': {
              borderRadius: '5px',
              width: '100%',
              backgroundColor: '#F2F2F2',
            },
            '& .MuiOutlinedInput-notchedOutline': {
              border: 'none',
            },
            width: '100%',
          }}/>
      </div>
      <div className="map-sidebar-grid">
        <ZoneCreateForm/>
        <Datagrid
          className={'map-sidebar-datagrid'}
          resource="zones"
          data={loadedZones}
          expandSingle={true}
          bulkActionButtons={false}
          selectedIds={[]}
          empty={isLoaded ? <div></div> : (
            <div style={{
              height: '100%', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center',
            }}>
              <CircularProgress/>
            </div>
          )}
          header={<EmptyHeader/>}
          expand={<ZoneEditForm beaconHealth={beaconHealth} links={links} triggers={triggers} kiosks={kiosks.data || []}/>}
          rowClick={'toggleSelection'}
          onToggleItem={(id) => toggleItemExpansion(id)}
          rowStyle={(record) => ({
            display: record._id === expandedId ? 'none' : undefined,
            borderBottom: '1px solid darkgray',
            padding: '0px 5px',
          })}
          cellPadding={0}
          cellSpacing={0}
        >
          <Grid container spacing={0} sx={{ paddingTop: '10px', paddingBottom: '10px' }}>
            <Grid xs={1.5} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'start' }}>
              <ZoneColorById size={20}/>
            </Grid>
            <Grid xs={10.5}>
              <div className="flex-in-between">
                <TextField source="name" label="general.fields.name" sx={{
                  display: 'flex', alignItems: 'center', justifyContent: 'center', fontWeight: 'bolder',
                }}/>
                <IconButton size="small" disabled={createMode !== false}>
                  <EditIcon spacing={0}/>
                </IconButton>
              </div>
            </Grid>
          </Grid>
        </Datagrid>
      </div>
    </div>
  );

};

export const ZoneList = () => (
  <>
    <StabilityAlert stability={StabilityLevel.Stable}/>
    <ListLive
      filters={zoneFilters}
      debounce={LIST_DEBOUNCE}
      actions={<><ListActions exporter={false}/><FullExportButton resource="zones"/></>}
      title="resources.zones.text.title"
      queryOptions={{
        meta: { includeFingerprints: true },
      }}
    >
      <Datagrid rowClick="toggleSelection" bulkActionButtons={<CustomBulkActionButtons/>}>
        <TextField source="name" label="general.fields.name"/>
        <TextField source="type"/>
        <TextFieldNullable source="location.properties.address.formattedAddress" label="general.fields.position.address.name"/>
        <TextFieldNullable source="description"/>
        <LengthField source="staticResponders" label="resources.zones.fields.number_of_responders" sortable={false}/>
        <LengthField source="assetsInside" label="resources.zones.fields.number_of_assets" sortable={false}/>
        <NumberFieldNullable source="responsePriority"/>
        <BeaconCounterField label="resources.beacons.text.title"/>
        <NumberField source="fingerprintCount"/>
        <DateFieldWithTime source="createdAt" label="general.fields.createdAt" timeOnHover={true}/>
        <DateFieldWithTime source="updatedAt" label="general.fields.updatedAt" timeOnHover={true}/>
        <IfCanAccess action="edit">
          <EditButton/>
        </IfCanAccess>
      </Datagrid>
    </ListLive>
  </>
);

export const ZoneEdit = () => {

  const recordId = useGetRecordId();
  const notify = useNotify();
  const redirect = useRedirect();
  const createPath = useCreatePath();
  const refresh = useRefresh();

  const translate = useTranslate();

  const [map, setMap] = useState<Map | null>(null);
  const zoneEdit = async (data: Record<string, any>) => {

    const layers = map?.pm.getGeomanLayers();
    const zoneValidations = validateZone(layers);

    if (zoneValidations) {

      notify(translate(zoneValidations), { type: 'warning' });
      return;

    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore types geoman zijn niet te importeren
    let feature = layers[0].toGeoJSON();
    if (turf.area(feature.geometry) < 0.15) {

      const layerCoordinates = turf.circle(turf.center(feature.geometry), 10, {
        units: 'centimeters',
        steps: 3,
      });
      feature = { ...feature, geometry: layerCoordinates.geometry };

    }
    const zonePatch = {
      location: feature.geometry,
    };
    const previousDataRequest = await xgacDataProvider.getOne('zones', { id: recordId });
    const transformedData = transform(data, zonePatch);
    const updateResult = await xgacDataProvider.update('zones', { id: recordId, data: transformedData, previousData: previousDataRequest.data });
    if (updateResult) {

      notify(`${translate('resources.zones.text.title_single')} ${translate('general.text.updated')}`, { type: 'success' });
      refresh();
      redirect(createPath({ resource: 'zones', type: 'list' }));

    } else {

      notify(`${translate('resources.zones.text.title_single')} ${translate('general.text.invalid')}`, { type: 'warning' });

    }

  };

  return (
    <>
      <StabilityAlert stability={StabilityLevel.Beta}/>
      <EditWithSuccess title="resources.zones.text.title">
        <SimpleForm toolbar={<CustomToolbar alwaysEnable={true}/>} onSubmit={zoneEdit}>
          <TextInput source="name" validate={required()} label="general.fields.name"/>
          <TextInput source="description"/>
          <SelectInput source="type" choices={zoneTypes} defaultValue="default" validate={required()}/>
          <Typography variant="body1" sx={{ fontSize: '12px' }}>{translate('resources.zones.text.polygon_should_smaller')}</Typography>
          <ZoneMap source={'location'} setMap={setMap} map={map}/>
          <TextInput source="location.properties.address.formattedAddress" label="general.fields.position.address.name"/>
          <RaIntegerInput source="location.properties.address.floor" label="general.text.map_floors" style={{
            marginLeft: 0,
          }}/>
          <NumberInput source="responsePriority"/>
          <div className="flex-in-between">
            <BooleanInput source="syncToAms" fullWidth/>
            <Tooltip title={translate('resources.zones.text.syncToAms_explanation')}>
              <HelpOutlineIcon/>
            </Tooltip>
          </div>
          <ArrayInput source="staticResponders" label="resources.static-responders.text.title">
            <SimpleFormIterator disableReordering>
              <ReferenceInput name="static_responders" source="staticResponder" reference="static-responders">
                <AutocompleteInput validate={required()} autoHighlight={true} label="resources.assets.fields.staticResponders.staticResponder"/>
              </ReferenceInput>
              <NumberInput source="priority" label="resources.assets.fields.staticResponders.priority"/>
            </SimpleFormIterator>
          </ArrayInput>
        </SimpleForm>
      </EditWithSuccess>
    </>
  );

};

export const ZoneCreate = () => {

  const translate = useTranslate();
  const notify = useNotify();
  const redirect = useRedirect();
  const createPath = useCreatePath();
  const refresh = useRefresh();
  const [map, setMap] = useState<Map | null>(null);
  const zoneCreate = async (data: Record<string, any>) => {

    const layers = map?.pm.getGeomanLayers();

    const zoneValidations = validateZone(layers);

    if (zoneValidations) {

      notify(translate(zoneValidations), { type: 'warning' });
      return;

    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore types geoman zijn niet te importeren
    const feature = layers[0].toGeoJSON();
    const zonePatch = {
      location: feature.geometry,
    };
    const transformedData = transform(data, zonePatch);
    const createResult = await xgacDataProvider.create('zones', { data: transformedData });
    if (createResult) {

      notify(`${translate('resources.zones.text.title_single')} ${translate('general.text.saved').toLowerCase()}`, { type: 'success' });
      refresh();
      redirect(createPath({ resource: 'zones', type: 'list' }));

    } else {

      notify(`${translate('resources.zones.text.title_single')} ${translate('general.text.invalid')}`, { type: 'warning' });

    }

  };
  return (
    <>
      <StabilityAlert stability={StabilityLevel.Beta}/>
      <CreateWithSuccess title="Zones">
        <SimpleForm onSubmit={zoneCreate} >
          <TextInput source="name" validate={required()} label="general.fields.name"/>
          <TextInput source="description"/>
          <SelectInput source="type" choices={zoneTypes} defaultValue="default" validate={required()}/>
          <Typography variant="body1" sx={{ fontSize: '12px' }}>{translate('resources.zones.text.polygon_should_smaller')}</Typography>
          <ZoneMap source={'location'} setMap={setMap} map={map}/>
          <TextInput source="location.properties.address.formattedAddress" label="general.fields.position.address.name"/>
          <RaIntegerInput source="location.properties.address.floor" label="general.text.map_floors" style={{
            marginLeft: 0,
          }}/>
          <NumberInput source="responsePriority"/>
          <div className="flex-in-between">
            <BooleanInput source="syncToAms" fullWidth/>
            <Tooltip title={translate('resources.zones.text.syncToAms_explanation')}>
              <HelpOutlineIcon/>
            </Tooltip>
          </div>
          <ArrayInput source="staticResponders" label="resources.static-responders.text.title">
            <SimpleFormIterator disableReordering>
              <ReferenceInput name="static_responders" source="staticResponder" reference="static-responders">
                <AutocompleteInput validate={required()} autoHighlight={true} label="resources.assets.fields.staticResponders.staticResponder"/>
              </ReferenceInput>
              <NumberInput source="priority" label="resources.assets.fields.staticResponders.priority"/>
            </SimpleFormIterator>
          </ArrayInput>

        </SimpleForm>
      </CreateWithSuccess>
    </>
  );

};

const flyDuration = 1.5;

export const SimpleZoneMap = () => {

  const [expandedId, setExpandedId] = useState<Identifier>('');
  const [formEdited, setFormEdited] = useState(false);
  const [, toggleExpanded] = useExpanded('zones', expandedId, true);
  const [map, setMap] = useState<Map | null>(null);
  const [searchFieldValue, setSearchFieldValue] = useState('');
  const [searchDebounceValue] = useDebounce(searchFieldValue, LIST_DEBOUNCE);
  const [searchFocussed, setSearchFocussed] = useState(false);
  const [createMode, setCreateMode] = useState<false | 'polygon' | 'marker'>(false);
  const [createModeDialogOpen, setCreateModeDialogOpen] = useState(false);
  const [loadedZones, setLoadedZones] = useState<Array<ZoneWithBeacons>>([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [lastSavedCustomer, setLastSavedCustomer] = useState('');
  const [initialLocation, setInitialLocation] = useState<PointGeometry | null>(null);
  const [initialName, setInitialName] = useState('');
  const [selfPlaceDialogOpen, setSelfPlaceDialogOpen] = useState(false);
  const [locationInputDialogOpen, setLocationInputDialogOpen] = useState(false);
  const [selectedFloors, setSelectedFloors] = useState<number[]>([]);
  const [possibleFloors, setPossibleFloors] = useState<number[]>([]);
  const userIdentity = useGetIdentity();

  const currentCustomer = getCurrentCustomer();

  const translate = useTranslate();
  const notify = useNotify();
  const refresh = useRefresh();

  const overlays = useOverlay(map, selectedFloors);

  const getZones = async () => {

    const zoneRequest = await xgacDataProvider.getList('zones', {
      pagination: { page: 1, perPage: -1 },
      sort: { field: 'name', order: 'ASC' },
      filter: { ...(searchDebounceValue ? { q: searchDebounceValue } : {}) },
    });
    return zoneRequest;

  };
  const loadZones = async () => {

    setIsLoaded(false);
    const promises = [];
    promises.push(getZones());

    promises.push(xgacDataProvider.getAll('beacons', {
      sort: { field: 'name', order: 'ASC' },
      filter: {},
    }));

    const [zoneRequest, beaconsRequest] = await Promise.all(promises);
    const beacons = beaconsRequest.data.filter((beacon: any) => beacon.zone);
    const zonesWithBeacons: Array<ZoneWithBeacons> = zoneRequest.data?.map((zone: Zone) => ({
      ...zone,
      zoneSize: turf.area(zone.location),
      beacons: beacons.filter((beacon: any) => beacon.zone._id === zone._id),
    })).filter((fullZone: ZoneWithBeacons) => {

      // check for the size of the zone polygon
      return fullZone.zoneSize < 20000 * 1000000;

    }).sort((zoneA: ZoneWithBeacons, zoneB: ZoneWithBeacons) => {

      // sort by zone size
      return zoneB.zoneSize - zoneA.zoneSize;

    }) as unknown as Array<ZoneWithBeacons>;

    if (_.isEqual(loadedZones, zonesWithBeacons)) {

      setIsLoaded(true);
      return;

    }
    setLoadedZones(zonesWithBeacons);
    setIsLoaded(true);

  };

  useEffect(() => {

    if (currentCustomer && currentCustomer.value !== lastSavedCustomer) {

      setLastSavedCustomer(currentCustomer.value);
      setLoadedZones([]);
      setExpandedId('');
      setFormEdited(false);
      loadZones();

    }

  }, [currentCustomer, userIdentity]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {

    if (searchDebounceValue !== '') {

      setSearchFocussed(false);

    }
    loadZones();

  }, [searchDebounceValue]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {

    if (!formEdited) return () => {};
    const message = translate('resources.zones.text.still_editing_warning');
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {

      event.preventDefault();
      event.returnValue = message;
      return message;

    };
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {

      window.removeEventListener('beforeunload', handleBeforeUnload);

    };

  }, [formEdited]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {

    if (expandedId === '') {

      setFormEdited(false);

    }

  }, [expandedId]);

  const filteredZones = useMemo(() => {

    return selectedFloors.length > 0
      ? loadedZones.filter((zone) => selectedFloors.includes(zone.location?.properties?.address?.floor || 0))
      : loadedZones;

  }, [selectedFloors, loadedZones]);

  const toggleItemExpansion = (id: Identifier) => {

    if (createMode !== false) {

      notify('resources.zones.text.still_editing_warning', { type: 'warning' });
      return false;

    }
    if (expandedId === '' || !formEdited) {

      setExpandedId(id);
      return false;

    }

    if (expandedId !== id) {

      notify('resources.zones.text.still_editing_warning', { type: 'warning' });

    }
    return false;

  };

  const setExpandedIdToEmpty = () => {

    setExpandedId('');

  };

  useEffect(() => {

    toggleExpanded();
    // go over each layer
    map?.eachLayer((layer: any) => {

      if (layer instanceof L.Polygon) {

        // check if it is the expanded one
        if (layer.toGeoJSON().id === expandedId) {

          layer.pm?.enable(polygonZoneEditOptions);
          const bounds = map?.getBounds();
          const zoomLevel = map?.getZoom();
          if (!bounds?.contains(layer.getBounds()) || (zoomLevel && zoomLevel < 12)) {

            map?.flyToBounds(layer.getBounds(), { padding: [20, 20], duration: flyDuration });

          }

        } else {

          layer.pm?.disable();
          const originalZone = loadedZones.find((zone) => zone._id === layer.toGeoJSON().id);

          if (originalZone?.location.coordinates[0]) {

            layer.setLatLngs(originalZone?.location.coordinates[0].map((coordinates: any[]) => [coordinates[1], coordinates[0]]));

          }

        }

      }
      if (layer instanceof L.Marker) {

        // check if it is the expanded one
        if (layer.toGeoJSON().id === expandedId) {

          // enable dragging on the marker
          layer.pm?.enable(markerZoneEditOptions);
          const bounds = map?.getBounds();
          const zoomLevel = map?.getZoom();
          if (!bounds?.contains(layer.getLatLng()) || (zoomLevel && zoomLevel < 12)) {

            map?.flyTo(layer.getLatLng(), 18, { duration: flyDuration });

          }

        } else {

          layer.pm?.disable();
          const originalZone = loadedZones.find((zone) => zone._id === layer.toGeoJSON().id);

          if (originalZone?.location.coordinates[0]) {

            const center = turf.center(originalZone.location).geometry.coordinates;
            layer.setLatLng([center[1], center[0]]);

          }

        }

      }

    });
    wait(1).then(() => {

      document.getElementById('open_zone_form')?.scrollIntoView({ block: 'start' });

    });

  }, [expandedId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {

    const combinedFloors = [...loadedZones.map((zone) => zone.location?.properties?.address?.floor || 0), ...overlays.map((overlay) => overlay.level || 0)];
    const uniqueFloors = _.uniq(combinedFloors);
    const sortedFloors = uniqueFloors.sort((a, b) => a - b);
    if (!_.isEqual(possibleFloors, sortedFloors)) {

      setPossibleFloors(sortedFloors);
      setSelectedFloors([]);

    }

  }, [loadedZones, overlays]); // eslint-disable-line react-hooks/exhaustive-deps

  const handlePlaceLocation = () => {

    if (initialLocation) {

      map?.flyTo([initialLocation.coordinates[1], initialLocation.coordinates[0]], 18, { duration: flyDuration });

      map?.pm?.disableDraw();

      if (createMode === 'marker') {

        const zoneToAdd = {
          type: 'Feature',
          id: -1,
          properties: {},
          geometry: {
            type: 'Point',
            coordinates: initialLocation.coordinates,
          },
          updatedAt: null,

        };
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore types geoman zijn niet te importeren
        const layerToAdd = L.geoJson(zoneToAdd, {
          pointToLayer(geoJsonPoint: Feature<Point>, latlng: LatLng): Layer {

            return L.marker(latlng, {
              draggable: false,
              icon: L.divIcon({
                className: zoneToAdd.id.toString(),
                iconAnchor: [11, 36],
                popupAnchor: [0, -24],
                html: `<div class="pin pincolor-1972b9"></div><style>
  .pin.pincolor-1972b9 {
    border-color: #1972b9;
  }
  .pin.pincolor-1972b9::after { border-top-color: #1972b9 }
</style>`,
              }),
            });

          },
        });

        if (map) {

          layerToAdd.addTo(map);

        }
        layerToAdd.pm.enable(markerZoneEditOptions);

      }
      if (createMode === 'polygon') {

        const zoneGeometry = turf.circle(initialLocation.coordinates, 15, { units: 'meters', steps: 4 }).geometry;
        const zoneToAdd = {
          type: 'Feature',
          id: -1,
          properties: {},
          geometry: zoneGeometry,
          updatedAt: null,

        };
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore types geoman zijn niet te importeren
        const layerToAdd = L.geoJson(zoneToAdd);
        if (map) {

          layerToAdd.addTo(map);

        }
        layerToAdd.pm.enable(polygonZoneEditOptions);

      }

    }

  };
  const startCreate = () => {

    setCreateModeDialogOpen(true);
    setExpandedIdToEmpty();

  };
  const reverseGeocode = async (e: any) => {

    let coordinates;
    if (e.layer instanceof L.Marker) {

      coordinates = e.layer.getLatLng();
      const { place, parsedGoogleInput } = await reverseCoordinates(coordinates);

      if (place && parsedGoogleInput) {

        setInitialLocation({
          type: 'Point',
          coordinates: [coordinates.lng, coordinates.lat],
          properties: {
            address: {
              formattedAddress: place.formatted_address,
              streetName: parsedGoogleInput.route?.long_name,
              streetNumber: parsedGoogleInput.street_number?.long_name || 0,
              city: parsedGoogleInput.locality?.long_name,
              state: parsedGoogleInput.administrative_area_level_1?.long_name,
              countryCode: parsedGoogleInput.country?.short_name || 'NL',
            },
          },
        } as PointGeometry);

      }

    }
    if (e.layer instanceof L.Polygon) {

      // Gets size of the polygon in square meters
      const size = turf.area(e.layer.toGeoJSON());

      const center = turf.center(e.layer.toGeoJSON()).geometry.coordinates;
      const { place, parsedGoogleInput } = await reverseCoordinates(latLng([center[1], center[0]]), size);

      if (place && parsedGoogleInput) {

        setInitialLocation({
          type: 'Point',
          coordinates: center,
          properties: {
            address: {
              formattedAddress: place.formatted_address,
              streetName: parsedGoogleInput.route?.long_name,
              streetNumber: parsedGoogleInput.street_number?.long_name || 0,
              city: parsedGoogleInput.locality?.long_name,
              state: parsedGoogleInput.administrative_area_level_1?.long_name,
              countryCode: parsedGoogleInput.country?.short_name || 'NL',
            },
          },
        } as PointGeometry);

      }

    }

  };
  const ZoneCreateForm = () => {

    const [scrolled, setScrolled] = useState(false);
    const [saving, setSaving] = useState(false);
    useEffect(() => {

      if (!initialLocation) {

        map?.on('pm:create', reverseGeocode);

      } else {

        map?.off('pm:create', reverseGeocode);

      }

      return () => {

        map?.off('pm:create', reverseGeocode);

      };

    }, [initialLocation]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {

      if (!scrolled) {

        const documentElement = document.getElementById('open_zone_form');
        if (!documentElement) return;

        documentElement?.scrollIntoView({ block: 'start' });
        setScrolled(true);

      }

    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const floorDefaultValue = useMemo(() => {

      if (possibleFloors.length === 1) return possibleFloors[0];
      if (selectedFloors.length === 1) return selectedFloors[0];
      return 0;

    }, []);
    if (!createMode) return null;

    const handleCreateSubmit = async (data: any) => {

      setSaving(true);

      try {

        const layer = map?.pm.getGeomanLayers().find((innerLayer: any) => innerLayer.myId === -1 || innerLayer.toGeoJSON().id === -1);
        if (!layer) {

          return;

        }

        // ts-ignore because the typings are not correct, toGeoJSON does in fact exist
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        let layerCoordinates = layer?.toGeoJSON().geometry.coordinates;
        if (layer instanceof L.Marker) {

          layerCoordinates = turf.circle(layer.toGeoJSON().geometry.coordinates, 10, {
            units: 'centimeters',
            steps: 3,
          }).geometry.coordinates;

        }
        const updateData = {
          name: data.name,
          type: data.type || 'default',
          description: null,
          location: {
            type: 'Polygon',
            coordinates: layerCoordinates,
            properties: {
              address: {
                floor: data.location.properties.address.floor,
                formattedAddress: data.location.properties.address.formattedAddress,
              },
            },
          },
        };

        const transformedData = transform(updateData, updateData);
        const createResult = await xgacDataProvider.create('zones', { data: transformedData });
        notify('resources.zones.text.created', { type: 'success' });
        await loadZones();
        map?.removeLayer(layer);
        setInitialLocation(null);
        setInitialName('');
        setCreateMode(false);
        if (createResult) {

          setExpandedId(createResult.data._id);

        }

      } catch (e) {

        setSaving(false);

      }
      setSaving(false);

    };

    return (
      <Card sx={{ borderRadius: '5px', boxShadow: 1, marginTop: '10px' }} id="open_zone_form">
        <CardContent sx={{ p: '10px' }}>
          <Create
            resource="zones"
            actions={false}
            title={' '}
            sx={{
              '& .RaCreate-noActions': {
                marginTop: 0,
              },
            }}
          >
            <Form onSubmit={handleCreateSubmit}>
              <Typography
                variant="body1"
                sx={{
                  fontSize: '14px',
                  marginBottom: '5px',
                  fontWeight: 'bold',
                }}>
                {translate('general.fields.name')}
              </Typography>
              <TextInput
                source="name"
                className="text-input-zone-map"
                variant={'outlined'}
                label={false}
                defaultValue={initialName}
                required
                sx={{
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '5px',
                    width: '100%',
                    maxHeight: '30px',
                    backgroundColor: '#F2F2F2',
                    border: 'none',
                  },
                  '& .MuiOutlinedInput-notchedOutline': {
                    border: 'none',
                  },
                  marginTop: 0,
                  width: '100%',
                }}
                inputProps={{
                  style: {
                    fontWeight: 'bolder',
                  },
                }}
                helperText={false}
              />
              <Typography
                variant="body1"
                sx={{
                  fontSize: '14px',
                  marginBottom: '5px',
                  marginTop: '10px',
                  fontWeight: 'bold',
                }}>
                {translate('resources.zones.fields.alarm_position')}
              </Typography>
              <TextInput
                source="location.properties.address.formattedAddress"
                variant={'outlined'}
                multiline
                label={false}
                required
                defaultValue={initialLocation?.properties?.address?.formattedAddress}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '5px',
                    width: '100%',
                    height: '95px',
                    backgroundColor: '#F2F2F2',
                  },
                  '& .MuiOutlinedInput-notchedOutline': {
                    border: 'none',
                  },
                  marginTop: 0,
                  width: '100%',
                }}
                inputProps={{
                  style: {
                    fontSize: '13px',
                  },
                }}
                rows={4}
                helperText={false}
              />
              <RaIntegerInput source="location.properties.address.floor"
                label="general.text.map_floors"
                style={{
                  marginLeft: 0,
                }}
                defaultValue={floorDefaultValue}
              />
              <HasRoles anyOf={['admin']}>
                <Typography
                  variant="body1"
                  sx={{
                    fontSize: '14px',
                    marginBottom: '5px',
                    marginTop: '10px',
                    fontWeight: 'bold',
                  }}>
                  {translate('resources.zones.fields.type')}
                </Typography>
                <SelectInput
                  source="type"
                  choices={zoneTypes}
                  defaultValue="default"
                  validate={required()}
                  fullWidth
                  hiddenLabel={true}
                  helperText={false}
                  label={false}
                  variant="outlined"
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      borderRadius: '5px',
                      width: '100%',
                      backgroundColor: '#F2F2F2',
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                      border: 'none',
                    },
                    '& .ra-input': {
                      marginTop: 0,
                    },
                    marginTop: '0',
                    width: '100%',
                  }}
                />
              </HasRoles>
              <div style={{ width: '100%' }}>
                <Grid container spacing={1} sx={{ marginTop: '10px' }}>
                  <Grid md={6}>
                    <Button
                      color="error"
                      onClick={() => {

                        map?.pm.getGeomanLayers().find((layer: any) => layer.myId === -1 || layer.toGeoJSON().id === -1)?.remove();
                        setCreateMode(false);
                        setInitialLocation(null);
                        setInitialName('');

                      }}
                      label="ra.action.cancel"
                      sx={{ width: '100%', borderRadius: '5px', fontWeight: 'bolder' }}
                      variant="contained"/>
                  </Grid>
                  <Grid md={6}>
                    <Button color="primary"
                      label="ra.action.save"
                      type="submit"
                      disabled={saving}
                      sx={{ width: '100%', borderRadius: '5px', fontWeight: 'bolder' }}
                      variant="contained"
                    />
                  </Grid>
                </Grid>
              </div>
            </Form>
          </Create>
        </CardContent>
      </Card>
    );

  };

  const ZoneEditForm = (props: { beaconHealth: Array<BeaconHealth>; links: any[]; triggers: any[]; kiosks: KioskUser[] }) => {

    const record: ZoneWithBeacons = useRecordContext();
    const beaconCodes: { code: string; beaconHealth: boolean | string }[] = [];
    const [dialogOpen, setDialogOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [assets, setAssets] = useState<any[]>([]);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [saving, setSaving] = useState(false);
    const isAdmin = authProvider.isAdmin();
    const [deleteDisabled, setDeleteDisabled] = useState(true);
    const [deleteDisabledReason, setDeleteDisabledReason] = useState('');

    useEffect(() => {

      if (!record) return;
      const beacons = loadedZones.find((zone) => zone._id === record._id)?.beacons || [];
      const initialDisabled = record.syncToAms || beacons?.length > 0;

      if (initialDisabled) {

        setDeleteDisabled(true);
        setDeleteDisabledReason(translate('resources.zones.text.delete_disabled_ams_or_beacons'));
        return;

      }

      const linksAndTriggers = [...props.links, ...props.triggers];
      const isUsedInLinksAndTriggers = linksAndTriggers.filter((linkOrTrigger) => {

        return JSON.stringify(linkOrTrigger).includes(record._id as string);

      }).length > 0;
      if (isUsedInLinksAndTriggers) {

        setDeleteDisabled(true);
        setDeleteDisabledReason(translate('resources.zones.text.delete_disabled_links_or_triggers'));
        return;

      }

      const zonesInKiosks = props.kiosks.map((kiosk) => kiosk.kioskConfig.zone?._id);
      if (zonesInKiosks.includes(record._id)) {

        setDeleteDisabled(true);
        setDeleteDisabledReason(translate('resources.zones.text.delete_disabled_kiosks'));
        return;

      }
      setDeleteDisabled(false);

    }, [isAdmin, props.links, props.triggers, record, props.kiosks]);

    const DeleteButton = () => {

      if (!isAdmin) {

        if (deleteDisabled) {

          return (
            <Tooltip title={deleteDisabledReason}>
              <span>
                <IconButton disabled size="small">
                  <DeleteIcon/>
                </IconButton>
              </span>
            </Tooltip>
          );

        }
        return (
          <IconButton size="small" onClick={(e) => {

            e.stopPropagation();
            setDeleteDialogOpen(true);

          }}>
            <DeleteIcon/>
          </IconButton>
        );

      }
      return (
        <IconButton size="small" onClick={(e) => {

          e.stopPropagation();
          setDeleteDialogOpen(true);

        }}>
          <DeleteIcon/>
        </IconButton>
      );

    };

    const openDialog = (e: any) => {

      e.stopPropagation();
      setDialogOpen(true);
      setLoading(true);
      if (record.assetsInside?.length > 0) {

        xgacDataProvider.getMany(
          'all-assets',
          { ids: record.assetsInside.map((assetInside: AssetRef) => (assetInside._id)), meta: { sort: 'name' } },
        ).then((returnedAssets) => {

          setAssets(returnedAssets.data);
          setLoading(false);

        });

      } else {

        setAssets([]);
        setLoading(false);

      }

    };

    record?.beacons?.forEach((beacon: Beacon) => {

      const beaconHealth = props.beaconHealth.find((beaconInHealthArray) => beaconInHealthArray._id === beacon._id)?.healthy || true;

      beacon.codes.forEach((code: string) => {

        beaconCodes.push({ code, beaconHealth });

      });

    });

    const onZoneSuccess = () => {

      notify('ra.notification.updated', { messageArgs: { smart_count: 1 }, type: 'info' });
      refresh();

    };

    const saveButtonPressed = async (data: any) => {

      setSaving(true);
      try {

        // get the layer corresponding to this zone
        const layer = map?.pm.getGeomanLayers().find((innerLayer: any) => innerLayer.toGeoJSON().id === record._id);

        // ts-ignore because the typings are not correct, toGeoJSON does in fact exist
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        let layerCoordinates = layer?.toGeoJSON().geometry.coordinates;
        if (layer instanceof L.Marker) {

          layerCoordinates = turf.circle(layer.toGeoJSON().geometry.coordinates, 2, {
            units: 'centimeters',
            steps: 3,
          }).geometry.coordinates;

        }
        const updateData = {
          ...data,
          location: {
            ...data.location,
            coordinates: layerCoordinates,
          },
        };
        const transformedData = transform(updateData, updateData);
        await xgacDataProvider.update('zones', { id: record._id, data: transformedData, previousData: record });
        notify('resources.zones.text.updated', { type: 'success' });

      } catch (e) {

        setSaving(false);

      }
      setSaving(false);
      await loadZones();
      setExpandedIdToEmpty();

    };

    return (
      <>
        <Card sx={{ borderRadius: '5px', boxShadow: 1 }} id="open_zone_form">
          <CardContent sx={{ p: '10px' }}>
            <Edit
              id={record._id}
              resource="zones"
              actions={false}
              title={' '}
              redirect={false}
              mutationMode="pessimistic"
              mutationOptions={{ onSuccess: onZoneSuccess }}
              sx={{
                '& .RaEdit-noActions': {
                  marginTop: 0,
                },
              }}
            >
              <Form onSubmit={saveButtonPressed}>
                <div>
                  <div className="flex-in-between">
                    <ZoneColorById
                      size={30}
                      withDot={true}
                      style={{
                        marginRight: '10px',
                      }}
                    />
                    <TextInput
                      source="name"
                      className="text-input-zone-map"
                      variant={'outlined'}
                      label={false}
                      required
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          borderRadius: '5px',
                          width: '100%',
                          maxHeight: '30px',
                          backgroundColor: '#F2F2F2',
                          border: 'none',
                        },
                        '& .MuiOutlinedInput-notchedOutline': {
                          border: 'none',
                        },
                        marginTop: 0,
                        width: '80%',
                      }}
                      inputProps={{
                        style: {
                          fontWeight: 'bolder',
                        },
                      }}
                      helperText={false}
                    />
                    <DeleteButton/>
                  </div>
                  {beaconCodes.length > 0 && (
                    <Typography
                      variant="body1"
                      sx={{
                        fontSize: '14px',
                        marginBottom: '5px',
                        marginTop: '10px',
                        fontWeight: 'bold',
                      }}>
                      {translate('resources.zones.text.beacons_in_zone')}
                    </Typography>
                  )}
                  {beaconCodes.map((beacon) => {

                    return (
                      <Chip variant="outlined"
                        sx={{
                          marginRight: '7px',
                          marginBottom: '7px',
                        }}
                        label={
                          <div style={{
                            display: 'flex',
                            alignItems: 'center',
                          }}>
                            <span
                              style={{
                                marginRight: '7px',
                              }}
                            >
                              {beacon.code}
                            </span>
                            {/* eslint-disable-next-line no-nested-ternary */}
                            {beacon.beaconHealth === true ? (
                              <Tooltip title={translate('resources.beacons.text.beacon_healthy')}>
                                <CheckIcon fontSize="small"/>
                              </Tooltip>
                            ) : (
                              beacon.beaconHealth === 'lowBattery' ? (
                                <Tooltip title={translate('resources.beacons.text.low_battery')}>
                                  <Battery1BarIcon fontSize="small" sx={{ color: '#E37D55' }}/>
                                </Tooltip>
                              ) : (
                                <Tooltip title={translate('resources.beacons.text.beacon_last_seen')}>
                                  <SignalCellularOffIcon fontSize="small" sx={{ color: '#E37D55' }}/>
                                </Tooltip>
                              )
                            )}
                          </div>
                        }/>
                    );

                  })}
                  <Typography
                    variant="body1"
                    sx={{
                      fontSize: '14px',
                      marginBottom: '5px',
                      marginTop: '10px',
                      fontWeight: 'bold',
                    }}>
                    {translate('resources.zones.fields.alarm_position')}
                  </Typography>
                  <TextInput
                    source="location.properties.address.formattedAddress"
                    variant={'outlined'}
                    multiline
                    label={false}
                    required
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        borderRadius: '5px',
                        width: '100%',
                        height: '95px',
                        backgroundColor: '#F2F2F2',
                      },
                      '& .MuiOutlinedInput-notchedOutline': {
                        border: 'none',
                      },
                      marginTop: 0,
                      width: '100%',
                    }}
                    inputProps={{
                      style: {
                        fontSize: '13px',
                      },
                    }}
                    rows={4}
                    helperText={false}
                  />
                  <RaIntegerInput source="location.properties.address.floor" label="general.text.map_floors" style={{
                    marginLeft: 0,
                  }}/>
                  <HasRoles anyOf={['admin']}>
                    <Typography
                      variant="body1"
                      sx={{
                        fontSize: '14px',
                        marginBottom: '5px',
                        marginTop: '10px',
                        fontWeight: 'bold',
                      }}>
                      {translate('resources.zones.fields.type')}
                    </Typography>
                    <SelectInput
                      source="type"
                      choices={zoneTypes}
                      defaultValue="default"
                      validate={required()}
                      fullWidth
                      hiddenLabel={true}
                      helperText={false}
                      label={false}
                      variant="outlined"
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          borderRadius: '5px',
                          width: '100%',
                          backgroundColor: '#F2F2F2',
                        },
                        '& .MuiOutlinedInput-notchedOutline': {
                          border: 'none',
                        },
                        '& .ra-input': {
                          marginTop: 0,
                        },
                        marginTop: '0',
                        width: '100%',
                      }}
                    />
                  </HasRoles>
                  <div style={{
                    textAlign: 'center', backgroundColor: '#EFF3FE', paddingTop: '5px', paddingBottom: '5px', borderRadius: '5px', marginTop: '10px',
                  }}>
                    <span
                      style={ record.assetsInside.length > 0 ? {
                        textDecoration: 'underline',
                        cursor: 'pointer',
                      } : {}}
                      onClick={record.assetsInside.length > 0 ? openDialog : undefined}>
                      {record.assetsInside.length === 0
                        ? translate('resources.zones.text.assets_inside_empty')
                        : translate('resources.zones.text.assets_inside', { smart_count: record.assetsInside.length })}
                    </span>
                  </div>
                  <div style={{ width: '100%' }}>
                    <Grid container spacing={1} sx={{ marginTop: '10px' }}>
                      <Grid md={6}>
                        <Button color="error"
                          onClick={setExpandedIdToEmpty}
                          label="ra.action.cancel"
                          sx={{ width: '100%', borderRadius: '5px', fontWeight: 'bolder' }}
                          variant="contained"/>
                      </Grid>
                      <Grid md={6}>
                        <Button color="primary"
                          label="ra.action.save"
                          type="submit"
                          disabled={saving}
                          sx={{ width: '100%', borderRadius: '5px', fontWeight: 'bolder' }}
                          variant="contained"
                          onClick={(e) => {

                            e.stopPropagation();

                          }}
                        />
                      </Grid>
                    </Grid>
                  </div>
                </div>
              </Form>
            </Edit>
          </CardContent>
        </Card>
        <Confirm
          content={
            <div><Typography variant="body1">{translate('ra.message.delete_content')}</Typography>
              {(isAdmin && deleteDisabled) && (
                <Typography variant="body1" style={{ color: '#d0382a' }}>
                  {deleteDisabledReason}
                </Typography>
              )}
            </div>
          }
          onClose={() => setDeleteDialogOpen(false)}
          title="resources.zones.text.delete"
          isOpen={deleteDialogOpen}
          onConfirm={async () => {

            setExpandedId('');
            await xgacDataProvider.delete('zones', { id: record._id });
            await loadZones();
            notify('resources.zones.text.deleted', { type: 'success' });
            refresh();
            setDeleteDialogOpen(false);

          }}
        />
        <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)} maxWidth="md" fullWidth>
          <DialogTitle className="flex-in-between">
            <Typography variant="body1" fontSize="20px">{translate('resources.zones.text.assets_inside_dialog_title')}</Typography>
            <IconButton onClick={() => setDialogOpen(false)}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <AssetTableInDialog assets={assets} loading={loading}/>
        </Dialog>
      </>
    );

  };

  const handleClickOnMap = (id: Identifier) => {

    if (expandedId !== id) {

      toggleItemExpansion(id);

    }

  };

  return (
    <>
      <Grid container sx={{ height: '100%', marginLeft: '-16px' }}>
        <Grid md={9}>
          <MultipleZonesMap
            zones={filteredZones}
            handleClickOnZone={handleClickOnMap}
            map={map}
            setMap={setMap}
            createMode={createMode}
            expandedId={expandedId}
            formEdited={formEdited}
            setFormEdited={setFormEdited}
            possibleFloors={possibleFloors}
            selectedFloors={selectedFloors}
            setSelectedFloors={setSelectedFloors}
          />
        </Grid>
        <Grid md={3}>
          <ZoneSideBar
            expandedId={expandedId}
            toggleItemExpansion={toggleItemExpansion}
            searchFocussed={searchFocussed}
            setSearchFocussed={setSearchFocussed}
            searchFieldValue={searchFieldValue}
            setSearchFieldValue={setSearchFieldValue}
            startCreate={startCreate}
            isLoaded={isLoaded}
            loadedZones={filteredZones.sort((a, b) => a.name.localeCompare(b.name))}
            setSelectedFloors={setSelectedFloors}
            createMode={createMode}
            ZoneCreateForm={ZoneCreateForm}
            ZoneEditForm={ZoneEditForm}
            formEdited={formEdited}
            map={map}
          />
        </Grid>
      </Grid>
      <CustomConfirmDialog
        open={createModeDialogOpen}
        handleClose={() => setCreateModeDialogOpen(false)}
        dialogTitle="resources.zones.text.create_mode_dialog_title"
        icon1={{
          title: 'resources.zones.text.area',
          subTitle: 'resources.zones.text.area_subtext',
          action: () => {

            setCreateMode('polygon');
            setSelfPlaceDialogOpen(true);
            setCreateModeDialogOpen(false);

          },
          icon: <SvgIcon>
            <svg id="Overlay" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 65 41" strokeWidth='0px' stroke='currentColor'>
              <path
                d="M28,41L0,25.36,25.24,0l39.76,13.85v17.39l-37,9.76ZM4.11,24.79l24.23,13.53,34.15-9.01v-13.69L25.9,2.89,4.11,24.79Z"
              />
            </svg>
          </SvgIcon>,
        }}
        icon2={{
          title: 'resources.zones.text.marker',
          subTitle: 'resources.zones.text.marker_subtext',
          action: () => {

            setCreateMode('marker');
            setSelfPlaceDialogOpen(true);
            setCreateModeDialogOpen(false);

          },
          icon: <PinDropOutlinedIcon/>,
        }}/>
      <CustomConfirmDialog
        open={selfPlaceDialogOpen}
        handleClose={() => setSelfPlaceDialogOpen(false)}
        dialogTitle="resources.zones.text.create_dialog_where_title"
        icon1={{
          title: 'resources.zones.text.create_dialog_where_map',
          subTitle: '',
          action: () => {

            setSelfPlaceDialogOpen(false);
            setInitialLocation(null);
            setInitialName('');

          },
          icon: <MapIcon/>,
        }}
        icon2={{
          title: 'resources.zones.text.create_dialog_where_input',
          subTitle: '',
          action: () => {

            setLocationInputDialogOpen(true);
            setSelfPlaceDialogOpen(false);

          },
          icon: <GoogleIcon/>,
        }}
      />
      <Dialog open={locationInputDialogOpen} onClose={() => {

        setInitialLocation(null);
        setInitialName('');
        setLocationInputDialogOpen(false);

      }} maxWidth="sm" fullWidth>
        <DialogTitle className="flex-in-between">
          <Typography variant="body1" fontSize="20px">{translate('resources.zones.text.create_dialog_where_input')}</Typography>
          <IconButton onClick={() => {

            setLocationInputDialogOpen(false);
            setInitialLocation(null);
            setInitialName('');

          }}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Typography
            variant="body1"
            sx={{
              fontSize: '14px',
              marginBottom: '5px',
              fontWeight: 'bold',
            }}>
            {translate('general.fields.position.address.name')}
          </Typography>
          <GoogleCoordinatesInput
            value={initialLocation}
            setValue={setInitialLocation}
            setName={setInitialName}
            variant={'outlined'}
            label={translate('general.text.search_google_maps')}
            sx={{
              '& .MuiOutlinedInput-root': {
                borderRadius: '5px',
                width: '100%',
                height: '40px',
                backgroundColor: '#F2F2F2',
                border: 'none',
              },
              '& .MuiOutlinedInput-notchedOutline': {
                border: 'none',
              },
              marginTop: 0,
              width: '100%',
            }}/>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => {

            setLocationInputDialogOpen(false);
            setSelfPlaceDialogOpen(true);
            setInitialLocation(null);
            setInitialName('');

          }}
          color="error"
          label={translate('ra.action.back')}/>
          <Button onClick={(e) => {

            e.stopPropagation();
            handlePlaceLocation();
            setLocationInputDialogOpen(false);

          }} color="primary"
          label={translate('ra.action.save')}
          />
        </DialogActions>
      </Dialog>
    </>
  );

};
