import {
  AutocompleteArrayInput, ReferenceArrayInput, SearchInput, useGetIdentity,
} from 'react-admin';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useListContext } from 'ra-core';
import { getCurrentCustomer } from '../../../../lib/currentCustomer';

export const FilterResetter = () => {

  const currentCustomer = getCurrentCustomer()?.value;
  const [lastSavedCustomer, setLastSavedCustomer] = useState<string | null>(null);
  const { setFilters } = useListContext();
  const identity = useGetIdentity();

  useEffect(() => {

    if (currentCustomer && currentCustomer !== lastSavedCustomer) {

      setLastSavedCustomer(currentCustomer);

    }

  }, [identity, currentCustomer]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {

    if (!lastSavedCustomer) return;
    const storedCustomer = sessionStorage.getItem('resetterCustomer');
    if (storedCustomer && storedCustomer !== lastSavedCustomer) {

      setFilters({ }, { });

    }

    sessionStorage.setItem('resetterCustomer', lastSavedCustomer || '');

  }, [lastSavedCustomer]); // eslint-disable-line react-hooks/exhaustive-deps

  return null;

};

const SearchFields = [
  <SearchInput source="q" alwaysOn sx={{ width: '100%' }}/>,
];

export const AssetFilters = [
  <SearchInput source="q" alwaysOn sx={{
    '& .MuiFilledInput-input': {
      height: '31px',
    },
    width: '100%',
  }}/>,
  <ReferenceArrayInput source="assetGroups" reference="asset-groups" label="resources.asset-groups.name" alwaysOn>
    <>
      <AutocompleteArrayInput label="resources.asset-groups.name" fullWidth />
      <FilterResetter/>
    </>
  </ReferenceArrayInput>,
];
export default SearchFields;
