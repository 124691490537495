import { useFormContext } from 'react-hook-form';
import {
  Dispatch, SetStateAction, useEffect, useState,
} from 'react';
import { useInput, useTranslate } from 'react-admin';
import { Alert, SxProps, TextField as MuiTextField } from '@mui/material';
import * as React from 'react';
import { httpClient } from '../../../../utils/httpClient';
import { XGAC_MAIN_API_URL } from '../../../../config';
import authProvider from '../../../../utils/authProvider';

const apiUrl = XGAC_MAIN_API_URL;
export const UsernameInput = (props: {
  fromEmail?: boolean;
  initialUsername?: string;
  sx?: SxProps;
  setFormValid? : Dispatch<SetStateAction<boolean>>;
  disableFullWidth?: boolean;
  color?: 'error' | 'primary' | 'secondary' | 'info' | 'success' | 'warning';
}) => {

  const { getValues } = useFormContext();
  const { watch } = useFormContext();
  const formData = getValues();
  const emailFieldValue = watch('properties.email');
  const [emailCanBeUsername, setEmailCanBeUsername] = useState(true);
  const [usernameValid, setUsernameValid] = useState(true);
  const [usernameFieldValue, setUsernameFieldValue] = useState('');
  const [helperText, setHelperText] = useState('');
  const [input, setInput] = useState<any>(null);
  const roles = authProvider.getRoles();

  const canUseSpecificEmail = roles.includes('admin') || roles.includes('customer_admin_special_username');

  useEffect(() => {

    if (props.fromEmail === false) {

      setEmailCanBeUsername(false);

    }

  }, [props.fromEmail]);

  const validateUserNameField = (value: string) => {

    if (value === '' || !value) {

      return 'general.errors.required';

    }
    if (value.length < 3) {

      return 'general.errors.username_too_short';

    }
    if (!usernameValid) {

      return 'resources.users.text.username_already_exists';

    }
    return undefined;

  };
  const { field } = useInput({ source: 'username', validate: usernameFieldValue !== props.initialUsername ? validateUserNameField : undefined });
  const translate = useTranslate();

  const checkUsername = async (username: string) => {

    return httpClient(`${apiUrl}/users/check-username`, { method: 'POST', body: JSON.stringify(username) }).then(() => {

      return true;

    }).catch(() => {

      return false;

    });

  };

  useEffect(() => {

    if (props.fromEmail === false) {

      return () => {};

    }
    if (emailFieldValue === undefined) {

      return () => {};

    }

    const delayDebounceFn = setTimeout(() => {

      checkUsername(emailFieldValue).then((returnValue: boolean) => {

        setUsernameValid(returnValue);
        props.setFormValid?.(returnValue);
        if (props.fromEmail === false) {

          setEmailCanBeUsername(false);
          field.onChange(props.initialUsername || emailFieldValue);
          setUsernameFieldValue(props.initialUsername || emailFieldValue);

        } else {

          setEmailCanBeUsername(returnValue);
          field.onChange(emailFieldValue);
          setUsernameFieldValue(emailFieldValue);

        }

      }).catch(() => {

        field.onChange(props.initialUsername);

      });

    }, 200);

    return () => clearTimeout(delayDebounceFn);

  }, [emailFieldValue]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleBlurOrTimout = async (e: any) => {

    const returnValue = await checkUsername(e.target.value);

    if (returnValue) {

      setUsernameValid(true);
      props.setFormValid?.(true);
      field.onChange(e.target.value);

    } else {

      setUsernameValid(false);
      if (e.target.value === props.initialUsername) {

        field.onChange(e.target.value);

      }
      props.setFormValid?.(false);

    }

  };

  useEffect(() => {

    if (!emailCanBeUsername) {

      const delayDebounceFn = setTimeout(() => {

        handleBlurOrTimout({ target: { value: usernameFieldValue } });

      }, 1000);

      return () => clearTimeout(delayDebounceFn);

    }
    return () => {};

  }, [usernameFieldValue]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {

    if (!usernameValid && usernameFieldValue !== props.initialUsername) {

      setHelperText(translate('resources.users.text.username_already_exists'));
      return;

    }
    if (usernameFieldValue !== '' && canUseSpecificEmail && usernameFieldValue !== emailFieldValue && props.fromEmail !== false) {

      setHelperText(translate('resources.users.text.username_should_be_email'));
      return;

    }
    setHelperText('');

  }, [usernameValid, canUseSpecificEmail, usernameFieldValue, emailCanBeUsername]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!canUseSpecificEmail && emailCanBeUsername) {

    return (
      <MuiTextField
        sx={props.sx}
        value={formData.properties?.email}
        disabled
        InputLabelProps={{ shrink: usernameFieldValue !== '' }}
        color={props.color || 'info'}
        fullWidth={!props.disableFullWidth}
        size="small"
        className="ra-input"
        label={translate('resources.users.fields.username')}
      />
    );

  }
  return (
    <>
      <MuiTextField
        sx={props.sx}
        inputRef={(ref) => setInput(ref)}
        value={usernameFieldValue}
        InputLabelProps={{ shrink: usernameFieldValue !== '' }}
        color={props.color || 'info'}
        size="small"
        fullWidth={!props.disableFullWidth}
        className="ra-input"
        label={translate('resources.users.fields.username')}
        onChange={(e) => {

          setUsernameFieldValue(e.target.value);

        }}
        error={(!usernameValid && usernameFieldValue !== props.initialUsername)}
        onBlur={handleBlurOrTimout}
        onKeyPress={(e) => {

          if (e.key === 'Enter') {

            e.preventDefault();
            input.blur();

          }

        }}
      />
      {helperText !== '' && (
        <Alert severity={'warning'}> {helperText}</Alert>
      )}
    </>
  );

};
