import {
  useRecordContext, useRefresh, useResourceContext, useTranslate,
} from 'react-admin';
import { useUpdate } from 'ra-core';
import CircularProgress from '@mui/material/CircularProgress';
import * as React from 'react';
import { Switch } from '@mui/material';

export const BooleanSetterField = (props: { source: string; label: string }) => {

  const record = useRecordContext();
  const resource = useResourceContext();
  const [update, { isLoading }] = useUpdate();
  const translate = useTranslate();
  const refresh = useRefresh();
  const checkedValue = record[props.source];

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {

    const value = event.target.checked;
    update(resource, { id: record.id, data: { [props.source]: value }, previousData: record }).then(() => {

      refresh();

    });

  };

  if (isLoading) {

    return (
      <>
        <CircularProgress size={20}/>
      </>);

  }
  return (
    <>
      <Switch checked={checkedValue} onChange={handleChange} />
      {!isLoading && (
        <span>{translate(checkedValue ? 'general.text.yes' : 'general.text.no')}</span>
      )}
    </>
  );

};
