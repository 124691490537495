import { useEffect, useState } from 'react';
import { Map } from 'leaflet';
import { Button, useLocaleState } from 'react-admin';
import { useTranslate } from 'ra-core';
import {
  Dialog, DialogContent, DialogTitle, IconButton, Table, TableBody, TableCell, TableHead, TableRow,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CircularProgress from '@mui/material/CircularProgress';
import moment from 'moment';
import {
  RoomCalcConfig,
  RoomCalcResult,
  RoomCalcResultRow,
  RoomCalcSizeConfig,
  ZoneWithBeacons,
} from '../lib/constants/customTypes';
import { wait } from '../lib/websockets/webSocketHelpers';

const roomCalcConfig: RoomCalcConfig = {
  small: {
    maxSquareMeters: 100,
    beaconNumber: 1,
    fingerPrintTime: 10,
  },
  medium: {
    maxSquareMeters: 350,
    beaconNumber: 3,
    fingerPrintTime: 15,
  },
  large: {
    maxSquareMeters: 700,
    beaconNumber: 5,
    fingerPrintTime: 22.5,
  },
};

const beaconPlaceTime = 5;
export const RoomCalculation = (props: {
  zones: ZoneWithBeacons[];
  map: Map | null;
  setSelectedFloors: (floors: number[]) => void;
}) => {

  const { zones, map, setSelectedFloors } = props;
  const [open, setOpen] = useState(false);
  const [roomCalcResult, setRoomCalcResult] = useState<RoomCalcResult | null>(null);
  const currentLocale = useLocaleState();
  moment.locale(currentLocale[0]);

  const translate = useTranslate();

  const openDialog = async () => {

    setSelectedFloors([]);
    await wait(1);
    setOpen(true);

  };
  const minutesToText = (minutes: number) => {

    return minutes ? moment.duration(minutes, 'minutes').humanize() : 0;

  };
  const calculateRow = (sizeConfig: RoomCalcSizeConfig, zonesForCalc: ZoneWithBeacons[]) => {

    const result: RoomCalcResultRow = {
      roomNumber: zonesForCalc.length,
      beaconNumber: sizeConfig.beaconNumber * zonesForCalc.length,
      totalSquareMeters: parseFloat(zonesForCalc.reduce((acc, zone) => acc + zone.zoneSize, 0).toFixed(2)),
      zones: zonesForCalc,
      fingerPrintTime: sizeConfig.fingerPrintTime * zonesForCalc.length,
      beaconPlaceTime: beaconPlaceTime * sizeConfig.beaconNumber * zonesForCalc.length,
      totalTime: sizeConfig.fingerPrintTime * zonesForCalc.length + beaconPlaceTime * zonesForCalc.length,
    };

    return result;

  };
  const calculateResult = () => {

    const mapBounds = map?.getBounds();
    const zonesInBounds = zones.filter((zone) => {

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const mapPolygon = L.polygon(zone.location.coordinates[0].map((coord) => [coord[1], coord[0]]));
      return mapBounds?.contains(mapPolygon.getBounds());

    });
    const smallRooms: ZoneWithBeacons[] = [];
    const mediumRooms: ZoneWithBeacons[] = [];
    const largeRooms: ZoneWithBeacons[] = [];

    zonesInBounds.forEach((zone) => {

      if (zone.zoneSize <= roomCalcConfig.small.maxSquareMeters) {

        smallRooms.push(zone);

      } else if (zone.zoneSize <= roomCalcConfig.medium.maxSquareMeters) {

        mediumRooms.push(zone);

      } else {

        largeRooms.push(zone);

      }

    });
    const calculatedRows = {
      small: calculateRow(roomCalcConfig.small, smallRooms),
      medium: calculateRow(roomCalcConfig.medium, mediumRooms),
      large: calculateRow(roomCalcConfig.large, largeRooms),
    };
    const total = {
      roomNumber: zonesInBounds.length,
      beaconNumber: calculatedRows.small.beaconNumber + calculatedRows.medium.beaconNumber + calculatedRows.large.beaconNumber,
      totalSquareMeters: parseFloat(zonesInBounds.reduce((acc, zone) => acc + zone.zoneSize, 0).toFixed(2)),
      zones: [] as ZoneWithBeacons[],
      fingerPrintTime: calculatedRows.small.fingerPrintTime + calculatedRows.medium.fingerPrintTime + calculatedRows.large.fingerPrintTime,
      beaconPlaceTime: calculatedRows.small.beaconPlaceTime + calculatedRows.medium.beaconPlaceTime + calculatedRows.large.beaconPlaceTime,
      totalTime: calculatedRows.small.totalTime + calculatedRows.medium.totalTime + calculatedRows.large.totalTime,
    };
    setRoomCalcResult({
      small: calculatedRows.small,
      medium: calculatedRows.medium,
      large: calculatedRows.large,
      total,
    });

  };

  useEffect(() => {

    if (!map || !zones || !open) return;

    setRoomCalcResult(null);
    calculateResult();

  }, [map, zones, open]); // eslint-disable-line

  return (
    <>
      <Button onClick={openDialog} label='resources.zones.text.beacon_calc.open_button' size="small" variant="contained"
        sx={{ marginRight: '10px', borderRadius: '5px', fontWeight: 'bolder' }}
      />
      <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth="lg">
        <DialogTitle className="flex-in-between">
          <span>{translate('resources.zones.text.beacon_calc.dialog_title')}</span>
          <IconButton onClick={() => setOpen(false)}>
            <CloseIcon/>
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {roomCalcResult ? (
            <div>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      {translate('resources.zones.text.beacon_calc.table.room_size')}
                    </TableCell>
                    <TableCell>
                      {translate('resources.zones.text.beacon_calc.table.room_number')}
                    </TableCell>
                    {/* <TableCell> */}
                    {/*  {translate('resources.zones.text.beacon_calc.table.total_square_meters')} */}
                    {/* </TableCell> */}
                    <TableCell>
                      {translate('resources.zones.text.beacon_calc.table.beacon_number')}
                    </TableCell>
                    <TableCell>
                      {translate('resources.zones.text.beacon_calc.table.beacon_place_time')}
                    </TableCell>
                    <TableCell>
                      {translate('resources.zones.text.beacon_calc.table.fingerprint_time')}
                    </TableCell>
                    <TableCell>
                      {translate('resources.zones.text.beacon_calc.table.total_time')}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Object.keys(roomCalcResult).map((key) => {

                    const row = roomCalcResult[key as keyof RoomCalcResult];
                    // check if last row
                    if (key === 'total') {

                      return (
                        <TableRow key={key} >
                          <TableCell>
                          </TableCell>
                          <TableCell>
                            {row.roomNumber}
                          </TableCell>
                          {/* <TableCell> */}
                          {/*  {row.totalSquareMeters}m² */}
                          {/* </TableCell> */}
                          <TableCell>
                            ≈ {row.beaconNumber} {translate('resources.zones.text.beacon_calc.table.beacons')}
                          </TableCell>
                          <TableCell>
                            {minutesToText(row.beaconPlaceTime)}
                          </TableCell>
                          <TableCell>
                            {minutesToText(row.fingerPrintTime)}
                          </TableCell>
                          <TableCell>
                            ≈ {minutesToText(row.totalTime)}
                          </TableCell>
                        </TableRow>
                      );

                    }
                    return (
                      <TableRow key={key}>
                        <TableCell>
                          {translate(`resources.zones.text.beacon_calc.table.${key}`)}
                        </TableCell>
                        <TableCell>
                          {row.roomNumber}
                        </TableCell>
                        {/* <TableCell> */}
                        {/*  {row.totalSquareMeters}m² */}
                        {/* </TableCell> */}
                        <TableCell>
                          {/* eslint-disable-next-line max-len */}
                          ({row.roomNumber} × {roomCalcConfig[key as keyof RoomCalcConfig].beaconNumber} ≈ {row.beaconNumber} {translate('resources.zones.text.beacon_calc.table.beacons')})
                        </TableCell>
                        <TableCell>
                          {minutesToText(row.beaconPlaceTime)}
                        </TableCell>
                        <TableCell>
                          {minutesToText(row.fingerPrintTime)}
                        </TableCell>
                        <TableCell>
                          {minutesToText(row.totalTime)}
                        </TableCell>
                      </TableRow>
                    );

                  })}
                </TableBody>
              </Table>
            </div>
          ) : (
            <div>
              <CircularProgress/>
            </div>
          )}
        </DialogContent>
      </Dialog>
    </>
  );

};
