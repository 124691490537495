import { BooleanField, useRecordContext } from 'react-admin';

const ResponsePartnerBooleanField = (props: any) => {

  const record = useRecordContext();
  const source = props.source;
  if (!record[source]) return <BooleanField source="value" record={{ value: false }}/>;
  return <BooleanField source="value" record={{ value: record[source].enabled }}/>;

};

export default ResponsePartnerBooleanField;
