import { SubscriptionCallback } from '@react-admin/ra-realtime';
import _ from 'lodash';
import { XGAC_WS_API_URL } from '../config';
import {
  getFilterForResource, getXgacToken,
  guidGenerator,
  translateRaResourceToWSResource,
  translateWSResourceToRaResource, wait,
} from '../lib/websockets/webSocketHelpers';
import { ActiveIdsType, PendingSubscriptionType, SubscriptionType } from '../lib/websockets/websocketTypes';
import { getCurrentCustomer } from '../lib/currentCustomer';

let currentCustomer: { value: string; label: string } | null;

export class WebSocketService {

  private static instance: WebSocketService;

  private webSocket?: WebSocket;

  private subscriptions: SubscriptionType[] = [];

  private pendingSubscriptions: PendingSubscriptionType[] = [];

  private activeIdsPerResource: ActiveIdsType[] = [];

  // General function called when a message is received from the websocket
  private onMessage = async (event: any) => {

    let data = event.data;
    try {

      data = JSON.parse(event.data);

    } catch (e) {

      console.log('error parsing data', e);

    }

    // check if the message is a confirmation of a subscription
    if (data.event === 'subscribed') {

      // Get the entity from the confirmed subscription
      const entityFromReceivedSubscription = data.data.topics[0].split('.')[2];
      // Check if the subscription is a pending subscription, or if there is a active subscription for the entity
      const foundPendingSubscription = this.pendingSubscriptions.find((pendingSubscription) => pendingSubscription.raTopic === entityFromReceivedSubscription);
      const foundSubscription = this.subscriptions.find((subscription) => subscription.raTopic === entityFromReceivedSubscription);
      if (foundSubscription && foundPendingSubscription) {

        this.subscriptions.map((subscription) => {

          // If the subscription is the same as the pending subscription, merge the ids and subscribed topics
          if (subscription.raTopic === foundPendingSubscription.raTopic) {

            subscription.ids = _.union(subscription.ids, foundPendingSubscription.ids);
            subscription.subscribedTopics = _.union(subscription.subscribedTopics, data.data.topics);
            subscription.subscribedTopics.push(`entity.${currentCustomer?.value}.${entityFromReceivedSubscription}.create`);

          }
          return subscription;

        });

        // If there is no active subscription for the entity, but there is a pending subscription, add a new active subscription.

      } else if (foundPendingSubscription) {

        this.subscriptions.push({
          requestId: data.requestId,
          raTopic: foundPendingSubscription.raTopic,
          subscribedTopics: [...data.data.topics, `entity.${currentCustomer?.value}.${entityFromReceivedSubscription}.create`],
          ids: foundPendingSubscription.ids,
          callBack: foundPendingSubscription.callBack,
        });

      }
      // Remove the pending subscription
      this.pendingSubscriptions = this.pendingSubscriptions.filter((pendingSubscription) => pendingSubscription.raTopic !== entityFromReceivedSubscription);
      return;

    }

    // If the message data has an entity field, it means that the message is a create or update of a record.
    if (data.entity) {

      // Check if there is one or more subscriptions for the entity
      let foundSubscriptions = _.filter(this.subscriptions, (subscription) => {

        return subscription.subscribedTopics.includes(data.topic);

      });

      if (['AlarmEvent', 'AlarmHelper'].includes(data.entity)) {

        foundSubscriptions = _.filter(this.subscriptions, (subscription) => {

          return subscription.raTopic === 'Alarm';

        });

      }

      if (foundSubscriptions.length > 0) {

        // Call the callback function for each subscription
        for (const foundSubscription of foundSubscriptions) {

          foundSubscription.callBack({
            topic: translateWSResourceToRaResource(data.entity),
            type: `${data.operation}d`,
            payload: { id: data.document._id },
          });

        }

      }

    }

  };

  // When the websocket closes, reconnect it, and resubscribe to all subscriptions
  private onClose = async () => {

    console.log('websocket closed');
    this.webSocket = new WebSocket(XGAC_WS_API_URL);
    this.webSocket.onmessage = this.onMessage;
    this.webSocket.onclose = this.onClose;
    await this.checkIfWebSocketIsReady();
    const subscriptions = [...this.subscriptions];
    this.subscriptions = [];
    this.pendingSubscriptions = [];

    for (const subscription of subscriptions) {

      await this.subscribe(translateWSResourceToRaResource(subscription.raTopic), subscription.callBack);

    }

  };

  // Constructor of the class. Creates a new websocket, and sets the onmessage and onclose functions. Is a singleton.
  public constructor() {

    if (!this.webSocket) {

      this.webSocket = new WebSocket(XGAC_WS_API_URL);
      this.webSocket.onmessage = this.onMessage;
      this.webSocket.onclose = this.onClose;

    }
    if (WebSocketService.instance) {

      // eslint-disable-next-line no-constructor-return
      return WebSocketService.instance;

    }
    WebSocketService.instance = this;

  }

  // Function to check if the websocket is ready. If not, reconnect, wait 1 second and return. Is recursive.
  private checkIfWebSocketIsReady = async () => {

    if (this.webSocket?.readyState !== 1) {

      if (this.webSocket?.readyState === 2 || this.webSocket?.readyState === 3) {

        this.webSocket = new WebSocket(XGAC_WS_API_URL);
        this.webSocket.onmessage = this.onMessage;
        this.webSocket.onclose = this.onClose;

      }

      console.log('websocket not ready, waiting 1 second');

      await wait(1000);
      if (this.webSocket?.readyState !== 1) {

        await this.checkIfWebSocketIsReady();

      }

    }

  };

  // Function which sets the active ids. Is called by the result of the getList in the dataprovider.
  // The result of this function is later used in subscribe, to only subscribe to the ids which are active.
  public setActiveIdsPerResource = (resource: string, ids: string[]) => {

    const translatedResource = translateRaResourceToWSResource(resource);
    const foundResource = this.activeIdsPerResource.find((activeResource) => activeResource.resource === translatedResource);
    if (foundResource) {

      foundResource.ids = ids;
      this.activeIdsPerResource = this.activeIdsPerResource.map((activeResource) => (activeResource.resource === resource ? foundResource : activeResource));

    } else {

      this.activeIdsPerResource.push({ resource: translatedResource, ids });

    }
    // if there is already a pending subscription for this resource, make an active subscription. Return.
    const foundPendingSubscription = this.pendingSubscriptions.find((subscription) => subscription.raTopic === translatedResource);
    if (foundPendingSubscription) {

      this.subscribe(`resource/${resource}`, foundPendingSubscription.callBack);
      return;

    }
    // If there is already an active subscription for this resource, return.
    const foundSubscription = this.subscriptions.find((subscription) => subscription.raTopic === translatedResource);
    if (foundSubscription) {

      this.subscribe(`resource/${resource}`, foundSubscription.callBack);

    }

  };

  // Function to subscribe to a resource. Only subscribes to the ids which are active.
  public subscribe = async (resource: string, callBack: SubscriptionCallback) => {

    // Renew the active customer
    currentCustomer = getCurrentCustomer();

    const cutResource = resource.split('/').splice(1).join('/');
    const translatedResource = translateRaResourceToWSResource(cutResource);
    const requestId = guidGenerator();
    let createNeedsToSubscribe = true;
    const foundActiveIds = this.activeIdsPerResource.find((activeResource) => activeResource.resource === translatedResource);
    const activeSubscription = this.subscriptions.find((subscription) => subscription.raTopic === translatedResource);
    if (!foundActiveIds || foundActiveIds.ids.length === 0) {

      this.pendingSubscriptions.push({
        requestId, raTopic: translatedResource, ids: [], callBack,
      });
      return;

    }

    const idsToSubscribe: string[] = [];

    if (activeSubscription) {

      // Check if the subscription is already subscribed to the create topic
      if (activeSubscription.subscribedTopics.includes(`entity.${currentCustomer?.value}.${translatedResource}.create`)) {

        createNeedsToSubscribe = false;

      }

      // Check if there are ids which are not in the active subscription
      const differenceBetweenIds = _.difference(foundActiveIds.ids, activeSubscription.ids);

      const filteredIds = activeSubscription.ids.filter((id) => !foundActiveIds.ids.includes(id));
      if (filteredIds.length > 0) {

        this.subscriptions = this.subscriptions.map((subscription) => {

          if (subscription.raTopic === translatedResource) {

            subscription.ids = foundActiveIds.ids;

          }
          return subscription;

        });
        // Unsubscribe from the topics which are not in the active ids anymore
        await this.unsubScribeSpecificTopics(filteredIds, translatedResource);

      }

      if (differenceBetweenIds.length === 0) {

        return;

      }

      idsToSubscribe.push(...differenceBetweenIds);

    }

    if (this.webSocket?.readyState !== 1) {

      await wait(1000);

    }
    // Subscribe to the topics, by the ids which are active.
    const filter = getFilterForResource(resource);
    this.webSocket?.send(JSON.stringify({
      requestId: activeSubscription?.requestId || requestId,
      event: 'subscribe',
      token: getXgacToken(),
      data: {
        disableSubEntities: translatedResource !== 'Alarms',
        entity: translatedResource,
        id: idsToSubscribe.length > 0 ? idsToSubscribe : foundActiveIds.ids,
        customers: currentCustomer ? [currentCustomer.value] : null,
        ...(filter && { filter }),
      },
    }));

    // Subscribe to the create topic if needed
    if (createNeedsToSubscribe) {

      this.webSocket?.send(JSON.stringify({
        event: 'subscribe',
        token: getXgacToken(),
        data: {
          disableSubEntities: translatedResource !== 'Alarms',
          entity: translatedResource,
          customers: currentCustomer ? [currentCustomer.value] : null,
          events: ['create'],
        },
      }));

    }

    const activePendingSubscription = this.pendingSubscriptions.find((subscription) => subscription.raTopic === translatedResource);
    if (activePendingSubscription) {

      // If there is already a pending subscription for this resource, add the ids to the pending subscription.
      this.pendingSubscriptions = this.pendingSubscriptions.map((subscription) => (subscription.raTopic === translatedResource ? {
        ...subscription,
        ids: [...subscription.ids, ...idsToSubscribe],
      } : subscription));
      return;

    }

    // Push the pending subscription.
    this.pendingSubscriptions.push({
      requestId: activeSubscription?.requestId || requestId, raTopic: translatedResource, callBack, ids: foundActiveIds.ids,
    });

  };

  public unsubscribe = async (resource: string) => {

    await this.checkIfWebSocketIsReady();
    const cutResource = resource.split('/').splice(1).join('/');
    const translatedResource = translateRaResourceToWSResource(cutResource);
    const topicsToUnsub = [];
    // Get the topics which are subscribed to the resource
    const validSubscriptions = this.subscriptions.filter((subscription) => subscription.raTopic === translatedResource);
    for (const validSubscription of validSubscriptions) {

      topicsToUnsub.push(...validSubscription.subscribedTopics);

    }
    // if there are no topics to unsubscribe, return.
    if (topicsToUnsub.length === 0) {

      return;

    }
    // Remove the subscriptions from the subscriptions array, and send the unsubscribe request.
    this.subscriptions = this.subscriptions.filter((subscription) => subscription.raTopic !== translatedResource);
    this.webSocket?.send(JSON.stringify({
      requestId: guidGenerator(),
      event: 'unsubscribe',
      token: getXgacToken(),
      data: {
        topics: topicsToUnsub,
      },
    }));

  };

  public unsubScribeSpecificTopics = async (ids: string[], entity: string) => {

    // Unsubscribe from the topics which are passed as ids.
    await this.checkIfWebSocketIsReady();
    const topics = ids.map((id) => `entity.${currentCustomer?.value}.${entity}.${id}`);
    this.webSocket?.send(JSON.stringify({
      requestId: guidGenerator(),
      event: 'unsubscribe',
      token: getXgacToken(),
      data: {
        topics,
      },
    }));

  };

}
