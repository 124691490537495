import {
  TextField,
  EditButton,
  TextInput,
  SimpleForm,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  required,
  useRecordContext,
  useTranslate,
  CreateButton,
  TopToolbar,
  Button,
  Identifier,
  SaveButton,
  Toolbar,
  BulkDeleteButton,

} from 'react-admin';
import {
  Datagrid, IfCanAccess,
} from '@react-admin/ra-rbac';
import * as React from 'react';
import {
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  Tooltip,
  Typography,
} from '@mui/material';
import { ListLive } from '@react-admin/ra-realtime';
import {
  Asset,
  AssetRef,
} from '@x-guard/xgac-types/xgac';
import { useListContext } from 'ra-core';
import { useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import { CreateToolbarRight } from '../../../components/CustomToolBar';
import SearchFields, { FilterResetter } from '../components/fields/Filters';
import TextFieldNullable from '../../../components/fields/TextFieldNullable';
import { CreateWithSuccess } from '../../../components/baseForms/CreateWithSuccess';
import { EditWithSuccess } from '../../../components/baseForms/EditWithSuccess';
import { LIST_DEBOUNCE } from '../../../config';
import { StabilityAlert, StabilityLevel } from '../../../components/StabilityAlert';
import authProvider from '../../../utils/authProvider';
import { PageExplanation } from '../../../components/PageExplanation';
import {
  CustomBulkExportButton, FullExportButton,
} from '../../../utils/customExporter';
import { DateFieldWithTime } from '../../../components/fields/DateFieldWithTime';
import { AssetsInDialogButton } from '../../../components/buttons/assetsInDialogButton';
import { AssetGroupDeleteButton, useIsUsedInLinkTriggerOrKiosk } from '../../../components/buttons/assetGroupDeleteButton';

const transform = (data: any) => {

  return {
    ...data,
    assets: data.assets.map((record: string) => ({ _ref: 'Asset', _id: record } as AssetRef)),
  };

};

const AssetGroupAssetInput = () => {

  const isCustomerAdmin = authProvider.isCustomerAdmin();
  const translate = useTranslate();

  const assetGroupAssetLabel = (asset: Asset & { id: Identifier }) => {

    return asset.app ? <>{asset.name} <Chip size="small" sx={{ marginLeft: '5px' }} label={translate('resources.assets.text.has_app')}/></> : asset.name;

  };

  return (
    <ReferenceArrayInput source="assets" reference="all-assets"
      debounce={LIST_DEBOUNCE}
      sort={{ field: 'app', order: 'DESC' }}
      perPage={100}
    >
      <AutocompleteArrayInput
        label={isCustomerAdmin ? 'general.text.persons' : 'resources.assets.text.single_name' }
        autoHighlight={true}
        fullWidth
        optionText={assetGroupAssetLabel}
        renderTags={(value: any, getTagProps) => {

          return value.map((option: Asset, index: number) => {

            return <Chip {...getTagProps({ index })} label={option.name} size="small"/>;

          });

        }}
        matchSuggestion={(filterValue: string, suggestion: Asset) => {

          const assetName = suggestion.name.toLowerCase();
          const filterValueLower = filterValue.toLowerCase();
          return assetName.includes(filterValueLower);

        }}
        optionValue="id"
        blurOnSelect={false}
      />
    </ReferenceArrayInput>
  );

};

const AssetGroupBulkDeleteButton = () => {

  const translate = useTranslate();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [deleteEnabled, setDeleteEnabled] = useState(false);
  const { selectedIds } = useListContext();
  const isUsed = useIsUsedInLinkTriggerOrKiosk(selectedIds);
  const isAdmin = authProvider.isAdmin();
  const closeDialog = () => {

    setDialogOpen(false);
    setDeleteEnabled(false);

  };
  if (isAdmin) {

    return (
      <>
        {isUsed ? (
          <>
            <Button onClick={() => setDialogOpen(true)} color="error" label="ra.action.delete" startIcon={<DeleteIcon/>}/>
            <Dialog open={dialogOpen} onClose={closeDialog}>
              <DialogContent>
                <div>
                  <Typography variant={'body1'}>{translate(
                    'ra.message.bulk_delete_content',
                    {
                      name: translate('resources.asset-groups.name').toLowerCase(),
                      smart_count: selectedIds.length,
                    },
                  )}</Typography>
                  <br/>
                  <Typography
                    fontWeight="fontWeightBold"
                    variant={'body1'}
                    color="error">
                    {translate('resources.asset-groups.text.delete_warning_multiple')}
                  </Typography>
                </div>
                <br/>
                <FormControlLabel control={
                  <Checkbox checked={deleteEnabled} onChange={(event, checked) => setDeleteEnabled(checked)}/>
                } label={translate('resources.asset-groups.text.delete_confirmation')}/>
              </DialogContent>
              <DialogActions>
                <Button onClick={closeDialog} label={translate('ra.action.cancel')}/>
                <BulkDeleteButton disabled={!deleteEnabled} mutationMode='pessimistic'/>
              </DialogActions>
            </Dialog>
          </>
        ) : (
          <BulkDeleteButton mutationMode='pessimistic' />
        )}
      </>
    );

  }
  if (isUsed) {

    return (
      <Tooltip title={translate('resources.asset-groups.text.delete_warning_multiple')}>
        <div>
          <BulkDeleteButton disabled={true} mutationMode='pessimistic'/>
        </div>
      </Tooltip>
    );

  }

  return (
    <BulkDeleteButton disabled={isUsed} mutationMode='pessimistic'/>
  );

};

export const AssetGroupList = () => {

  return (
    <>
      <PageExplanation text="resources.asset-groups.text.page_info" />
      <ListLive filters={SearchFields} debounce={LIST_DEBOUNCE} title="menu.items.groups"
        actions={<TopToolbar><CreateButton/><FullExportButton resource="asset-groups"/></TopToolbar>}
        sort={{ field: 'name', order: 'ASC' }}
      >
        <>
          <FilterResetter/>
          <Datagrid rowClick={false} bulkActionButtons={
            <><CustomBulkExportButton/><AssetGroupBulkDeleteButton/></>
          }
          >
            <TextField source="name" label="general.fields.name" />
            <TextFieldNullable source="description" />
            <AssetsInDialogButton label="resources.asset-groups.fields.asset_count" hasExporter={true}/>
            <DateFieldWithTime source="createdAt" label="general.fields.createdAt" timeOnHover={true}/>
            <DateFieldWithTime source="updatedAt" label="general.fields.updatedAt" timeOnHover={true}/>
            <IfCanAccess action="edit" resource="asset-groups">
              <EditButton/>
            </IfCanAccess>
          </Datagrid>
        </>
      </ListLive>
    </>
  );

};

const AssetGroupEditFields = () => {

  const translate = useTranslate();
  const record = useRecordContext();

  return (
    <>
      <h4 style={{ marginBottom: '10px' }}>"{record.name}" {translate('general.text.settings')}</h4>
      <Typography variant="body1">{translate('resources.asset-groups.text.create_explanation')}</Typography>
      <hr/>
      <TextInput source="name" fullWidth validate={required()} label="general.fields.name"/>
      <TextInput source="description" fullWidth multiline/>
      <AssetGroupAssetInput/>
    </>
  );

};

const AssetGroupToolbar = () => {

  return (
    <Toolbar sx={{ justifyContent: 'end' }}>
      <AssetGroupDeleteButton/>
      <SaveButton sx={{ ml: '8px' }}/>
    </Toolbar>
  );

};
export const AssetGroupEdit = () => {

  return (
    <>
      <StabilityAlert stability={StabilityLevel.Stable} />
      <EditWithSuccess transform={transform} title="menu.items.groups" actions={false}>
        <SimpleForm sx={{ maxWidth: '1000px' }} toolbar={<AssetGroupToolbar/>}>
          <AssetGroupEditFields/>
        </SimpleForm>
      </EditWithSuccess>
    </>
  );

};
export const AssetGroupCreate = () => {

  const translate = useTranslate();

  return (
    <>
      <StabilityAlert stability={StabilityLevel.Stable} />
      <CreateWithSuccess transform={transform} title="menu.items.groups">
        <SimpleForm toolbar={<CreateToolbarRight narrow/>} sx={{ maxWidth: '1000px' }}>
          <h4>{translate('resources.asset-groups.text.create_title')}</h4>
          <Typography variant="body1">{translate('resources.asset-groups.text.create_explanation')}</Typography>
          <hr/>
          <TextInput source="name" fullWidth validate={required()} label="general.fields.name"/>
          <TextInput source="description" multiline fullWidth/>
          <AssetGroupAssetInput/>
        </SimpleForm>
      </CreateWithSuccess>
    </>);

};
