import { ChipField, useRecordContext } from 'react-admin';
import TextFieldNullable from './TextFieldNullable';

const SimpleChipListField = ({ source, passedRecord }:any) => {

  const recordContext = useRecordContext();
  const record = passedRecord || recordContext;
  const arrayValue = record[source];
  if (!arrayValue || !arrayValue[0]) return <TextFieldNullable source="value" record={{ value: null }}/>;
  return (<ul style={{ margin: '0' }}>
    {arrayValue.map((item: any) => (
      <ChipField source="item" record={{ item }} />
    ))}
  </ul>);

};

export default SimpleChipListField;
